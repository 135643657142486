import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'assignations',
		url: StaticRoutes.Groups,
		icon: 'fa fa-group',
		role: roles.EDITOR,
		children: [
			// {
			// 	name: 'crews',
			// 	url: StaticRoutes.GroupsDefinition
			// },
			{
				name: 'applications',
				icon: 'fa fa-gavel',
				url: StaticRoutes.GroupsAssign
			},
			{
				name: 'report',
				icon: 'fa fa-file-pdf-o',
				url: StaticRoutes.GroupsReport
			}
		]
	},
];

export default menu;
