import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, UncontrolledPopover as Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import {
	Filter,
	FilterGroup,
	Table,
	Title,
	Button,
	Tbody,
	Thead,
	Toolbox,
	Tr,
	Search,
	Pagination,
} from 'table';
import { DynamicRoutes } from '../../model/routes';
import { DynamicRoutes as FlowsDynamicRoutes } from 'flows-app/model/routes';
import StatusHistory from 'flows-app/components/statusHistory';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { Loading, SecureContent, Error } from 'core/components';
import { ExportToCSV } from 'core/model/lib';
import * as roles from 'core/model/roles';
import { applicationStatus } from 'flows-app/model/constants';
import Alert from 'core/views/modals/alert';
import { AdvancedView as View } from 'advanced-view';
import { toggleModal } from 'core/ducks/ui/modal';
import { deleteData, getData as getElement, updateData as activate } from 'core/ducks/update';
import T from 'modules/i18n';

class WorkflowsListWithTags extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			status: '',
			sort: 'created',
			sort_method: 'desc',
			popoverIndex: null,
			popoverData: {},
		};
		this.state = {...this.initialState, page: 1, refreshing: false, mounted: true};
		this.fields = props.type==='private'
			? ['name', 'created', 'step', 'status']
			: ( (props.profile.role===roles.GUEST || props.profile.role===roles.AUTHORIZED)
				? ['tags', 'status', 'address', 'created']
				: ['tags', 'status', 'address', 'created', 'last_action', 'author', 'reviewer']
			);
		this.layout = {
			workflow: {sortable: true},
			registry_number: {sortable: true},
			author: {sortable: true},
			created: {type: 'date', sortable: true},
			last_action: {type: 'date', sortable: true},
			revision: {sortable: true},
			step: {sortable: true},
			reviewer: {sortable: true},
			status: {type: 'translatable'},
			address: {sortable: true},
			tags: {type: 'badge', link: props.personal ? undefined : {f: buildPath, args: [DynamicRoutes.WorkflowsListStatusTag]}}
		};
		this.badge_colors = {
			'rejected': 'red',
			'accepted': 'green',
			'progressing': 'warning',
			'with_editor': 'info',
			'under_review': 'primary',
			'completed': 'success'
		}

		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.requestData = this.requestData.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.viewDetails = this.viewDetails.bind(this);
		this.activate = this.activate.bind(this);
		this.createHistory = this.createHistory.bind(this);
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleSelect(uuid) {
		const path = buildPath(FlowsDynamicRoutes.Apply, [uuid, 'current']);
		this.props.history.push(path);
	}

	handleFilterChange(event) {
		this.setState({
			[event.target.name]: event.target.value,
			page: 1
		});

		if (this.props.match && this.props.match.params) {
			const target = event.target;
			let { status } = this.props.match.params;
			status = target.name==='status' ? (target.value ? target.value : undefined) : status;
			const { tag } = this.props.match.params;
			let path;
			if (status) {
				path = tag ? buildPath(DynamicRoutes.WorkflowsListStatusTag, [tag, status]) : buildPath(DynamicRoutes.WorkflowsListStatus, [status]);
			} else {
				path = tag ? buildPath(DynamicRoutes.WorkflowsListStatusTag, [tag]) : buildPath(DynamicRoutes.WorkflowsListStatus, []);
			}
			this.props.history.push(path);
		}
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
		const { tag } = this.props.match.params;
		const path = tag ? buildPath(DynamicRoutes.WorkflowsListStatusTag, [tag]) : buildPath(DynamicRoutes.WorkflowsListStatus, []);
		this.props.history.push(path);
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl('csv');
		let fields = [...this.fields, 'lat', 'lon'];
		this.props.dispatch(requestData('csv', url)).then(() => {
			fields = [...fields, ...this.props.list.csv.data.fields];
			let data = Object.keys(this.props.list.csv.data.values).map((index) => {
				let row = this.props.list.csv.data.values[index];
				return fields.map((col) => {
					if (!row[col])
						return '';
					return (this.layout[col] && this.layout[col].type === 'translatable' && messages[row[col]]) ? messages[row[col]] : row[col];
				});
			});
			fields = fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('reports.csv', fields, data);
			csv.createLink();
		});
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	createUrl(scope='') {
		let { type, profile } = this.props;
		if ( !type || (type !== 'public' && type !== 'private') )
			type = 'public';
		let { query, sort, sort_method } = this.state;
		let status = (this.props.match && this.props.match.params) ? this.props.match.params.status : undefined;
		let tag = encodeURI(this.props.match.params.tag) || undefined;
		let url = this.props.match.params.tag ? `tags/tag/${tag}` : 'application';
		url += `/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		url += this.props.inactive ? '/inactive/true' : '';
		query = (query !== '') ? '/query/' + query : query;
		let fq = `/fq/type:${type}`;
		fq += status ? `;status:${status}` : '';
		const role = profile.role===roles.ADMIN ? roles.EDITOR : (profile.role===roles.GUEST ? 'author' : profile.role);
		fq += this.props.personal ? `;${role}:${profile.uuid}` : '';
		url += query + fq;
		if (scope === 'csv')
			url += '/csv/true';

		return url;
	}

	requestData() {
		if (!this.state.mounted)
			return;
		const { dispatch } = this.props;
		const url = this.createUrl();
		this.setState({refreshing: true}, () => {
			dispatch(requestData('application', url)).then(() => {
				this.setState({refreshing: false});
			});
		});
	}

	viewDetails(uuid) {
		const url = this.props.inactive ? `application/inactive/true/view/${uuid}` : `advancedView/application/${uuid}`;
		this.props.dispatch( getElement(url) ).then(() => {
			if (this.props.http_status === 200)
				this.props.dispatch(
					toggleModal(true,
						<View
							toggle={() => {this.actions.toggleModal()}}
							data={this.props.view.values}
							translateFields={false}
							location={this.props.view.location}
							details={this.props.view.details}
						/>
					)
				);
		});
	}

	activate(uuid) {
		this.props.dispatch( activate('application/inactive/activate', {'uuid': uuid}, false) ).then(() => {
			if (this.props.http_status === 200) {
				const path = buildPath(FlowsDynamicRoutes.Apply, [uuid, 'current']);
				this.props.history.push(path);
			}
		});
	}

	createHistory(data) {
		const { index } = data;
		if (this.state.popoverData[index]) {
			this.setState({popoverIndex: index});
		} else {
			this.props.dispatch( getElement(`application/history/${index}`)).then(() => {
				this.setState({
					popoverIndex: index,
					popoverData: {
						...this.state.popoverData,
						[index]: [{transition: data.created, status: 'submission'} , ...this.props.view]
					},
				})
			});
		}
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.status) {
			this.setState({status: this.props.match.params.status}, this.requestData);
		} else {
			this.requestData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if ( params.status !== prevProps.match.params.status || prevState.query !== this.state.query) {
			let state = {page: 1};
			if (prevState.query !== this.state.query)
				state = Object.assign(state, {query: this.state.query});
			if (params.status !== prevProps.match.params.status)
				state = Object.assign(state, {status: params.status})
			this.setState(state, this.requestData());
		}
		if ( params.tag !== prevProps.match.params.tag)
			this.requestData();
		if (prevProps.inactive !== this.props.inactive)
			this.setState(this.initialState, this.requestData());
		if (prevProps.modalOpen !== this.props.modalOpen && !this.props.modalOpen)
			this.requestData();
	}

	componentWillUnmount() {
		this.setState({...this.initialState, page: 1, refreshing: false, mounted: false});
	}

	render() {
		const { data, info, list } = this.props;
		if (this.props.pending || !data)
			return (<Loading />);
		if (list.application.status !== 200 && list.application.status !== '')
			return (<Error status={list.application.status} errorMsg={list.application.errorMsg} />);

		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>{this.props.inactive ? 'inactive_workflows' : 'applications'}</T>
							<SecureContent role={roles.REVIEWER}>
								<Button type="toolbox" title="filters" className="float-right"/>
							</SecureContent>
						</Title>
						<SecureContent role={roles.REVIEWER}>
							<Toolbox onReset={this.handleToolboxReset}>
								<Col xs="12" lg="8" className="form-group text-right">
									<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
									<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
										<T>export</T> csv
									</Button>
									<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
								</Col>
								<Col xs="12" lg="4">
									<FilterGroup>
										<Filter onChange={this.handleFilterChange} name="status" defaultValue={this.state.status} >
											<option value="">{`${messages.choose || 'choose'} ${messages.status || 'status'}`}</option>
											{ applicationStatus.map((status) => (
												<option key={`option_${status}`} value={status}>{messages[status] || 'status'}</option>
											))}
										</Filter>
									</FilterGroup>
								</Col>
							</Toolbox>
						</SecureContent>
						<Thead>
							<Tr
								className="text-capitalize"
								data={['', ...this.fields]}
								layout={this.layout}
								sortBy={this.state.sort}
								sortMethod={this.state.sort_method}
								onClick={this.handleSortChange}
							/>
						</Thead>
						<Tbody refreshing={this.state.refreshing}>
							{ this.props.personal ?
								<Tr
									data={data ? data : {}}
									layout={this.layout}
									order={this.fields}
									badge_colors={this.badge_colors}
								>
									<Button type="edit" title={messages.edit || 'edit'} onClick={(data) => {this.handleSelect(data.index)}} />
									<Button
										type="drop"
										title={messages.drop || 'drop'}
										disabled={data => data.status!=='progressing'}
										onClick={index => {
											this.setState({popoverIndex: null});
											this.actions.toggleModal(true,
												<Alert
													toggle={this.actions.toggleModal}
													title="drop confirm"
													message="do you wish to continue"
													onConfirm={() => this.actions.deleteData(`application/uuid/${index}`)}
												/>
											);
										}}
									/>
									<Button
										className="fa fa-history"
										onMouseEnter={(data) => this.createHistory(data)}
										onMouseLeave={() => {this.setState({popoverIndex: null})}}
									/>
								</Tr>
								: this.props.inactive ?
								<Tr
									data={data ? data : {}}
									layout={this.layout}
									order={this.fields}
									badge_colors={this.badge_colors}
								>
									<Button type="view" title={messages.view || 'view'} onClick={(data) => {this.viewDetails(data.index)}} />
									<Button type="activate" title={messages.activate || 'activate'} onClick={(index) => {this.activate(index)}} />
									<Button type="drop" title={messages.drop || 'drop'} onClick={index => {
										this.setState({popoverIndex: null});
										this.actions.toggleModal(true,
											<Alert
												toggle={this.actions.toggleModal}
												title="drop confirm"
												message="do you want to permanently delete this application"
												onConfirm={() => this.actions.deleteData(`application/inactive/true/uuid/${index}`)}
											/>
										);
									}} />
								</Tr>
								:
								<Tr
									data={data ? data : {}}
									layout={this.layout}
									order={this.fields}
									badge_colors={this.badge_colors}
								>
									<Button type="view" title={messages.view || 'view'} onClick={(data) => {this.viewDetails(data.index)}} />
									<Button type="edit" title={messages.edit || 'edit'} onClick={(data) => {this.handleSelect(data.index)}} />
									{ (this.props.profile.role === roles.ADMIN || this.props.profile.role === roles.EDITOR) &&
										<Button type="drop" title={messages.drop || 'drop'} disabled={data => data.status!=='progressing'} onClick={index => {
											this.setState({popoverIndex: null})
											this.actions.toggleModal(true,
												<Alert
													toggle={() => {this.actions.toggleModal()}}
													title="drop confirm"
													message="do you want to permanently delete this application"
													onConfirm={() => this.actions.deleteData(`application/uuid/${index}`)}
												/>
											);
										}} />
									}
									<Button
										className="fa fa-history"
										onMouseEnter={(data) => this.createHistory(data)}
										onMouseLeave={() => {this.setState({popoverIndex: null})}}
									/>
								</Tr>
							}
						</Tbody>
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
					{ this.state.popoverIndex &&
						<Popover
							placement="right"
							isOpen={true}
							target={`table_button_fa_fa-history_${this.state.popoverIndex}`}
						>
							<PopoverHeader><T>history</T></PopoverHeader>
							<PopoverBody>
								<StatusHistory data={this.state.popoverData[this.state.popoverIndex]}/>
							</PopoverBody>
						</Popover>
					}
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.user,
	list: state.list,
	pending: state.list.application.pending,
	data: getData(state, 'application'),
	info: getInfo(state, 'application'),
	modalOpen: state.ui.modal.modalOpen,
	view: state.update.response,
	http_status: state.update.status,
	i18n: state.i18n,
});

WorkflowsListWithTags = connect(mapStateToProps)(WorkflowsListWithTags);

export default WorkflowsListWithTags;
