import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SliderRail extends Component {
	render() {
		const { getRailProps } = this.props

		return (
			<>
				<div className="slider-rail-outer" {...getRailProps()} />
				<div className="slider-rail-inner" />
			</>
		);
	}
}

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired
};

export default SliderRail;
