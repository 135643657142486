import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown
} from 'reactstrap';

import { T } from '../../components';
import { toggleLangDropdown } from '../../ducks/ui/menu';
import { changeLocale } from '../../ducks/i18n';
import settings from '../../../../../package.json';

class LangDropdown extends Component {

	render() {
		const { dispatch, locale } = this.props;
		if (settings.locales.length <= 1)
			return null;
		return (
			<Dropdown nav isOpen={this.props.langDropdownOpen} toggle={() => dispatch(toggleLangDropdown())}>
				<DropdownToggle caret size="sm" className="no-outline">
					<i className="fa fa-language"/><span className="d-none d-sm-inline"> <T>{locale}</T></span>
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={() => dispatch(changeLocale('el'))}>
						<i className="flag-icon flag-icon-gr"></i> <T>el</T>
					</DropdownItem>
					<DropdownItem onClick={() => dispatch(changeLocale('en'))}>
						<i className="flag-icon flag-icon-gb"></i> <T>en</T>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
}

const mapStateToProps = (state) => ({
	locale: state.i18n.locale,
	langDropdownOpen: state.ui.menu.langDropdownOpen
});

LangDropdown = connect(mapStateToProps)(LangDropdown);

export default LangDropdown;
