import { LogisticsInput } from './components';

const fieldTypes = {
	logistics: {
		name: 'logistics',
		component: LogisticsInput
	}
};

const constants = { fieldTypes };

export default constants;
