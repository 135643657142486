import { StaticRoutes } from './model/routes';
import * as roles from './model/roles';

const menu = [
	{
		name: 'settings',
		url: StaticRoutes.Settings,
		icon: 'icon-wrench',
		role: roles.ADMIN,
		children: [
			{
				name: 'general',
				url: StaticRoutes.GeneralSettings,
				icon: 'fa fa-sliders',
			},
			{
				name: 'email templates',
				url: StaticRoutes.Templates,
				icon: 'fa fa-envelope-o'
			}
		],
	},
	{
		name: 'failures',
		url: StaticRoutes.Admin,
		icon: 'fa fa-wrench',
		role: roles.REVIEWER,
		children: [],
	}
];

export default menu;
