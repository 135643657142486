import React, { Component } from 'react';
import { Row, CardBody } from 'reactstrap';
import { TableContextConsumer } from './table';
import PropTypes from 'prop-types';

class Toolbox extends Component {

	render() {

		return (
			<TableContextConsumer>
				{ (context) =>
					<CardBody className={context.toolboxIsOpen ? 'bg-light mx-3 mb-2' : 'd-none'}>
						<form
							onSubmit={(e) => {e.preventDefault()}}
							onReset={() => {
								context.onResetFiltersClick();
								if (typeof this.props.onReset === 'function')
									this.props.onReset()
							}}
						>
							<Row id="filters">
								{this.props.children}
							</Row>
						</form>
					</CardBody>
				}
			</TableContextConsumer>
		);
	}
}

Toolbox.propTypes = {
	onReset: PropTypes.func
};

Toolbox.displayName = 'Toolbox';

export default Toolbox;
