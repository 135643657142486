import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { Api } from 'core/api';

class LogisticsInput extends Component {

	constructor(props) {
		super(props);
		this.initialValues = {
			category: '',
			type: '',
			item: '',
			quantity: '',
			unit: '',
		};
		this.state = {
			values: [{...this.initialValues}],
			categories: [],
			types: [[]],
			items: [[]],
			value: {}
		};

		this.requestData = this.requestData.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleRowNumberChange = this.handleRowNumberChange.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(requestData('categories', 'logistics/list/category')).then(() => {
			this.setState({
				categories: this.props.data.categories.data
			});
		});
		if (this.props.value && this.props.value !== '') {
			let value = JSON.parse(this.props.value);
			this.setState(value);
			Object.keys(value).forEach((key, row) => {
				const { id, quantity } = value[key];
				const request = new Api(`logistics/uuid/${key}/fields/category;type;unit`);
				request.Get().then(response => {
					return response.json();
				}).then(json => {
					const { category, type, unit } = json;
					let values = { id, category, type, item: key, unit, quantity};
					const oldValues = row===0 ? [] : this.state.values;
					this.setState({
						values: [...oldValues, {...values}],
					}, () => {
						this.requestData('category', row);
						this.requestData('type', row);
					});
				});
			});
		}
	}

	requestData(scope, row) {
		const values = this.state.values[row];
		switch (scope) {
			case 'category':
				if (values.category !== '') {
					this.props.dispatch(requestData('types', `logistics/list/type/category/${values.category}`)).then(() => {
						this.setState({
							types: [
								...this.state.types.slice(0, row),
								this.props.data.types.data,
								...this.state.types.slice(row + 1)
							]
						});
					});
				}
				break;

			case 'type':
				if (values.type !== '') {
					this.props.dispatch(requestData('items', `logistics/list/name/category/${values.category}/type/${values.type}`)).then(() => {
						this.setState({
							items: [
								...this.state.items.slice(0, row),
								this.props.data.items.data,
								...this.state.items.slice(row + 1)
							],
						});
					});
				}
				break;

			case 'item':
				if (values.item !== '') {
					const stateValues = this.state.values;
					this.props.dispatch(requestData('unit', `logistics/list/unit/category/${values.category}/type/${values.type}/name/${values.item}`)).then(() => {
						this.setState({
							values: [
								...stateValues.slice(0, row),
								{...stateValues[row], unit: this.props.data.unit.data},
								...stateValues.slice(row + 1)
							],
						});
					})
				}
				break;

			default:
				break;
		}
	}

	handleInputChange(event) {
		const { name, value } = event.target;
		const row = event.target.getAttribute('data-row');
		const { values } = this.state;
		const other = name==='category' ? {id: '', type: '', item: '', quantity: '', unit: ''} : (name==='type' ? {id: '', item: '', quantity: '', unit: ''} : {});
		this.setState({
			values: [
				...values.slice(0, row),
				{...values[row], ...other, [name]: value},
				...values.slice(row + 1)
			],
		}, () => {
			this.requestData(name, row);
			if (name === 'quantity')
				this.setValue();
		});
	}

	handleRowNumberChange() {
		this.setState({
			values: [...this.state.values, {...this.initialValues}],
			types: [...this.state.types, []],
			items: [...this.state.items, []],
		})
	}

	setValue() {
		const { values } = this.state;
		let value = {};
		values.forEach(row => {
			value[row.item] = {id: row.id, quantity: row.quantity};
		});
		this.setState({value}, () => {
			if (typeof this.props.onChange === 'function')
				this.props.onChange({target: {name: this.props.name, value: JSON.stringify(value)}});
		});
	}

	render() {

		if (!this.props.data.categories || this.props.data.categories.pending)
			return null;

		const { messages } = this.props.i18n || {messages: {}};
		const { categories, types, items } = this.state;

		return (
			<>
				{this.state.values.map((value, row) => (
					<Row key={`row_${row}`}>
						<Col lg="3">
							<FormGroup>
								<Input type="select" name="category" onChange={this.handleInputChange} value={value.category} readOnly={this.props.readOnly} data-row={row}>
									<option value="">{messages.choose || 'choose'} {messages.category || 'category'}</option>
									{ categories.map((category, index) =>
										<option key={`category_${row}_${index}`} value={category}>{category}</option>
									) }
								</Input>
							</FormGroup>
						</Col>
						<Col lg="3">
							<FormGroup>
								<Input type="select" name="type" onChange={this.handleInputChange} disabled={value.category===''} value={value.type} readOnly={this.props.readOnly} data-row={row}>
									<option value="">{messages.choose || 'choose'} {messages.type || 'type'}</option>
									{types[row] ? types[row].map((type, index) =>
										<option key={`type_${row}_${index}`} value={type}>{type}</option>
									) : null}
								</Input>
							</FormGroup>
						</Col>
						<Col lg="3">
							<FormGroup>
								<Input type="select" name="item" onChange={this.handleInputChange} disabled={value.type===''} value={value.item} readOnly={this.props.readOnly} data-row={row}>
									<option value="">{messages.choose || 'choose'} {messages.item || 'item'}</option>
									{items[row] ? Object.keys(items[row]).map((item, index) =>
										<option key={`item_${row}_${index}`} value={item}>{items[row][item]}</option>
									) : null}
								</Input>
							</FormGroup>
						</Col>
						<Col lg="3">
							<FormGroup>
								<Row>
									<Col xs="10" className="p-0">
										<InputGroup>
											<Input
												type="number"
												name="quantity"
												onChange={this.handleInputChange}
												disabled={value.item===''}
												value={value.quantity}
												min={0}
												placeholder={messages.quantity || 'quantity'}
												readOnly={this.props.readOnly}
												data-row={row}
											/>
											<InputGroupAddon addonType="append">
												<InputGroupText>{value.unit}</InputGroupText>
											</InputGroupAddon>
										</InputGroup>
									</Col>
									<Col xs="2" className="text-right">
										{ (row === this.state.values.length - 1 && value.quantity !== '') &&
											<i className="fa fa-plus" role="button" title={messages.add || 'add'} onClick={this.handleRowNumberChange}/>
										}
									</Col>
								</Row>
							</FormGroup>
						</Col>
					</Row>
				))}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	data: state.list,
});

LogisticsInput = connect(mapStateToProps)(LogisticsInput);

export default LogisticsInput;
