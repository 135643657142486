import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Form,
	FormGroup,
	FormFeedback,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from 'reactstrap';

import { Input } from 'core/components';
import { getValidation, validate } from 'core/ducks/forms';
import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { Loading } from 'core/components';
import T from 'modules/i18n';
import { Address } from 'input-fields';

import sample from 'core/img/sample.png';

class Register extends Component {

	constructor(props) {
		super(props);
		this.empty_state = {
			username: '',
			firstname: '',
			lastname: '',
			email: '',
			password: '',
			address: '',
			phone: '',
			meter_number: '',
			client_number: '',
			accepts_email: false,
		}
		this.state = {
			values: {
				...this.empty_state,
				password_repeat: ''
			},
			under_submit: false,
			submitted: false,
			showSample: false,
		}
		this.usernameRef = React.createRef();

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submit = this.submit.bind(this);
	}

	handleChange(event) {
		const { name, type, value } = event.target
		this.setState({
			values: {
				...this.state.values,
				[name]: type === 'checkbox' ? event.target.checked : value
			},
			under_submit: false
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		if (this.state.values.password !== this.state.values.password_repeat) {
			dispatch(pushNotification({
				body: "passwords do not match",
				type: "warning",
				duration: 2
			}));
		} else {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.setState({under_submit: true}, () => dispatch(validate(data, rules)));
		}
	}

	submit(data) {
		const { dispatch } = this.props;
		dispatch(postData('registration', data, false));
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(getValidation('users'));
	}

	componentDidUpdate(prevProps) {
		if (this.state.under_submit && this.props.valid) {
			let data = Object.assign({}, this.state.values);
			delete data.password_repeat;
			this.submit(data);
			this.setState({under_submit: false});
		}

		if (prevProps.post_pending && !this.props.post_pending) {
			const { post_status, dispatch } = this.props;
			if (post_status === 200) {
				dispatch(pushNotification({
					body: "successful registration",
					type: "success",
					duration: 3
				}));
				this.setState({submitted: true});
			} else if (post_status === 409) {
				this.usernameRef.current.select();
				dispatch(pushNotification({
					body: "username exists",
					type: "warning",
					duration: 3
				}));
			}
		}
	}

	render() {
		if (this.props.pending || this.props.validation_pending || this.props.validation_scope !== 'users')
			return <Loading />

		let message = this.props.notifications[this.props.notifications.length-1];
		const { rules } = this.props;
		const { values } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<div className="app-container flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Card className="mx-4">
							<CardBody className="p-4">
								<h1><T>register</T></h1>
								<p className="text-muted"><T>create your account</T></p>
								{ message &&
									<p className={message.type}><T>{message.body}</T></p>
								}
								<Form onSubmit={this.handleSubmit}>
									<fieldset disabled={this.state.submitted}>
										<Row>
											<Col md="6">
												<FormGroup tag="fieldset">
													<legend className="px-3 py-2 application-legend">
														<b>Στοιχεία Πρόσβασης</b>
													</legend>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="icon-user"></i>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															innerRef={this.usernameRef}
															type="text"
															placeholder={messages.username || 'username'}
															name="username"
															autoComplete="username"
															onChange={this.handleChange}
															minLength={rules.username.min_size}
															maxLength={rules.username.max_size}
															pattern={rules.username.validation}
															value={values.username}
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
														<FormFeedback>{rules.username.message}</FormFeedback>
													</InputGroup>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="icon-lock"></i>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															type="password"
															placeholder={messages.password || 'password'}
															name="password"
															autoComplete="new-password"
															onChange={this.handleChange}
															minLength={rules.password.min_size}
															maxLength={rules.password.max_size}
															pattern={rules.password.validation}
															value={values.password}
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
														<FormFeedback>{rules.password.message}</FormFeedback>
													</InputGroup>
													<InputGroup className="mb-4">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>
																<i className="fa fa-repeat"></i>
															</InputGroupText>
														</InputGroupAddon>
														<Input
															type="password"
															placeholder={messages['repeat password'] || 'repeat password'}
															name="password_repeat"
															autoComplete="new-password"
															onChange={this.handleChange}
															value={values.password_repeat}
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup tag="fieldset">
													<legend className="px-3 py-2 application-legend">
														<b>Προσωπικά Στοιχεία</b>
													</legend>
													<InputGroup className="mb-3">
														<Input
															type="text"
															placeholder={messages['given name'] || 'given name'}
															name="firstname"
															autoComplete="given-name"
															onChange={this.handleChange}
															minLength={rules.firstname.min_size}
															maxLength={rules.firstname.max_size}
															pattern={rules.firstname.validation}
															value={values.firstname}
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
														<FormFeedback>{rules.firstname.message}</FormFeedback>
													</InputGroup>
													<InputGroup className="mb-3">
														<Input
															type="text"
															placeholder={messages['family name'] || 'family name'}
															name="lastname"
															autoComplete="family-name"
															onChange={this.handleChange}
															minLength={rules.lastname.min_size}
															maxLength={rules.lastname.max_size}
															pattern={rules.lastname.validation}
															value={values.lastname}
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
														<FormFeedback>{rules.lastname.message}</FormFeedback>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup tag="fieldset">
													<legend className="px-3 py-2 application-legend">
														<b>Στοιχεία Σύνδεσης</b>
													</legend>
													<div>
														<div
															className="bg-light rounded px-5 py-2 font-weight-bold text-primary"
															style={{fontSize: '130%', minHeight: '3rem'}}
														>
															<div className="float-left">Υπόδειγμα</div>
															<div className="float-right">
																{ this.state.showSample ?
																	<i
																		className="fa fa-angle-up"
																		title="Απόκρυψη υποδείγματος"
																		onClick={() => {this.setState({showSample: false})}}
																	/>
																	:
																	<i
																		className="fa fa-angle-down"
																		title="Προβολή υποδείγματος"
																		onClick={() => {this.setState({showSample: true})}}
																	/>
																}
															</div>
														</div>
														<img src={sample} alt="sample" className={this.state.showSample ? 'd-block w-100' : 'd-none'}/>
													</div>
													<InputGroup className="mt-1 mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>A</InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															placeholder="Αριθμός Υδρομέτρου"
															autoComplete="off"
															name="meter_number"
															onChange={this.handleChange}
															maxLength={rules.meter_number.max_size}
															pattern={rules.meter_number.validation}
															value={values.meter_number}
														/>
														<FormFeedback>{rules.meter_number.message}</FormFeedback>
													</InputGroup>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>B</InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															placeholder="Κωδικός Καταναλωτή"
															autoComplete="off"
															name="client_number"
															onChange={this.handleChange}
															maxLength={rules.client_number.max_size}
															pattern={rules.client_number.validation}
															value={values.client_number}
														/>
														<FormFeedback>{rules.client_number.message}</FormFeedback>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col>
												<FormGroup tag="fieldset">
													<legend className="px-3 py-2 application-legend">
														<b>Στοιχεία Επικοινωνίας</b>
													</legend>
													<Address
														name="address"
														value={{address: '', location: {}}}
														onChange={this.handleChange}
														locale='el'
														showCoordinates={false}
														center={this.props.mapSettings.center}
														zoom={14}
													/>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText>@</InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															placeholder="Email"
															name="email"
															onChange={this.handleChange}
															minLength={rules.email.min_size}
															maxLength={rules.email.max_size}
															pattern={rules.email.validation}
															value={values.email}
															autoComplete="email"
															required
														/>
														<InputGroupAddon addonType="append">*</InputGroupAddon>
														<FormFeedback>{rules.email.message}</FormFeedback>
													</InputGroup>
													<InputGroup className="mb-3">
														<InputGroupAddon addonType="prepend">
															<InputGroupText><i className="fa fa-phone"/></InputGroupText>
														</InputGroupAddon>
														<Input
															type="text"
															placeholder={messages.phone || 'phone'}
															name="phone"
															minLength={rules.phone.min_size}
															maxLength={rules.phone.max_size}
															pattern={rules.phone.validation}
															onChange={this.handleChange}
															value={values.phone}
															autoComplete="tel"
														/>
														<FormFeedback>{rules.phone.message}</FormFeedback>
													</InputGroup>
												</FormGroup>
												<FormGroup check>
													<Label check>
														<Input
															type="checkbox"
															name="accepts_email"
															value={values.accepts_email}
															checked={values.accepts_email}
															onChange={this.handleChange}
														/>{' '}
														<T>accept emails</T>
													</Label>
												</FormGroup>
											</Col>
										</Row>
									</fieldset>
									<Row>
										<Col className="m-0 py-0 pr-3 text-muted text-right">
											*<T>required fields</T>.
										</Col>
									</Row>
									<Row>
										<Col sm="3">
											<Button type="button" color="primary" block onClick={() => {this.props.history.goBack()}}><i className="fa fa-backward"/> <T>back</T></Button>
										</Col>
										<Col sm="9">
											<Button disabled={this.state.submitted} type="submit" color="success" block><T>create account</T></Button>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	pending: state.forms.pending,
	rules: state.forms.validation.rules,
	validation_pending: state.forms.validation.pending,
	validation_scope: state.forms.validation.scope,
	valid: state.forms.valid,
	notifications: state.notifications.messages,
	post_pending: state.update.sending,
	post_status: state.update.status,
	mapSettings: state.ui.settings.values.map,
});

Register = connect(mapStateToProps)(Register);

export default Register;
