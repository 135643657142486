import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const WorkflowsMap = '/workflows/map';

export const StaticRoutes = {
	WorkflowsMap,
};

export const routes = [
	{
		url: WorkflowsMap,
		name: 'map',
		description: 'A map with the workflows',
		title: 'links.workflows.map',
		defaultTitle: 'Workflows map',
		component: 'WorkflowsMap',
		contextComponent: 'WorkflowsMap',
		role: roles.REVIEWER
	},
];

const combined = { StaticRoutes, routes };

export default combined;
