import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	Row, Col, Form, FormGroup, Input, Label, Button, InputGroup, InputGroupAddon, InputGroupText, Table, FormText
} from 'reactstrap';

import { getParameters } from 'core/model/lib/urlTools';
import { routes } from '../../model/routes';
import { getContent, setContent, resetContent, submit } from 'core/ducks/forms';
import { requestData } from 'core/ducks/list';
import T from 'modules/i18n';
import { applicationStatus, applicationContentTypes, applicationRoles, applicationTypes } from 'flows-app/model/constants';
import { characterConverter as converter } from 'core/model/lib';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';

class EditNodeTab extends Component {

	constructor(props) {
		super(props);
		this.actions = bindActionCreators({toggleModal}, props.dispatch);
		this.initialPermissions = applicationRoles.map(role => ({
			role,
			read: false,
			write: false
		}));

		this.state = {
			newOption: '',
			mnameEdited: false,
		};

		this.lastOptionRef = React.createRef();

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleOptionsChange = this.handleOptionsChange.bind(this);
		this.handleOptionAdd = this.handleOptionAdd.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handlePermissionChange = this.handlePermissionChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount () {
		this.props.dispatch( requestData('forms', 'admin/forms') );
		this.props.dispatch( requestData('template', 'admin/template/fields/type') );
		this.setContent();
	}

	componentDidUpdate(prevProps, prevState) {
		const { form_values } = this.props;
		if (
			( (!prevProps.form_values.node && form_values.node) || (prevProps.form_values.node.mname !== form_values.node.mname &&
				( prevProps.form_pending && !this.props.form_pending ))
			) ||
			prevProps.location.pathname !== this.props.location.pathname
		) {
			this.setState({mnameEdited: true});
			this.setContent();
		} else if (prevProps.form_values.node.id && !form_values.node.id) {
			this.setContent();
		}
		if (prevState.newOption !== '' && this.state.newOption === '' && this.lastOptionRef.current)
			this.lastOptionRef.current.focus();
		if (
			(!prevProps.form_values.permissions && form_values.permissions) ||
			prevProps.form_values.permissions !== form_values.permissions
		)
			if (form_values.permissions.length === 0) {
				this.props.dispatch( resetContent('permissions') );
				this.props.dispatch( setContent('permissions', this.initialPermissions) );
			}
	}

	setContent() {
		const { dispatch } = this.props;
		const { node } = this.props.form_values;
		// dispatch( setContent('node', node) );
		if (!node)
			return;
		if ( node.mname === '' ) {
			dispatch( resetContent('permissions') );
			dispatch( setContent('permissions', this.initialPermissions) );
		} else {
			dispatch( getContent(`admin/permissions/node/${node.mname}`, 'permissions') );
		}
	}

	handleInputChange(event) {
		const target = event.target;
		let value = target.value;
		if (target.name === 'mname') {
			value = value.toLowerCase();
			value = value.split(' ').join('_');
			value = value.split('-').join('_');
			if (!/^([a-zA-Z0-9_]*)$/.test(value))
				return;
			this.setState({mnameEdited: value!==''});
		}
		if (!this.state.mnameEdited && target.name === 'label') {
			this.props.dispatch(setContent('node', {
				label: value,
				mname: converter(value),
			}));
		} else {
			let content = {};
			if (target.name === 'type') {
				switch (value) {
					case 'boolean':
						content = {content: null, options: null, transition: null, workflow_content: null};
						break;
					case 'categorical':
						content = {content: null, transition: null, workflow_content: null};
						break;
					case 'form':
						content = {content_text: null, options: null, transition: null, workflow_content: null};
						break;
					case 'workflow':
						content = {content: null, options: null, transition: null};
						break;
					case 'transition':
						content = {content: null, options: null, workflow_content: null};
						break;
					case 'register':
						content = {content: null, options: null, transition: null, workflow_content: null};
						break;
					case 'email':
						content = {
							content: null,
							options: applicationRoles.map(role => role).reduce((obj, key) => ({...obj, [key]: ''}), {}),
							content_text: null, transition: null, workflow_content: null};
						break;
					default:
						content = {};
				}
			}
			content = {...content, [target.name]: target.type==='checkbox' ? target.checked : value};
			this.props.dispatch( setContent('node', content) );
		}
	}

	handleOptionsChange(event, index=null) {
		const target = event.target;

		if (index===null) {
			this.setState({newOption: target.value});
			return;
		}

		const { options } = this.props.form_values.node;
		var element_key;
		var element_value;

		let new_options = target.name==='key' ?
			Object.keys(options)
				.map((key, i) => {
					if (index === i) {
						element_key = target.value;
						element_value = options[key];
						return element_key;
					}
					return key;
				})
				.filter(key => key!=='')
				.reduce((obj, key) => ({
					...obj,
					[key]: key===element_key ? element_value : options[key]
				}), {})
			:
			Object.keys(options).map((key, i) => {
				if (index === i)
					element_key = key;
				return key;
			}).reduce((obj, key) => ({
				...obj,
				[key]: key===element_key ? target.value : options[key]
			}), {});
		this.props.dispatch(setContent('node', {
			options: new_options
		}));
	}

	handleOptionAdd(event) {
		const value = event.target.value;
		if (value === '')
			return;
		const { options } = this.props.form_values.node;
		this.props.dispatch(setContent('node', {
			options: {...options, [this.state.newOption]: ''}
		}));
		this.setState({newOption: ''});
	}

	handlePermissionChange(event, role, attr) {
		const target = event.target;
		let content = this.props.form_values.permissions;
		content = content.map(entry =>
			role===entry.role ? {...entry, [attr]: target.checked} : entry
		);
		this.props.dispatch( setContent('permissions', content) );
	}

	handleFormSubmit(event) {
		event.preventDefault();
		const node = this.props.form_values.node.mname;
		let params = getParameters(this.props.location.pathname, routes);
		if (!params.workflow)
			return;
		let path = `admin/nodes/workflow/${params.workflow}`;
		path = this.props.method.node ? path : `${path}/id/${this.props.form_values.node.id}`;
		this.props.dispatch( submit(path, 'node') ).then(() => {
			if (this.props.http_status === 200 || this.props.http_status === '')
				this.props.dispatch( submit(`admin/permissions/node/${node}`, 'permissions') );
		});
	}

	handleDelete() {
		const { id } = this.props.form_values.node;
		if ( id ) {
			let path = `admin/nodes/id/${id}`;
			console.log(path);
			// this.props.dispatch( deleteData(path) );
		}
	}

	render() {
		const { forms, workflows } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		const { node, permissions, nodeReadOnly } = this.props.form_values;

		if (!permissions || !node)
			return null;

		return (
			<Form onSubmit={this.handleFormSubmit}>
				<FormGroup tag="fieldset" className="w-100 border px-1 pb-3 m-0">
					<legend className="w-auto d-inline-block text-inherit text-muted mb-0">
						<T>{this.props.method.node ? 'add' : 'edit'}</T>
					</legend>
					{ nodeReadOnly.readOnly &&
						<FormText className="px-2 m-0 text-justify">
							Some fields are displayed as read-only, since either this node has active outgoing connections
							with others either there are unsaved connections.
						</FormText>
					}
					<FormGroup className="mb-1 pt-3">
						<Label className="w-100">
							<T>label</T>
							<Input
								type="text"
								value={node.label}
								name="label"
								onChange={this.handleInputChange}
							/>
						</Label>
					</FormGroup>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>identifier</T>
							<Input
								type="text"
								value={node.mname}
								name="mname"
								onChange={this.handleInputChange}
							/>
						</Label>
					</FormGroup>
					<FormGroup>
						<T>content type</T>
						<Input
							type="select"
							value={node.content_type}
							name="content_type"
							onChange={this.handleInputChange}
						>
							<option value="">---{messages.select_content_type}---</option>
							{ Object.keys(applicationContentTypes).map((key) =>
								<option key={`cont_type_${key}`} value={key}>
									{applicationContentTypes[key]}
								</option>
							) }
						</Input>
					</FormGroup>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>type</T>
							<Input
								disabled={nodeReadOnly.readOnly}
								type="select"
								value={node.type}
								name="type"
								onChange={this.handleInputChange}
							>
								<option value="">---{messages.select_type}---</option>
								{applicationTypes.map(type => <option key={`option_${type}`} value={type}>{type}</option>)}
							</Input>
						</Label>
					</FormGroup>
					{ (node.type==='form' || node.type==='workflow') &&
						<FormGroup className="mb-1">
							<Label className="w-100">
								<T>content</T>
								{ node.type==='form' &&
									<Input
										type="select"
										value={node.content}
										name="content"
										onChange={this.handleInputChange}
									>
										<option value="">---{messages.select_form_content}---</option>
										{ Object.keys(forms.data).map(key =>
											<option key={`form_${key}`} value={key}>
												{forms.data[key]}
											</option>
										) }
									</Input>
								}
								{ node.type==='workflow' &&
									<Input
										type="select"
										value={node.workflow_content}
										name="workflow_content"
										onChange={this.handleInputChange}
									>
										{ Object.keys(workflows.data).map(key =>
											<option key={`workflow_${key}`} value={key}>{workflows.data[key].name}</option>
										) }
									</Input>
								}
							</Label>
						</FormGroup>
					}
					{ node.type==='transition' &&
						<FormGroup className="mb-1">
							<Label className="w-100">
								<T>transition</T>
								<Input
									type="select"
									value={node.transition}
									name="transition"
									onChange={this.handleInputChange}
								>
									{ applicationStatus.map((status) => (
										<option key={`option_${status}`} value={status}>
											{messages[status]}
										</option>
									))}
								</Input>
							</Label>
						</FormGroup>
					}
					{ (node.type==='transition' || node.type==='boolean' || node.type==='categorical' || node.type==='workflow') &&
						<FormGroup className="mb-1">
							<Label className="w-100">
								<T>content text</T>
								<Input
									type="text"
									value={node.content_text}
									name="content_text"
									onChange={this.handleInputChange}
								/>
							</Label>
						</FormGroup>
					}
					{ node.type==='categorical' &&
						<FormGroup tag="fieldset" className="w-100 border px-1 py-1 mr-0 mb-2">
							<legend className="w-auto d-inline-block text-inherit text-muted mb-0">
								<T>options</T>
							</legend>
							{ Object.keys(node.options).map((key, index) => (
								<FormGroup key={`option_${index}`}>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText title="key">
												<i className="fa fa-key"/>
											</InputGroupText>
										</InputGroupAddon>
										<input
											readOnly={nodeReadOnly.readOnly}
											className="form-control"
											type="text"
											value={key}
											name="key"
											onChange={(e) => this.handleOptionsChange(e, index)}
										/>
									</InputGroup>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText title="value">
												<i className="fa fa-commenting-o"/>
											</InputGroupText>
										</InputGroupAddon>
										<input
											ref={index===Object.keys(node.options).length - 1 && this.lastOptionRef}
											className="form-control"
											type="text"
											value={node.options[key]}
											onChange={(e) => this.handleOptionsChange(e, index)}
										/>
									</InputGroup>
								</FormGroup>
							))}
							<FormGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText title="key">
											<i className="fa fa-key"/>
										</InputGroupText>
									</InputGroupAddon>
									<input
										className="form-control"
										type="text"
										value={this.state.newOption}
										onChange={this.handleOptionsChange}
										onBlur={this.handleOptionAdd}
									/>
								</InputGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText title="value">
											<i className="fa fa-commenting-o"/>
										</InputGroupText>
									</InputGroupAddon>
									<input
										className="form-control"
										type="text"
										value=""
										disabled
									/>
								</InputGroup>
							</FormGroup>
						</FormGroup>
					}
					{ node.type==='email' &&
						<FormGroup tag="fieldset" className="w-100 border px-1 py-1 mr-0 mb-2">
							<legend className="w-auto d-inline-block text-inherit text-muted mb-0">
								<T>options</T>
							</legend>
							{ Object.keys(node.options).map((role, index) =>
								<FormGroup key={`email_opts_${role}`}>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText title="key">
												<i className="fa fa-key"/>
											</InputGroupText>
										</InputGroupAddon>
										<input
											className="form-control"
											type="text"
											value={role}
											readOnly
										/>
									</InputGroup>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText title="value">
												<i className="fa fa-commenting-o"/>
											</InputGroupText>
										</InputGroupAddon>
										<select
											className="form-control"
											value={node.options[role]}
											onChange={(e) => this.handleOptionsChange(e, index)}
										>
											<option value=''>{messages['do not send email']}</option>
											{ Object.values(this.props.templates).map(value =>
												<option key={`email_opt_${value}`} value={value}>{value}</option>
											)}
										</select>
									</InputGroup>
								</FormGroup>
							)}
						</FormGroup>
					}
					<FormGroup tag="fieldset" className="w-100 border px-1 py-1 mr-0 mb-2">
						<legend className="w-auto d-inline-block text-inherit text-muted mb-0">
							<T>permissions</T>
						</legend>
						<Table className="w-100">
							<thead>
								<tr>
									<th></th>
									<th title="read">R</th>
									<th title="write">W</th>
								</tr>
							</thead>
							<tbody>
								{permissions.map(entry =>
									<tr key={`row_${entry.role}`}>
										<td>{entry.role}</td>
										<td>
											<input type="checkbox" checked={entry.read} onChange={(e) => this.handlePermissionChange(e, entry.role, 'read')} />
										</td>
										<td>
											<input type="checkbox" checked={entry.write} onChange={(e) => this.handlePermissionChange(e, entry.role, 'write')} />
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</FormGroup>
				</FormGroup>
				<Row>
					<Col className="mb-1 mt-0 text-center">
						<Button
							color="danger"
							type="button"
							className={this.props.method.node ? 'd-none' : 'mr-2'}
							title={messages.delete}
							onClick={
								() => this.actions.toggleModal(true,
									<Alert
										toggle={this.actions.toggleModal}
										title="drop confirm"
										message="do you wish to continue"
										onConfirm={this.handleDelete}
									/>
								)
							}
						>
							<i className="fa fa-trash" />
						</Button>
						<Button color="primary" type="submit"><T>{this.props.method.node ? 'add' : 'save'}</T></Button>
					</Col>
				</Row>
			</Form>
		);
	}

}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	forms: state.list.forms,
	templates: state.list.template.data,
	form_values: state.forms.content,
	form_pending: state.forms.pending,
	method: state.forms.post,
	http_status: state.forms.status,
});

EditNodeTab = connect(mapStateToProps)(EditNodeTab);

export default withRouter(EditNodeTab);
