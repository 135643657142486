import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavItem } from 'reactstrap';

import { requestReindex } from '../../ducks/reindexDB';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';

class ReindexDB extends Component {

	render() {
		const { pending, dispatch, ...other } = this.props;
		const icon = pending ? "fa fa-spin fa-retweet" : "fa fa-retweet";
		return (
			<SecureContent role={roles.ADMIN}>
				<NavItem {...other} className="d-md-down-none">
					<i
						className={icon}
						title="Re-index database."
						role="link"
						onClick={() => {
							if (!pending) {
								dispatch( requestReindex() )
							}
						}}
					/>
				</NavItem>
			</SecureContent>
		);
	}
}

const mapStateToProps = (state) => ({
	pending: state.reindexDB.pending,
});

ReindexDB = connect(mapStateToProps)(ReindexDB);

export default ReindexDB;
