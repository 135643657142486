import * as roles from 'core/model/roles';

/**
 * Static routes
 */
const AdminWorkflowsList = '/admin/workflows';
const MyWorkflows = '/myworkflows';

export const StaticRoutes = {
	AdminWorkflowsList,
	MyWorkflows
};

/**
 * Dynamic routes
 */
const WorkflowsListStatus = AdminWorkflowsList + '/(status)?/:status?'
const WorkflowsListStatusTag = AdminWorkflowsList + '/tag/:tag/(status)?/:status?';

export const DynamicRoutes = {
	WorkflowsListStatus,
	WorkflowsListStatusTag
};

export const routes = [
	{
		url: WorkflowsListStatus,
		name: 'workflows',
		component: 'WorkflowListWithTags',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: MyWorkflows,
		name: 'workflows',
		to: WorkflowsListStatus,
		role: roles.REVIEWER,
	},
	{
		url: WorkflowsListStatusTag,
		name: 'tags',
		component: 'WorkflowListWithTags',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: MyWorkflows,
		name: 'my workflows',
		component: 'WorkflowListWithTags',
		role: roles.AUTHORIZED,
		props: {
			personal: true
		},
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
