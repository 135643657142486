import React, { Component } from "react";

import Draggable from "react-draggable";

import NodeInputList from "./NodeInputList";
import NodeOuputList from "./NodeOutputList";

// import nodestyle from "./styles/node";
import nodeContentstyle from "./styles/nodeContent";
import nodeHeaderstyle from "./styles/nodeHeader";
import EditIcon from "./EditIcon";
import { GraphContextConsumer } from '../react-node-graph';

class Node extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: props.context.nodeSelected === props.nid
		};
	}

	handleDragStart(event, ui) {
		this.props.onNodeStart(this.props.nid, ui);
	}

	handleDragStop(event, ui) {
		this.props.onNodeStop(this.props.nid, { x: ui.x, y: ui.y });
	}

	handleDrag(event, ui) {
		this.props.onNodeMove(this.props.index, { x: ui.x, y: ui.y });
	}

	onStartConnector(index) {
		this.props.onStartConnector(this.props.nid, index);
	}

	onCompleteConnector(index) {
		this.props.onCompleteConnector(this.props.nid, index);
	}

	handleClick(e) {
		this.props.context.selectNode(this.props.nid);
		if (this.props.onNodeSelect) {
			this.props.onNodeSelect(this.props.nid);
		}
	}

	handleEditClick(nid) {
		if (!this.state.selected)
			this.handleClick();
	}

	componentDidUpdate(prevProps) {
		if (this.state.selected && this.props.context.nodeSelected==='') {
			if (this.props.onNodeDeselect)
				this.props.onNodeDeselect(this.props.nid);
		}
		if (prevProps.context.nodeSelected !== this.props.context.nodeSelected)
			this.setState({ selected: this.props.context.nodeSelected === this.props.nid });
	}

	render() {
		let { grid } = this.props;
		let { selected } = this.state;

		let nodeClass = "node" + (selected ? " selected" : "");

		return (
			<React.Fragment>
				<Draggable
					position={{ x: this.props.pos.x, y: this.props.pos.y }}
					handle=".node-header"
					grid={grid}
					onStart={(event, ui) => this.handleDragStart(event, ui)}
					onStop={(event, ui) => this.handleDragStop(event, ui)}
					onDrag={(event, ui) => this.handleDrag(event, ui)}
				>
					<section className={nodeClass}>
						<header className="node-header" style={nodeHeaderstyle} title={this.props.nid}>
							<span className="node-title">
								<span style={{color: 'darkred'}}>({this.props.content_type})</span>{' '}
								{this.props.title}
							</span>
							<span className="node-header-icon">
								<EditIcon onClick={() => this.handleEditClick(this.props.nid)} />
							</span>
						</header>
						<div style={nodeContentstyle}>
							<NodeInputList
								items={this.props.inputs}
								onCompleteConnector={index => this.onCompleteConnector(index)}
							/>
							<small className="nodeContent" title={this.props.content && this.props.content}>
								{this.props.type}{this.props.content && ': '}
								<span style={{color: '#929292'}}>{this.props.content && this.props.content}</span>
							</small>
							<NodeOuputList
								items={this.props.outputs}
								onStartConnector={index => this.onStartConnector(index)}
							/>
						</div>
					</section>
				</Draggable>
			</React.Fragment>
		);
	}
}

export default props => (
	<GraphContextConsumer>
		{context => <Node {...props} context={context} />}
	</GraphContextConsumer>
);
