import { TagInput } from './components';

const fieldTypes = {
	tag: {
		name: 'tag',
		component: TagInput
	}
};

const constants = { fieldTypes };

export default constants;
