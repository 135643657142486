import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: 8 + 'px',
	margin: '4px 0',

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'inherit',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListClassName = isDraggingOver => ({
	className: isDraggingOver ? 'draggable-container:dragging' : 'draggable-container',
});

class DragDrop extends Component {

	constructor(props) {
		super(props);

		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		if (!result.destination)
			return;

		const items = reorder(
			this.props.items,
			result.source.index,
			result.destination.index
		);

		this.props.onDragEnd(items);
	}

	render() {
		const { children, items } = this.props;

		if (!children || !items)
			return null;

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...getListClassName(snapshot.isDraggingOver)}
						>
							{ children.map((child, index) => (
								<Draggable key={child.props.id} draggableId={child.props.id} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
										>
											{child}
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}

}

DragDrop.propTypes = {
	onDragEnd: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
};

export default DragDrop;
