import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { parse } from '../ducks/parsePdf';
import { File } from 'file-upload';

class ParsePDF extends Component {

	constructor(props) {
		super(props);
		this.state = {
			filename: '',
			uuid: '',
			values: {},
		};

		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		this.setState({uuid: event.target.value});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.uuid !== prevState.uuid) {
			this.props.dispatch( parse(this.state.uuid, this.props.form, this.props.application) );
		}
	}

	render() {
		return (
			<File
				name="temp"
				locale={this.props.locale}
				value={this.state.filename}
				filetype={['application/pdf']}
				maxFileSize={this.props.maxFileSize || undefined}
				onChange={this.handleFileChange}
			/>
		);
	}
}

ParsePDF.propType = {
	locale: PropTypes.string.isRequired,
	maxFileSize: PropTypes.string,
};

ParsePDF = connect(null)(ParsePDF);

export default ParsePDF;
