import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'logistics',
		url: StaticRoutes.Logistics,
		icon: 'fa fa-shopping-cart',
		role: roles.EDITOR,
		children: [
			{
				name: 'dashboard',
				url: StaticRoutes.LogisticsDashboard
			},
			{
				name: 'new products',
				url: StaticRoutes.LogisticsForm
			},
		]
	},
];

export default menu;
