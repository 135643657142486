import React, { Component } from 'react';

class Marker extends Component {
	render() {

		const fill = this.props.color || '#fff';
		const opacity = this.props.opacity || 1;

		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="36"
				width="22"
				viewBox="0 0 22 36"
				version="1.1"
				x="0px"
				y="0px"
				id="svg12"
				fill={fill}
				opacity={opacity}
			>
				<g
					transform={this.props.rotated ? 'matrix(0.38412469,0.10382053,-0.10322084,0.38857112,93.294438,-376.19215)' : 'matrix(0.39942835,0.03475859,-0.03517424,0.39989352,25.116691,-384.58013)'}
					id="g10"
				>
					<path
						style={{opacity:1,fill,fillOpacity:1,stroke:'none',strokeWidth:10,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:4,strokeDasharray:'none',strokeDashoffset:0,strokeOpacity:1}}
						d="m 49.97913,957.36217 -25.142829,13.00245 0.02098,25.9723 25.397202,51.02528 24.909175,-51.05789 -0.02127,-25.9723 z m 0.0207,3.96409 21.416962,11.05742 v 22.11454 L 49.99983,1005.5556 28.58316,994.49822 v -22.11454 z"
						id="path2"
					/>
					<path
						style={{color:'#000000',fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'medium',lineHeight:'normal',fontFamily:'sansSerif',textIndent:0,textAlign:'start',textDecoration:'none',textDecorationLine:'none',textDecorationStyle:'solid',textDecorationColor:'#000000',letterSpacing:'normal',wordSpacing:'normal',textTransform:'none',writingMode:'lrTb',direction:'ltr',baselineShift:'baseline',textAnchor:'start',whiteSpace:'normal',clipRule:'nonzero',display:'inline',overflow:'visible',visibility:'visible',opacity:1,isolation:'auto',mixBlendMode:'normal',colorInterpolation:'sRGB',colorInterpolationFilters:'linearRGB',solidColor:'#000000',solidOpacity:1,fill,fillOpacity:1,fillRule:'nonzero',stroke:'none',strokeWidth:2.35664225,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeDashoffset:0,strokeOpacity:1,colorRendering:'auto',imageRendering:'auto',shapeRendering:'auto',textRendering:'auto',enableBackground:'accumulate'}}
						d="m 50,968.5332 -1.054688,2.10742 -12.011718,24.02735 h 26.132812 z m 0,5.27149 9.253906,18.50586 H 40.746094 Z"
						id="path4"
					/>
					<path
						style={{color:'#000000',fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'medium',lineHeight:'normal',fontFamily:'sansSerif',textIndent:0,textAlign:'start',textDecoration:'none',textDecorationLine:'none',textDecorationStyle:'solid',textDecorationColor:'#000000',letterSpacing:'normal',wordSpacing:'normal',textTransform:'none',writingMode:'lrTb',direction:'ltr',baselineShift:'baseline',textAnchor:'start',whiteSpace:'normal',clipRule:'nonzero',display:'inline',overflow:'visible',visibility:'visible',opacity:1,isolation:'auto',mixBlendMode:'normal',colorInterpolation:'sRGB',colorInterpolationFilters:'linearRGB',solidColor:'#000000',solidOpacity:1,fill,fillOpacity:1,fillRule:'nonzero',stroke:'none',strokeWidth:2.35664201,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeDashoffset:0,strokeOpacity:1,colorRendering:'auto',imageRendering:'auto',shapeRendering:'auto',textRendering:'auto',enableBackground:'accumulate'}}
						d="m 48.845703,987.57617 v 2.04492 h 2.357422 v -2.04492 z"
						id="path6"
					/>
					<path
						style={{color:'#000000',fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'medium',lineHeight:'normal',fontFamily:'sansSerif',textIndent:0,textAlign:'start',textDecoration:'none',textDecorationLine:'none',textDecorationStyle:'solid',textDecorationColor:'#000000',letterSpacing:'normal',wordSpacing:'normal',textTransform:'none',writingMode:'lrTb',direction:'ltr',baselineShift:'baseline',textAnchor:'start',whiteSpace:'normal',clipRule:'nonzero',display:'inline',overflow:'visible',visibility:'visible',opacity:1,isolation:'auto',mixBlendMode:'normal',colorInterpolation:'sRGB',colorInterpolationFilters:'linearRGB',solidColor:'#000000',solidOpacity:1,fill,fillOpacity:1,fillRule:'nonzero',stroke:'none',strokeWidth:2.35664225,strokeLinecap:'butt',strokeLinejoin:'miter',strokeMiterlimit:4,strokeDasharray:'none',strokeDashoffset:0,strokeOpacity:1,colorRendering:'auto',imageRendering:'auto',shapeRendering:'auto',textRendering:'auto',enableBackground:'accumulate'}}
						d="m 48.845703,979.38281 v 7.25586 h 2.357422 v -7.25586 z"
						id="path8"
					/>
				</g>
			</svg>
		);
	}
}

export default Marker;
