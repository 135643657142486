export const dot = {
	height: 5 + 'px',
	width: 5 + 'px',
	backgroundColor: '#bbb',
	borderRadius: 50 + '%',
	display: 'inline-block'
};

export const box = {
	height: 5 + 'px',
	width: 5 + 'px',
	backgroundColor: '#1562ad',
	display: 'inline-block'
}