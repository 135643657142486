import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Nav, NavItem, NavLink,
	TabContent, TabPane, Badge,
	Form, FormGroup, Input,
} from 'reactstrap';

import { StaticRoutes, DynamicRoutes, routes } from '../../model/routes';
import { buildPath, getParameters } from 'core/model/lib/urlTools';
import { requestData } from 'core/ducks/list';
import T from 'modules/i18n';
import EditNodeTab from './editNodeTab';
import EditWorkflowTab from './editWorkflowTab';

class EditWorkflows extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: '0',
			current_workflow: '',
		}

		this.toggle = this.toggle.bind(this);
		this.handleWorkflowSelect = this.handleWorkflowSelect.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleWorkflowSelect(event) {
		const value = event.target.value;
		this.setState({
			current_workflow: value
		});
		let path = value==='' ? StaticRoutes.EditWorkflows : buildPath(DynamicRoutes.EditWorkflows, [value]);
		this.props.history.push(path);
	}

	componentDidMount() {
		this.initiate();
	}

	componentDidUpdate(prevProps) {
		if (
			( prevProps.submitting.workflow && !this.props.submitting.workflow && this.props.http_status === 200 ) &&
			this.props.workflow !== this.props.forms.workflow.mname
		) {
			let path = buildPath(DynamicRoutes.EditWorkflows, [this.props.forms.workflow.mname]);
			this.props.history.push(path);
		}
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.initiate();
		}
	}

	initiate() {
		this.props.dispatch( requestData('workflow') );
		let params = getParameters(this.props.location.pathname, routes);
		this.setState({
			current_workflow: (params.workflow)
				? params.workflow
				: '',
		});
	}

	render() {

		const { workflows } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		if ( workflows.pending || (workflows.status !== 200 && workflows.status !== '') )
			return null;

		return (
			<React.Fragment>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>flows</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>{this.state.current_workflow !== '' ? 'edit' : 'add'}</T>
						</NavLink>
					</NavItem>
					{ this.state.current_workflow !== '' &&
						<NavItem>
							<NavLink
								className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => { this.toggle('2'); }}
							>
								<T>node</T>
							</NavLink>
						</NavItem>
					}
				</Nav>

				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					{/*Workflows tab*/}
					<TabPane tabId="0">
						<Form>
							<FormGroup>
								<Input
									type="select"
									value={this.state.current_workflow}
									onChange={this.handleWorkflowSelect}
								>
									<option value="">---{messages['select workflow']}---</option>
									{ Object.keys(workflows.data).map((mname) =>
										<option key={`workflows_${mname}`} value={mname}>
											{workflows.data[mname].name}
										</option>
									) }
								</Input>
							</FormGroup>
						</Form>
						{(this.state.current_workflow !== '' && this.props.forms.workflow) &&
							<Row>
								<Col className="text-right py-0">
									<Badge color={this.props.forms.workflow.valid ? 'success' : 'danger'}>
										<T>{this.props.forms.workflow.valid ? 'valid' : 'not valid'}</T>
									</Badge>
								</Col>
							</Row>
						}
					</TabPane>

					{/*Edit tab*/}
					<TabPane tabId="1">
						<EditWorkflowTab workflow={this.state.current_workflow} />
					</TabPane>

					{/*Node tab*/}
					<TabPane tabId="2">
						<EditNodeTab workflows={workflows} />
					</TabPane>
				</TabContent>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	workflows: state.list.workflow,
	forms: state.forms.content,
	submitting: state.forms.submitting,
	http_status: state.forms.status,
});

EditWorkflows = connect(mapStateToProps)(EditWorkflows);

export default EditWorkflows;
