import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';

import layersImg from '../img/layers.png';

class LayersButton extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
	}

	handleClick() {
		this.setState({
			showMenu: !this.state.showMenu
		});
	}

	handleCheckChange(event) {
		const { value, checked } = event.target;
		this.props.onLayerChange({
			[value]: {
				...this.props.layers[value],
				checked,
			}
		});
	}

	render() {
		const { layers } = this.props;

		return (
			<Control position="topleft" >
				<img className="border bg-white p-2 rounded" role="button" src={layersImg} alt="layers" height={32} width={32} onClick={this.handleClick} />
				{ this.state.showMenu &&
					<Container className="border rounded-bottom p-2" style={{backgroundColor: 'rgba(200,200,200,0.5)'}}>
						{Object.keys(layers).map(key => (
								<label key={`layer_${layers[key].name}`} className="input-container mb-3">
									{` ${layers[key].name}`}
									<input
										type="checkbox"
										checked={layers[key].checked}
										onChange={this.handleCheckChange}
										value={key}
										name={layers[key].name}
									/>
									<span className="checkmark" style={layers[key].checked ? {backgroundColor: layers[key].style.color} : {}}/>
								</label>
						))}
					</Container>
				}
			</Control>
		);
	}
}

LayersButton.propTypes = {
	layers: PropTypes.object.isRequired,
	onLayerChange: PropTypes.func.isRequired,
};

export default LayersButton;
