/**
 * Table component
 *
 * Example of usage:
 *
 *<Table>
 *	<Title>
 *		<T>users</T>
 *		<Button type="toolbox" />
 *	</Title>
 *	<Toolbox onReset>
 *		<Search
 *			placeholder
 *			onChange
 *		/>
 *		<Button type="resetFilters" >
 *			Reset
 *		</Button>
 *		<Button type="csv" onClick >
 *			Export csv
 *		</Button>
 *		<FilterGroup>
 *			<Filter onChange >
 *				<option value >
 *					i18n.value
 *				</option>
 *			</Filter>
 *			<DateFilter onChange />
 *		</FilterGroup>
 *	</Toolbox>
 *	<Thead>
 *		<Tr data />
 *	</Thead>
 *	<Tbody refreshing>
 *		<Tr data >
 *			<Button type="edit" title="edit" onClick />
 *			<Button type="view" title="view" onClick />
 *			<Button type="drop" title="drop" onClick />
 *		</Tr>
 *	</Tbody>
 *	<Pagination />
 *</Table>
 */

export { default as Button } from './components/button';
// export { default as DateFilter } from './components/dateFilter';
export { default as Filter } from './components/filter';
export { default as FilterGroup } from './components/filterGroup';
export { default as Pagination } from './components/pagination';
export { default as Search } from './components/search';
export { default as Table } from './components/table';
export { default as Tbody } from './components/tbody';
export { default as Tfoot } from './components/tfoot';
export { default as Tr } from './components/tr';
export { default as Thead } from './components/thead';
export { default as Title } from './components/title';
export { default as Toolbox } from './components/toolbox';
