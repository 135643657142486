import React, { Component } from 'react';
import { Map as LeafletMap, TileLayer, GeoJSON } from 'react-leaflet';
import PropTypes from 'prop-types';
import hash from 'object-hash';

import LayersButton from './layersButton';

const selectedStyle = {
	color: 'red',
	fillOpacity: 0.7
};

class Map extends Component {

	constructor(props) {
		super(props);
		this.state = {
			bbox: [],
			originalStyle: {},
			selected: null,
		};

		this.mapRef = React.createRef();
		const { layers } = props;
		this.layerRef = {};
		Object.keys(layers).forEach(key => {
			this.layerRef[key] = React.createRef();
		});

		this.handleMapChange = this.handleMapChange.bind(this);
	}

	componentDidMount() {
		this.handleMapChange({target: this.mapRef.current.leafletElement});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedFeature !== this.props.selectedFeature) {
			const { layer, id } = this.props.selectedFeature;
			const bounds = this.layerRef[layer].current.leafletElement.getBounds();
			this.mapRef.current.leafletElement.fitBounds(bounds);
			this.setState({
				selected: {
					layer,
					id,
					style: this.state.originalStyle,
				}
			});
		}
	}

	handleMapChange(event) {
		const bounds = event.target.getBounds();
		let northEast = bounds._northEast;
		northEast = [northEast.lng, northEast.lat];
		let southWest = bounds._southWest;
		southWest = [southWest.lng, southWest.lat];
		this.setState({
			bbox: [...southWest, ...northEast, 4326]
		}, () => this.props.onChange(this.state.bbox));
	}

	handleLayerClick = (e) => {
		const { id, status } = e.target.feature.properties;
		const { originalStyle } = this.state;
		this.setState({
			originalStyle: selectedStyle,
			selected: {
				layer: status,
				id,
				style: originalStyle,
			}
		});
		this.props.onFeatureSelect(status, id);
	}

	resetSelect = (layer, style) => {
		layer.setStyle(style);
	}

	highlightFeature = (e) => {
		const layer = e.target;
		if (this.state.selected && layer.feature.properties.id === this.state.selected.id)
			return;

		this.setState({
			originalStyle: {...layer.options.style},
		});

		layer.setStyle(selectedStyle);
	}

	resetHighlight = (component, e) => {
		const layer = e.target;
		layer.setStyle(this.state.originalStyle);
		this.setState({
			originalStyle: {}
		});
	}

	onEachFeature = (component, feature, layer) => {
		// eslint-disable-next-line
		if (feature.properties.id == this.props.selectedFeature)
			this.handleLayerClick({target: {feature, layer}});
		layer.on({
			mouseover: this.highlightFeature,
			mouseout: this.resetHighlight.bind(null, component),
			click: this.handleLayerClick
		});
	}

	getStyle = (feature) => {
		const { style } = this.props.layers[feature.properties.status];
		const { selected } = this.state;
		// eslint-disable-next-line
		if (selected && selected.id == feature.properties.id)
			return {...style, ...selectedStyle};
		return style;
	}

	render() {
		const { layers, query, onQueryChange, onQuerySubmit, queryName, center, height, width, ...otherProps } = this.props;
		return (
			<LeafletMap
				zoom={14}
				maxZoom={20}
				{...otherProps}
				style={{width: width || undefined, height: height || undefined}}
				center={center}
				onzoomend={this.handleMapChange}
				onmoveend={this.handleMapChange}
				ref={this.mapRef}
			>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
				/>
				{ Object.keys(layers).filter(key => (layers[key].checked && layers[key].geoJSON)).map(key => (
					<GeoJSON
						ref={this.layerRef[key]}
						key={hash(layers[key].geoJSON) + key}
						data={layers[key].geoJSON}
						style={this.getStyle}
						onEachFeature={this.onEachFeature.bind(null, this)}
					/>
				))}
				{ this.props.onLayerChange &&
					<LayersButton layers={this.props.layers} onLayerChange={this.props.onLayerChange}/>
				}
				{this.props.children}
			</LeafletMap>
		);
	}
}

Map.propTypes = {
	center: PropTypes.array.isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	layers: PropTypes.object.isRequired,
	onLayerChange: PropTypes.func.isRequired,
	onFeatureSelect: PropTypes.func.isRequired,
	selectedFeature: PropTypes.object,
};

export default Map;
