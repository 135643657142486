import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

/**
 * Static routes
 */

// Admin
const SettingsMessages = CoreStaticRoutes.Settings + '/messages';
const PdfTemplates = CoreStaticRoutes.Settings + '/pdf_templates';
const RegisterFields = CoreStaticRoutes.Settings + '/register_fields';
const Edit = '/admin/edit';
const EditForms = '/admin/edit/forms';
const EditWorkflows = '/admin/edit/draw';

export const StaticRoutes = {
	SettingsMessages,
	PdfTemplates,
	RegisterFields,
	Edit,
	EditForms,
	EditWorkflows
};

/**
 * Dynamic routes
 */
const EditFormsDynamic = EditForms + '/(form)?/:form?';
const EditWorkflowsDynamic = EditWorkflows + '/(workflow)?/:workflow?';
const PdfTemplateDynamic = PdfTemplates + '/(workflow)?/:workflow?';

export const DynamicRoutes = {
	EditWorkflows: EditWorkflowsDynamic,
	EditForms: EditFormsDynamic,
	PdfTemplates: PdfTemplateDynamic,
};

export const routes = [
	{
		url: SettingsMessages,
		name: 'messages',
		component: 'SettingsMessages',
		role: roles.ADMIN,
	},
	{
		url: PdfTemplateDynamic,
		name: 'pdf templates',
		description: 'Admin Pdf Template settings',
		contextComponent: 'SelectWorkflow',
		component: 'PdfTemplates',
		role: roles.ADMIN,
		exact: true,
	},
	{
		url: RegisterFields,
		name: 'register fields',
		component: 'RegisterFields',
		role: roles.ADMIN,
	},
	{
		url: Edit,
		name: 'draw',
		description: 'Admin Edit Menu',
		title: 'links.edit',
		defaultTitle: 'Admin Edit Menu',
		to: EditForms,
		exact: true,
	},
	{
		url: EditFormsDynamic,
		name: 'forms',
		description: 'Admin Edit Forms',
		contextComponent: 'EditForms',
		component: 'EditForms',
		role: roles.ADMIN,
		exact: true
	},
	{
		url: EditWorkflowsDynamic,
		name: 'flows',
		description: 'Admin Draw Workflows',
		contextComponent: 'EditWorkflows',
		component: 'EditWorkflows',
		role: roles.ADMIN,
		exact: true,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
