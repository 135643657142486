import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Card, CardHeader, CardBody, Table,
	Form, FormGroup, Input, Button, Label
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { updateData } from 'core/ducks/update';
import { Loading } from 'core/components';
import T from 'modules/i18n';

class SettingsMessages extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: undefined
		};
		this.initialData = this.state.data;

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	handleInputChange(event, status, role) {
		let target = event.target;
		let { data } = this.state;
		this.setState({
			data: {
				...data,
				[status]: {
					...data[status],
					[role]: {
						...data[status][role],
						[target.name]: target.type==="checkbox" ? target.checked : target.value
					}
				}
			}
		});
	}

	handleFormSubmit(event) {
		event.preventDefault();
		this.props.dispatch( updateData('admin/notifications', this.state.data) );
	}

	componentDidMount() {
		this.props.dispatch( requestData('notifications', 'admin/notifications') );
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.list.notifications &&
			((prevProps.list.notifications.pending && !this.props.list.notifications.pending) ||
			 (prevProps.list.notifications.refreshing && !this.props.list.notifications.refreshing))
		) {
			let data = Object.values(this.props.list.notifications.data)
				.filter(row => row.scope !== 'progressing')
				.reduce((obj, row) => {
					const { scope, role, ...other} = row;
					other.message = other.message || '';
					other.receipt = other.receipt || '';
					return {
						...obj,
						[scope]: obj[scope] ? {...obj[scope], [role]: other} : {[role]: other}
					};
				}, {});
			this.initialData = Object.assign({}, data);
			this.setState({data});
		}
	}

	render() {
		const { data } = this.state;
		if (!data)
			return <Loading />

		return (
			<Form onSubmit={this.handleFormSubmit}>
				<Row>
					<Col xs="0" lg="1" xl="2"/>
					<Col lg="10" xl="8">
						<Card>
							<CardBody>
								<Table>
									<tbody>
										{ Object.keys(data).map((status, index) => {
											let current = data[status];
											return (
												<React.Fragment key={`row_${status}`}>
													{ index===0 &&
														<tr>
															<td/>
															{ Object.keys(current).map(role => (
																<th key={`title_${role}`} className="text-center"><T>{role}</T></th>
															))}
														</tr>
													}
													<tr>
														<th><T>{status}</T></th>
														{ Object.keys(current).map(role => (
															<td key={`cell_${role}`} className="text-center">
																<FormGroup>
																	<Input
																		type="checkbox"
																		name="active"
																		value={current[role].active}
																		checked={current[role].active}
																		onChange={(e) => {this.handleInputChange(e, status, role)}}
																	/>
																</FormGroup>
															</td>
														))}
													</tr>
												</React.Fragment>
											);
										})}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
					<Col xs="0" lg="1" xl="2"/>
				</Row>

				{ Object.keys(data).map((status) => {
					let current = data[status];
					return (
						<Row key={`card_${status}`}>
							<Col>
								<Card>
									<CardHeader><T>{status}</T></CardHeader>
									<CardBody>
										{ Object.keys(current).map((role) => (
											<FormGroup
												key={`form_group_${role}`}
												tag="fieldset"
												className="w-100 border px-1 py-1 mr-0 mb-2"
											>
												<legend className="w-auto d-inline-block text-inherit text-muted mb-0">
													<T>{role}</T>
												</legend>
												<FormGroup>
													<Label className="w-100">
														<T>message</T>
														<Input
															type="text"
															maxLength={1023}
															name="message"
															value={current[role].message}
															onChange={(e) => this.handleInputChange(e, status, role)}
															disabled={!current[role].active}
														/>
													</Label>
												</FormGroup>
												{ role==='author' &&
													<FormGroup>
														<Label className="w-100">
															<T>receipt</T>
															<Input
																type="text"
																maxLength={1023}
																name="receipt"
																value={current[role].receipt}
																onChange={(e) => this.handleInputChange(e, status, role)}
																disabled={!current[role].active}
															/>
														</Label>
													</FormGroup>
												}
											</FormGroup>
										))}
									</CardBody>
								</Card>
							</Col>
						</Row>
					);
				})}

				<Row>
					<Col className="text-right m-0 p-0">
						<Button type="submit" color="primary" className="mx-5 my-3"><T>submit</T></Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

SettingsMessages = connect(mapStateToProps)(SettingsMessages);

export default SettingsMessages;
