import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardText,
	Button,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import { ErrorPages } from 'core/model/routes';
import { Loading } from 'core/components';
import { postData, getData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { DynamicRoutes } from '../../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T, { getLocale } from 'modules/i18n';
import * as roles from 'core/model/roles';

class CreateWorkflow extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			values: {name: ''},
			recaptchaValue: '',
			pending: true,
			workflow: {}
		};

		this.apply = this.apply.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
	}

	apply(event) {
		const { recaptchaValue } = this.state;
		const { dispatch } = this.props;
		const mname = this.props.match.params.mname || this.props.mname;
		event.preventDefault();
		if (this.state.modalOpen)
			this.toggle();
		const data = {'workflow': mname, 'name': this.state.values.name, 'workflow_revision': 1, recaptchaValue};
		dispatch( postData('application', data, false) );
	}

	handleRecaptchaChange(recaptchaValue) {
		this.setState({recaptchaValue})
	}

	toggle() {
		this.setState( {modalOpen: !this.state.modalOpen} );
	}

	componentDidMount() {
		const { dispatch } = this.props;
		const mname = this.props.match.params.mname || this.props.mname;
		dispatch(getData(`workflow/mname/${mname}/revision/1`)).then(() => {
			this.setState({
				pending: false,
			});
		}, () => {
			this.props.history.push(ErrorPages.Forbidden);
		});
		const lang = dispatch(getLocale());
		window.recaptchaOptions = {
			lang
		}
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevState.pending && !this.state.pending) {
			const { update } = this.props;
			if (typeof update.response.mname == 'undefined')
				this.props.history.push(ErrorPages.NotFound);
			this.setState({
				workflow: update.response
			});
		}

		if ( prevProps.update.sending && !this.props.update.sending ) {
			if (this.props.update.status === 200) {
				this.props.dispatch( pushNotification({body: 'application created', type: 'success'}) );
				if (JSON.stringify(prevProps.update.message) !== JSON.stringify(this.props.update.message)) {
					const { uuid, node } = this.props.update.message;
					let path = buildPath(DynamicRoutes.Apply, [uuid, node]);
					this.props.history.push(path);
				}
			} else {
				this.props.dispatch( pushNotification({body: 'action denied', type: 'warning'}) );
			}
		}
	}

	render() {
		const { role } = this.props;
		const { workflow, pending } = this.state;
		if (pending)
			return (<Loading />);

		return (
			<Row>
				<Col xs="12">
					<Card>
						<CardHeader>
							<h4>{workflow.name}</h4>
						</CardHeader>
						<CardBody>
							<Row>
								<Col>
									<CardText>
										{workflow.description}
									</CardText>
								</Col>
							</Row>
							<Row>
								<Col>
									{ role === roles.GUEST &&
										<ReCAPTCHA
											sitekey="6LdM_agUAAAAAOe8caFJ-gG8d1NLpPAcruyg2dJY"
											onChange={this.handleRecaptchaChange}
										/>
									}
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<Button type="button" color="primary" onClick={this.apply} disabled={role===roles.GUEST && this.state.recaptchaValue===''}>
										<T>continue</T>
									</Button>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>

			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	update: state.update,
	role: state.profile.user.role,
});

CreateWorkflow = connect(mapStateToProps)(CreateWorkflow);

export default CreateWorkflow;
