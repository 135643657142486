/**
 * Static routes
 */
const Messages = '/messages';

export const StaticRoutes = {
	Messages,
};

export const routes = [
	{
		url: Messages,
		name: 'messages',
		description: 'Messages',
		title: 'links.messages',
		defaultTitle: 'Messages',
		component: 'Messages',
	},
];

const combined = {StaticRoutes, routes};

export default combined;
