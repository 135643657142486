import * as roles from 'core/model/roles';

/**
 * Static routes
 */
const AdminWorkflowsList = '/admin/workflows';
const WorkflowsList = '/workflows/list';
const WorkflowDashboard = '/workflows/register';
const MyWorkflows = '/myworkflows';
const Inactive = '/inactive';

export const StaticRoutes = {
	AdminWorkflowsList,
	WorkflowsList,
	WorkflowDashboard,
	MyWorkflows,
	Inactive,
};

/**
 * Dynamic routes
 */
const Workflows = '/workflows/register/:mname';
const Apply = '/apply/:uuid/step/:node';
const WorkflowsListStatus = AdminWorkflowsList + '/(status)?/:status?'

export const DynamicRoutes = {
	Workflows,
	Apply,
	WorkflowsListStatus,
};

export const routes = [
	{
		url: WorkflowDashboard,
		name: 'workflows dashboard',
		to: WorkflowsListStatus,
		role: roles.REVIEWER,
	},
	{
		url: WorkflowDashboard,
		name: 'workflows dashboard',
		description: 'A page to select a workflow type to initiate',
		component: 'WorkflowDashboard',
		role: roles.AUTHORIZED,
		exact: true,
	},
	{
		url: Workflows,
		name: 'workflows',
		component: 'CreateWorkflow',
		role: roles.AUTHORIZED,
	},
	{
		url: Apply,
		name: 'apply',
		description: 'Main application procedure',
		contextComponent: 'Apply',
		component: 'Apply',
		role: roles.AUTHORIZED,
	},
	{
		url: WorkflowsList,
		name: 'workflows',
		description: 'List of Workflows',
		component: 'WorkflowsList',
		role: roles.AUTHORIZED,
		exact: true,
	},
	{
		url: WorkflowsListStatus,
		name: 'workflows',
		component: 'WorkflowsList',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: MyWorkflows,
		name: 'workflows',
		to: WorkflowsListStatus,
		role: roles.REVIEWER,
	},
	{
		url: MyWorkflows,
		name: 'my workflows',
		component: 'WorkflowsList',
		role: roles.AUTHORIZED,
		props: {
			personal: true
		},
	},
	// {
	// 	url: Inactive,
	// 	name: 'inactive workflows',
	// 	component: 'WorkflowsList',
	// 	role: roles.REVIEWER,
	// 	props: {
	// 		inactive: true
	// 	},
	// }
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
