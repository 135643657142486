import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { postData, deleteData } from 'core/ducks/update';
import { Loading, Error } from 'core/components';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';
import {
	Filter,
	FilterGroup,
	Table,
	Title,
	Button,
	Tbody,
	Thead,
	Toolbox,
	Tr,
	Search,
	Pagination
} from 'table';
import { ExportToCSV } from 'core/model/lib';
import T from 'modules/i18n';
import { StaticRoutes } from '../../model/routes';
import Timeseries from '../../components/timeseries';
import EditItem from './editItem';

class LogisticsDashboard extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			query: '',
			sort: 'category',
			sort_method: 'asc',
			category: '',
			type: '',
		};
		this.state = {
			...this.initialState,
			refreshing: false,
			page: 1,
			isModalOpen: '',
			orderValues: {id: '', quantity: ''},
			orderQuantity: 0,
			editData: {},
			currentUnit: null,
		};

		this.layout = {
			category: {sortable: true},
			type: {sortable: true},
			name: {sortable: true},
			description: {sortable: true, type: 'text'},
			unit: {sortable: true},
			quantity: {sortable: true},
			last_action: {type: 'date', sortable: true},
		};
		this.fields = Object.keys(this.layout);
		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.handleToolboxReset = this.handleToolboxReset.bind(this);
		this.requestData = this.requestData.bind(this);
		this.handleSortChange = this.handleSortChange.bind(this);
		this.handleCSVExport = this.handleCSVExport.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.handleOrderChange = this.handleOrderChange.bind(this);
		this.submitOrder = this.submitOrder.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(requestData('categories', 'logistics/list/category'));
		if (this.props.match && this.props.match.params.category) {
			this.props.dispatch(requestData('types', `logistics/list/type/category/${this.props.match.params.category}`));
		} else {
			this.props.dispatch(requestData('types', 'logistics/list/type'));
		}
		this.props.dispatch(requestData('units', 'logistics/list/unit'));
		if (this.props.match) {
			let state = {};
			if (this.props.match.params.category)
				state = {category: this.props.match.params.category};
			if (this.props.match.params.type)
				state = {...state, type: this.props.match.params.type};
			this.setState({...state}, this.requestData);
		} else {
			this.requestData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { params } = this.props.match;
		if (
			params.category !== prevProps.match.params.category ||
			params.type !== prevProps.match.params.type ||
			prevState.query !== this.state.query
		) {
			let state = {};
			if (prevState.query !== this.state.query)
				state = Object.assign(state, {query: this.state.query})
			if (params.category !== prevProps.match.params.category) {
				const url = params.category ? `logistics/list/type/category/${this.props.match.params.category}` : 'logistics/list/type';
				this.props.dispatch(requestData('types', url));
				state = Object.assign(state, {category: params.category || '', type: ''});
			}
			if (params.type !== prevProps.match.params.type)
				state = Object.assign(state, {type: params.type || ''});
			this.setState({
				...state,
				page: 1
			}, this.requestData);
		}
	}

	handleToolboxReset() {
		this.setState({
			...this.initialState,
			page: 1
		}, this.requestData);
		this.props.history.push(StaticRoutes.LogisticsDashboard);
	}

	requestData() {
		const url = this.createUrl();
		this.setState({refreshing: true}, () => {
			this.props.dispatch(requestData('logistics', url)).then(() => {
				this.setState({refreshing: false});
			});
		});
	}

	createUrl() {
		let { query, sort, sort_method, category, type } = this.state;
		let url = `logistics/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : query;
		let fq = '';
		fq += (category && category !== '') ? 'category:' + encodeURI(category) + ';' : '';
		fq += (type && type !== '') ? 'type:' + encodeURI(type) : '';
		fq = (fq !== '') ? '/fq/' + fq : fq;
		url += query + fq;

		return url;
	}

	handleSortChange(sort) {
		if (sort===this.state.sort) {
			this.setState({ sort_method: this.state.sort_method==='asc' ? 'desc' : 'asc'}, this.requestData);
		} else {
			this.setState({ sort }, this.requestData);
		}
	}

	handleCSVExport() {
		const { messages } = this.props.i18n || {messages: {}};
		const url = this.createUrl();
		this.props.dispatch(requestData('csv', `${url}/limit/10000`)).then(() => {
			let fields = this.fields.map((field) => {
				return messages[field] ? messages[field] : field;
			});
			let csv = new ExportToCSV('logistics.csv', fields, this.props.list.csv.data.values);
			csv.createLink();
		});
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleFilterChange(event) {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 1
		}, () => {
			let { type, category } = this.state;
			let url = StaticRoutes.LogisticsDashboard;
			category = category ? encodeURI(category) : undefined;
			type = type ? encodeURI(type) : undefined;
			url += category ? `/category/${category}` : '';
			if (name === 'category')
				type = undefined;
			url += type ? `/type/${type}` : '';
			this.props.history.push(url);
		});
	}

	toggleModal(scope) {
		this.setState({
			isModalOpen: this.state.isModalOpen === scope ? '' : scope,
			orderValues: {id: '', quantity: ''},
			orderQuantity: 0,
			editData: {},
			currentUnit: null,
		});
	}

	handleOrderChange(event) {
		this.setState({orderQuantity: event.target.value});
	}

	submitOrder(event) {
		event.preventDefault();
		const quantity = parseInt(this.state.orderQuantity);
		if (quantity === 0) {
			this.toggleModal('order');
			return;
		}
		this.props.dispatch( postData(`stock/item/${this.state.orderValues.id}`, {quantity}) ).then(() => {
			this.requestData();
			this.toggleModal('order');
		});
	}

	render() {

		if (!this.props.list.logistics)
			return (<Loading />);

		const { pending, status, errorMsg, data } = this.props.list.logistics;
		if (pending) {
			return (<Loading />);
		} else if (status !== 200 && status !== '') {
			return <Error status={status} errorMsg={errorMsg} />;
		}
		const { values, ...info } = data;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Row>
				<Col xs="12" lg="12">
					<Table>
						<Title>
							<T>logistics</T> - <T>dashboard</T>
							<Button type="toolbox" title="filters" className="float-right"/>
						</Title>
						<Toolbox onReset={this.handleToolboxReset}>
							<Col xs="12" lg="4" className="form-group text-right">
								<Search placeholder={`${messages.search || 'search'}...`} onChange={this.handleFilterChange} name="query" />
								<Button type="csv" title={`${messages.export || 'export'} csv`} onClick={this.handleCSVExport}>
									<T>export</T> csv
								</Button>
								<Button type="resetFilters" title={messages['reset filters'] || 'reset filters'}><T>reset</T></Button>
							</Col>
							<Col xs="12" lg="8">
								<FilterGroup>
									<Filter onChange={this.handleFilterChange} name="category" defaultValue={this.state.category} >
										<option value="">{`${messages.choose || 'choose'} ${messages.category || 'category'}`}</option>
										{ (this.props.list.categories && !this.props.list.categories.pending) ?
											this.props.list.categories.data.map((category, catIndex) => <option key={`cat_option_${catIndex}`} value={category}>{category}</option>)
											:
											null
										}
									</Filter>
									<Filter onChange={this.handleFilterChange} name="type" defaultValue={this.state.type} disabled={this.state.category === ''} >
										<option value="">{`${messages.choose || 'choose'} ${messages.type || 'type'}`}</option>
										{ (this.props.list.types && !this.props.list.types.pending) ?
											this.props.list.types.data.map((type, typeIndex) => <option key={`type_option_${typeIndex}`} value={type}>{type}</option>)
											:
											null
										}
									</Filter>
								</FilterGroup>
							</Col>
						</Toolbox>
						<Thead>
							<Tr data={['', ...this.fields]} sortBy={this.state.sort} sortMethod={this.state.sort_method} onClick={this.handleSortChange} layout={this.layout} />
						</Thead>
						<Tbody refreshing={this.state.refreshing}>
							<Tr data={values} layout={this.layout}>
								<Button
									type="edit"
									onClick={
										(data) => {
											this.setState({isModalOpen: 'edit', editData: data});
										}
									}
									title={messages.edit || 'edit'}
								/>
								<Button
									type="drop"
									onClick={
										(index) => this.actions.toggleModal(true,
											<Alert
												toggle={this.actions.toggleModal}
												title="drop confirm"
												message="do you wish to continue"
												onConfirm={() => this.actions.deleteData(`logistics/uuid/${index}`).then(() => {this.requestData()})}
											/>
										)
									}
									title={messages.drop || 'drop'}
								/>
								<Button
									className="fa fa-cart-plus"
									title={messages.order || 'order'}
									onClick={
										(data) => {
											this.setState({isModalOpen: 'order', orderValues: {id: data.index, quantity: data.quantity}});
										}
									}
								/>
								<Button
									className="fa fa-line-chart"
									title={messages.chart || 'chart'}
									onClick={
										(data) => {
											this.props.dispatch( requestData('timeseries', `stock/item/${data.index}`) ).then(() => {
												this.setState({isModalOpen: 'chart', currentUnit: data.unit});
											});
										}
									}
								/>
							</Tr>
						</Tbody>
						<Pagination
							className="mx-auto"
							page={info.page}
							total={info.total_pages}
							onClick={(page) => {
								if (page !== info.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
				</Col>
				<Modal isOpen={this.state.isModalOpen==='order'} toggle={() => {this.toggleModal('order')}}>
					<ModalHeader toggle={() => {this.toggleModal('order')}}><i className="fa fa-cart-plus" /> <T>order</T></ModalHeader>
					<ModalBody>
						<Form onSubmit={this.submitOrder}>
							<Row form>
								<Col>
									<FormGroup>
										<Label>
											Ποσότητα παραγγελίας
											<Input type="number" min="0" value={this.state.orderQuantity} onChange={this.handleOrderChange} />
										</Label>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>{this.state.orderQuantity !== 0 ? 'Νέο ' : ''}Υπόλοιπο: <span style={{fontSize: 'x-large'}} className="warning"> {parseInt(this.state.orderValues.quantity) + parseInt(this.state.orderQuantity)}</span></p>
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<button type="submit"><T>submit</T></button>
								</Col>
							</Row>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.isModalOpen==='chart'} toggle={() => {this.toggleModal('chart')}} size="lg" >
					<ModalBody>
						{ this.props.list.timeseries &&
							<Timeseries data={this.props.list.timeseries.data} unit={this.state.currentUnit} />
						}
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.isModalOpen==='edit'} toggle={() => {this.toggleModal('edit')}} size="lg" >
					<ModalHeader toggle={() => {this.toggleModal('edit')}}><i className="fa fa-pencil-square-o"/> <T>edit</T></ModalHeader>
					<ModalBody>
						<EditItem
							data={this.state.editData}
							categories={this.props.list.categories.data}
							types={this.props.list.types.data}
							units={this.props.list.units.data}
							refresh={() => {
								this.toggleModal('edit');
								this.requestData();
							}}
						/>
					</ModalBody>
				</Modal>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	i18n: state.i18n,
});

LogisticsDashboard = connect(mapStateToProps)(LogisticsDashboard);

export default LogisticsDashboard;
