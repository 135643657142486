import React, { Component } from 'react';

import Search from './components/search';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';

class Menu extends Component {
	render() {
		return (
			<SecureContent role={roles.REVIEWER}>
				<Search/>
			</SecureContent>
		);
	}
}

export default Menu;
