import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Table } from 'reactstrap';
import { FormattedDate } from 'react-intl';
import hash from 'object-hash';

import { Map as LeafletMap, Popup, TileLayer, GeoJSON, WMSTileLayer, ImageOverlay } from 'react-leaflet';
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import proj4 from 'proj4';
import sat from '../../img/sat.png';

import { Api } from 'core/api';
import { DynamicRoutes } from 'flows-app/model/routes';
import { applicationStatusStyle } from 'flows-app/model/constants';
import { buildPath } from 'core/model/lib/urlTools';
import { requestData } from 'core/ducks/list';
import { getData, clearState } from 'core/ducks/update';
import T from 'modules/i18n';
import Marker from '../../img/marker';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '../../scss/style.scss';

const createIcon = ({color, opacity, rotated}) => {
	const icon = L.divIcon({
		className: 'custom-icon',
		html: ReactDOMServer.renderToString(<Marker color={color} opacity={opacity} rotated={rotated} />),
		iconAnchor: [rotated ? 4.5 : 8.5,36],
	});

	return icon;
};

class WorkflowsMap extends Component {

	constructor(props) {
		super(props);
		proj4.defs('EPSG:2100', "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");

		this.state = {
			boundingBox: [],
			points: {type: 'FeatureCollection', features: []},
			selected: '',
			pending: false,
			proc: '',
			polygon: {},
			geotif: '',
			showAerial: false,
			topoBbox: [],
			newLocationsFetched: false,
			previousSelectedTarget: null,
			selectedTarget: null
		};

		this.mapRef = React.createRef();
		this.mainGeojson = React.createRef();

		this.handleMapChange = this.handleMapChange.bind(this);
		this.createUrl = this.createUrl.bind(this);
		this.requestData = this.requestData.bind(this);
	}

	handleMapChange(event) {
		const bounds = event.target.getBounds();
		let northEast = bounds._northEast;
		northEast = proj4('EPSG:4326', 'EPSG:2100', [northEast.lng, northEast.lat]);
		let southWest = bounds._southWest;
		southWest = proj4('EPSG:4326', 'EPSG:2100', [southWest.lng, southWest.lat]);
		this.setState({
			boundingBox: [...southWest, ...northEast, 2100]
		}, this.requestData);
	}

	pointToLayer = (feature, latlng) => {
		const color = applicationStatusStyle[feature.properties.status].color;
		const icon = createIcon({color, opacity: 0.7, rotated: false});

		return L.marker(latlng, {icon});
	}


	onEachFeature = (feature, layer) => {
		layer.on({
			click: this.handleLayerClick,
		});
	}

	handleLayerClick = (e) => {
		const { id, status } = e.target.feature.properties;
		let icon, color;
		if (this.state.selected && e.target.feature.properties.id === this.state.selected) {
			color = applicationStatusStyle[this.state.selectedTarget.feature.properties.status].color;
			icon = createIcon({color, opacity: 0.7, rotated: false});
			this.state.selectedTarget.setIcon(icon);
			this.clearDetails();
			return;
		}
		if (this.state.selectedTarget) {
			color = applicationStatusStyle[this.state.selectedTarget.feature.properties.status].color;
			icon = createIcon({color, opacity: 0.7, rotated: false});
			this.state.selectedTarget.setIcon(icon);
		}
		this.setState({
			previousSelectedTarget: this.state.selectedTarget,
			selectedTarget: e.target,
			selected: id
		});
		icon = createIcon({color: 'red', opacity: 1, rotated: true});
		e.target.setIcon(icon);

		const url = `application/uuid/${id}`;
		this.props.dispatch( getData(url) );
	}

	createUrl() {
		const boundingBox = this.state.boundingBox.join(',');
		let url = `locations/geom/${boundingBox}`;
		let { query, category, status } = this.props.queries;
		query = (query && query!=='') ? '/query/' + query : query;
		let fq = [];
		if (status) {
			const statusEnseble = Object.keys(status).filter(key => status[key]);
			if (statusEnseble.length === 0)
				return false;
			statusEnseble.forEach(key => {
				fq.push(`status:${key}`);
			});
		}
		if (category && category!=='')
			fq.push('workflow:' + category);
		fq = fq.join(';');
		fq = (fq !== '') ? '/fq/' + fq : fq;
		url += query + fq;

		return url;
	}

	requestData() {
		const { dispatch } = this.props;
		if (this.state.pending)
			clearTimeout(this.state.proc);
		const url = this.createUrl();
		if (!url) {
			this.setState({
				points: {type: 'FeatureCollection', features: []}
			});
			return;
		}
		let proc = setTimeout(() => {
			dispatch( requestData('locations', url) ).then(() => {
				this.setState({newLocationsFetched: true});
			});
			this.setState({pending: false, proc: ''});
		}, 100);
		this.setState({
			pending: true,
			proc
		});
	}

	showTopo(uuid) {
		let a = new Api(`locations/feature/bbox/uuid/${uuid}`);
		a.Get().then((response) => {
			return response.json();
		}).then((json) => {
			this.setState({topoBbox: json});
		});
	}

	clearDetails() {
		this.setState({
			previousSelectedTarget: null,
			selectedTarget: null,
			selected: ''
		});
		this.props.dispatch( clearState() );
	}

	componentDidMount() {
		this.handleMapChange({target: this.mapRef.current.leafletElement});
	}

	componentDidUpdate(prevProps, prevState) {
		const { query, status, category } = this.props.queries
		if (query !== prevProps.queries.query || JSON.stringify(status) !== JSON.stringify(prevProps.queries.status) || category !== prevProps.queries.category) {
			this.clearDetails();
			this.requestData();
		}

		if (!prevState.newLocationsFetched && this.state.newLocationsFetched)
			this.setState({
				points: this.props.list.locations.data,
				newLocationsFetched: false,
			});
	}

	render() {
		const { points } = this.state;
		let { info, data_pending, mapSettings } = this.props;

		return (
			<Row className="workflows-map">
				<Col>
					<Card className="m-0">
						<CardBody style={{padding: 5 + 'px'}}>
							<LeafletMap
								center={mapSettings.center}
								zoom={mapSettings.zoom}
								maxZoom={mapSettings.maxZoom}
								minZoom={mapSettings.minZoom}
								onzoomend={this.handleMapChange}
								onmoveend={this.handleMapChange}
								ref={this.mapRef}
							>
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
								/>
								{ this.state.showAerial &&
									<WMSTileLayer
										layers="KTBASEMAP"
										url="http://gis.ktimanet.gr/wms/wmsopen/wmsserver.aspx?"
										opacity={0.4}
									/>
								}
										<GeoJSON
											ref={this.mainGeojson}
											key={hash(points)}
											data={points}
											onEachFeature={this.onEachFeature.bind(this)}
											pointToLayer={this.pointToLayer}
										/>
								{ Object.keys(this.state.polygon).length !== 0 &&
									<GeoJSON
										key={this.state.selected}
										data={this.state.polygon}
									/>
								}
								{ (this.state.topoBbox.length === 4) &&
									<ImageOverlay
										url={`/api/locations/feature/geotif/uuid/${this.state.selected}.png`}
										bounds={this.state.topoBbox}
										opacity={0.8}
										zIndex={100000}
									/>
								}
								<Control position="topright">
									<button
										style={{
											backgroundColor: 'rgba(0,0,0,0)',
											borderStyle: this.state.showAerial ? 'inset' : 'outset'
										}}
										onClick={() => {this.setState({showAerial: !this.state.showAerial})}}
									>
										<img src={sat} alt="Aerial" />
									</button>
								</Control>
							</LeafletMap>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}

}

const mapStateToProps = (state) => ({
	list: state.list,
	info: state.update.response,
	data_pending: state.update.pending,
	queries: state.ui.menu.asideState,
	mapSettings: state.ui.settings.values.map,
});

WorkflowsMap = connect(mapStateToProps)(WorkflowsMap);

export default WorkflowsMap;
