import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import { login, getRole } from '../ducks/profile';
import { Loading } from '../components';
import { requestSettings } from '../ducks/ui/settings';
// import Home from './home';

const Home = Loadable({
	loader: () => import('./home'),
	loading: Loading
});

const Page404 = Loadable({
	loader: () => import('./pages/page404'),
	loading: Loading
});

class Content extends Component {

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch( login() );
		dispatch( requestSettings() );
	}

	render() {

		const { pending, settings_pending } = this.props;

		if (pending || settings_pending)
			return <Loading/>;

		return (
			<Switch>
				<Route path="/" name="home" component={Home}/>
				<Route component={Page404} />
			</Switch>
		);
	}
}

const mapStateToProps = (state) => ({
	loggedin: state.profile.loggedin,
	pending: state.profile.login_pending,
	settings_pending: state.ui.settings.pending,
	role: getRole(state),
	time_out: state.profile.time_out
});

Content = connect(mapStateToProps)(Content);

export default withRouter(Content);
