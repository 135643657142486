import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Row, Col, Form, FormGroup, Input, Label, Button,
	InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';

import { getContent, setContent, submit } from 'core/ducks/forms';
import T from 'modules/i18n';
import { characterConverter as converter } from 'core/model/lib';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';

class EditWorkflowTab extends Component {

	constructor(props) {
		super(props);
		this.actions = bindActionCreators({toggleModal}, props.dispatch);
		this.state = {
			ready: false,
			mnameEdited: false,
		};

		this.initialValues = {
			name: '',
			mname: '',
			description: '',
			type: '',
			hidden: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleInputChange(event) {
		const target = event.target;

		let value = target.value;
		if (target.name === 'mname') {
			value = value.toLowerCase();
			value = value.split(' ').join('_');
			value = value.split('-').join('_');
			if (!/^([a-zA-Z0-9_]*)$/.test(value))
				return;
			this.setState({mnameEdited: value!==''});
		}
		if (!this.state.mnameEdited && target.name === 'name') {
			this.props.dispatch(setContent('workflow', {
				name: value,
				mname: converter(value),
			}));
		} else {
			this.props.dispatch(setContent('workflow', {
				[target.name]: target.type==='checkbox' ? target.checked : target.value,
			}));
		}
	}

	handleFormSubmit(event) {
		event.preventDefault();
		let url = 'admin/workflows';
		let method = 'Post';
		if (this.props.workflow) {
			url += `/mname/${this.props.workflow}`;
			method = 'Put';
		}
		this.setState({mnameEdited: true});
		this.props.dispatch( submit(url, 'workflow', method) );
	}

	handleDelete() {
		const { workflow } = this.props;
		let url = `admin/workflows/mname/${workflow}`;
		console.log(url);
		// if (params.workflow)
		// 	this.props.dispatch( deleteData(`admin/workflows/mname/${params.workflow}`) );
	}

	componentDidMount() {
		if (this.props.forms.workflow)
			this.setState({
				ready: true,
			});
		this.setContent();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.forms.workflow && this.props.forms.workflow)
			this.setState({
				ready: true,
			});
		if (prevProps.workflow !== this.props.workflow)
			this.setContent();
		if (prevProps.method.node && !this.props.method.node)
			this.setState({mnameEdited: true});
		if (!prevProps.method.node && this.props.method.node)
			this.setState({mnameEdited: false});
	}

	setContent() {
		if (this.props.workflow !== '') {
			this.props.dispatch( getContent(`admin/workflows/mname/${this.props.workflow}`, 'workflow') );
		} else {
			this.props.dispatch( setContent('workflow', this.initialValues) );
		}
	}

	render() {

		if (!this.state.ready)
			return null;

		const { workflow } = this.props.forms;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<React.Fragment>
				<Form onSubmit={this.handleFormSubmit}>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>name</T>
							<Input
								type="text"
								value={workflow.name}
								name="name"
								onChange={this.handleInputChange}
							/>
						</Label>
					</FormGroup>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>identifier</T>
							<Input
								type="text"
								value={workflow.mname}
								name="mname"
								onChange={this.handleInputChange}
							/>
						</Label>
					</FormGroup>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>description</T>
							<Input
								type="textarea"
								value={workflow.description}
								name="description"
								onChange={this.handleInputChange}
							/>
						</Label>
					</FormGroup>
					<FormGroup className="mb-1">
						<Label className="w-100">
							<T>type</T>
							<Input
								type="select"
								value={workflow.type}
								name="type"
								onChange={this.handleInputChange}
							>
								<option value="">{messages.select_workflow_type || 'select_workflow_type'}</option>
								<option value="public">{messages.public || 'public'}</option>
								<option value="private">{messages.private || 'private'}</option>
							</Input>
						</Label>
					</FormGroup>
					<FormGroup className="mb-1">
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<Label htmlFor="workflow_hidden" className="m-0"><T>hidden</T></Label>
								</InputGroupText>
							</InputGroupAddon>
							<InputGroupAddon addonType="append">
								<InputGroupText>
									<input
										id="workflow_hidden"
										type="checkbox"
										value={workflow.hidden}
										checked={workflow.hidden}
										name="hidden"
										onChange={this.handleInputChange}
									/>
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
					<Row>
						<Col className="text-center">
							<Button
								color="danger"
								type="button"
								className={this.props.workflow === '' ? 'd-none' : 'mr-2'}
								title={messages.delete || 'delete'}
								onClick={
									() => this.actions.toggleModal(true,
										<Alert
											toggle={this.actions.toggleModal}
											title="drop confirm"
											message="do you wish to continue"
											onConfirm={this.handleDelete}
										/>
									)
								}
							>
								<i className="fa fa-trash" />
							</Button>
							<Button color="primary" type="submit"><T>{this.props.method.workflow ? 'add' : 'save'}</T></Button>
						</Col>
					</Row>
				</Form>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	forms: state.forms.content,
	method: state.forms.post,
});

EditWorkflowTab = connect(mapStateToProps)(EditWorkflowTab);

export default EditWorkflowTab;
