import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { TagCloud } from 'react-tagcloud';
import { withRouter, Link } from 'react-router-dom';

import { Dashboard as Template, SecureContent } from 'core/components';
import { requestData } from 'core/ducks/list';
import * as roles from 'core/model/roles';
import T from 'modules/i18n';
import { buildPath } from 'core/model/lib/urlTools';
import { DynamicRoutes } from '../model/routes';

const tagRenderer = (tag, size, color) => (
		<Link
			to={buildPath(DynamicRoutes.WorkflowsListStatusTag, [tag.value])}
			key={tag.value}
			style={{color, fontSize: size + 'px', display: 'inline-block', textDecoration: 'none'}}
			className="p-1"
			title={`${tag.value}: ${tag.count}`}
		>
			{tag.value}
		</Link>
);

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: []
		};
	}

	componentDidMount() {
		this.props.dispatch( requestData('tagsCloud', 'tags/stats/') );
	}

	componentDidUpdate(prevProps) {
		if (prevProps.list.tagsCloud && ((prevProps.list.tagsCloud.pending && !this.props.list.tagsCloud.pending) || (prevProps.list.tagsCloud.refreshing && !this.props.list.tagsCloud.refreshing)))
			this.setState({tags: this.props.list.tagsCloud.data});
	}

	render () {

		return (
			<Template>
				<SecureContent role={roles.REVIEWER}>
					<Card>
						<CardHeader className="text-primary">
							<i className="fa fa-tags" /> { this.state.tags.length } <T>tags</T>
						</CardHeader>
						<CardBody className="bg-white">
							<TagCloud
								minSize={11}
								maxSize={30}
								tags={this.state.tags}
								renderer={tagRenderer}
							/>
						</CardBody>
					</Card>
				</SecureContent>
			</Template>
		);

	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default withRouter(Dashboard);
