import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import layersImg from '../img/layers.png';

class LayersButton extends Component {

	handleCheckChange = (event) => {
		const { value, checked } = event.target;
		this.props.onLayerChange({
			[value]: {
				...this.props.layers[value],
				checked,
			}
		});
	}

	render() {
		const { layers } = this.props;

		return (
			<Row className="px-3">
				{Object.keys(layers).map(key => (
						<Col key={`layer_${layers[key].name}`} sm="6" className="m-0 py-0">
							<label className="input-container mb-3">
								{` ${layers[key].name}`}
								<input
									type="checkbox"
									checked={layers[key].checked}
									onChange={this.handleCheckChange}
									value={key}
									name={layers[key].name}
								/>
								<span className="checkmark" style={layers[key].checked ? {backgroundColor: layers[key].style.color} : {}}/>
							</label>
						</Col>
				))}
			</Row>
		);
	}
}

LayersButton.propTypes = {
	layers: PropTypes.object.isRequired,
	onLayerChange: PropTypes.func.isRequired,
};

export default LayersButton;
