import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FormattedDate, FormattedTime } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { getMessages, removeMessages, markRead, countNotifications } from '../../ducks/messages';
import { Loading } from 'core/components';
import T from 'modules/i18n'
import {
	Table,
	Title,
	Tbody,
	Pagination
} from 'table';
import { DynamicRoutes } from 'flows-app/model/routes';
import { buildPath } from 'core/model/lib/urlTools';

class Messages extends Component {

	constructor(props) {
		super(props);
		this.timeoutID = '';
		this.state = {
			refreshing: false,
		};

		this.handlePageChange = this.handlePageChange.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(countNotifications()).then(() => dispatch( getMessages() ));
	}

	componentWillUnmount() {
		clearTimeout(this.timeoutID);
		this.props.dispatch( removeMessages() );
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pending && !this.props.pending) {
			const data = this.props.messages.values.filter(message => !message.read).map(message => message.id);
			this.timeoutID = setTimeout(() => {
				this.props.dispatch( markRead(data) );
			}, 10000);
			if (this.state.refreshing)
				this.setState({refreshing: false})
		}
	}

	handlePageChange(page) {
		this.setState({
			refreshing: true
		}, () => {
			this.props.dispatch( getMessages(page) )
		});
	}

	render() {
		const { messages } = this.props;

		if (this.props.pending && !this.state.refreshing)
			return <Loading />;

		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" lg="12">
						<Table>
							<Title>
								<T>messages</T>
							</Title>
							<Tbody refreshing={this.state.refreshing}>
								{ messages.values.map((message, index) => {
									let today = new Date();
									let plus = message.created.indexOf('+');
									let date = plus !== -1 ? message.created.substring(0, plus) : message.created;
									date = new Date(date);
									return (
										<tr key={`row_${index}`}>
											<td style={{width: 80 + '%', fontWeight: message.read ? 400 : 800}} >
												<NavLink to={buildPath(DynamicRoutes.Apply, [message.application, 'current'])} onClick={() => {this.props.dispatch(markRead([message.id]))}}>
													{message.body}
												</NavLink>
											</td>
											<td>
												{ date.toDateString() === today.toDateString() ?
													<FormattedTime
														value={date}
													/>
													:
													<FormattedDate
														value={date}
														year='numeric'
														month='short'
														day='2-digit'
													/>
												}
											</td>
										</tr>
									);
								})}
							</Tbody>
							<Pagination
								className="mx-auto"
								page={messages.page}
								total={messages.total_pages}
								onClick={(page) => {
									if (page !== messages.page)
										this.handlePageChange(page);
								}}
							/>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	messages: state.messages.list,
	http_status: state.messages.status,
	pending: state.messages.pending_list,
});

Messages = connect(mapStateToProps)(Messages);

export default Messages;
