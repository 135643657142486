import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardBody, Row, Col, Badge } from 'reactstrap';
import { FormattedDate } from 'react-intl';

class Job extends Component {

	handleViewClick = () => {
		if (this.props.view && typeof this.props.view == 'function' && this.props.id)
			this.props.view(this.props.id);
	}

	render() {
		const { name, id, comment, identifier, created, lastAction, view, tags, address, ...other } = this.props;
		return (
			<Card style={{border: '1px solid darkgreen', backgroundColor: 'rgba(55,70,55,0.1)'}} {...other}>
				<CardBody>
					{ tags &&
						<Row className="mb-2">
							<Col className="pt-0">
								{ tags.map((tag, tag_index) => (
									<Badge key={`${id}_tag_${tag_index}`} className="mx-1 p-2">{tag}</Badge>
								))}
							</Col>
						</Row>
					}
					<CardTitle className="mb-1">
						<Row>
							<Col className="py-0 font-weight-bold">
								{name || ''}
							</Col>
							<Col className="py-0 text-right">
								<span className="small">{identifier}</span>
								<i className="fa fa-eye d-inline-block" role="button" onClick={this.handleViewClick} />
							</Col>
						</Row>
					</CardTitle>
					{ address &&
						<Row>
							<Col className="py-0">
								<p className="text-justify mx-2 my-0">{address || ''}</p>
							</Col>
						</Row>
					}
					{ comment &&
						<Row>
							<Col className="py-0">
								<p className="text-justify mx-2 my-0 text-muted">{comment || ''}</p>
							</Col>
						</Row>
					}
					<Row className="mt-3">
						<Col className="py-0">
							<i className="fa fa-plus d-inline-block" />{' '}
							<FormattedDate value={created} />
						</Col>
						<Col className="py-0">
							<i className="fa fa-pencil d-inline-block" />{' '}
							<FormattedDate value={lastAction} />
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

Job.propTypes = {
	name: PropTypes.string,
	comment: PropTypes.string,
	id: PropTypes.string.isRequired,
	identifier: PropTypes.string,
	created: PropTypes.string.isRequired,
	lastAction: PropTypes.string,
	view: PropTypes.func,
	tags: PropTypes.array,
	address: PropTypes.string,
}

export default Job;