import React, { Component } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { format } from 'date-fns';
import { scaleTime } from 'd3-scale';
import PropTypes from 'prop-types';

import { SliderRail, Handle, Track, Tick } from './components';
import './scss/style.scss';

const sliderStyle = {
	position: "relative",
	width: "100%"
};

function formatTick(ms) {
	return format(new Date(ms), "dd/MM/yy");
}

class DatetimeSlider extends Component {

	constructor(props) {
		super(props);

		const minValue = props.minValue || props.min;
		const maxValue = props.maxValue || props.max;

		this.state = {
			selected: {
				start: minValue,
				end: maxValue,
			},
			updated: {
				start: minValue,
				end: maxValue,
			},
			minValue,
			maxValue,
			min: props.min,
			max: props.max,
			step: props.step,
		};
	}

	onChange = ([start, end]) => {
		this.setState({
			selected: {
				start: new Date(start),
				end: new Date(end),
			}
		});
	};

	onUpdate = ([start, end]) => {
		this.setState({
			updated: {
				start: new Date(start),
				end: new Date(end),
			}
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selected !== this.state.selected) {
			const dateFormat = this.props.dateFormat || 'dd/MM/yyyy hh:mm';
			this.props.onChange([
				format(new Date(this.state.selected.start), dateFormat),
				format(new Date(this.state.selected.end), dateFormat)
			]);
		}
	}

	render() {
		const { min, max, step, minValue, maxValue } = this.state;

		const dateTicks = scaleTime()
			.domain([min, max])
			.ticks(8)
			.map(d => +d);

		return (
				<div className="datetime-slider animated fadeIn">
					<Slider
						mode={1}
						step={step}
						domain={[+min, +max]}
						rootStyle={sliderStyle}
						onUpdate={this.onUpdate}
						onChange={this.onChange}
						values={[minValue, maxValue]}
					>
						<Rail>
							{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
						</Rail>
						<Handles>
							{({ handles, getHandleProps }) => (
								<div>
									{handles.map(handle => (
										<Handle
											key={handle.id}
											handle={handle}
											domain={[+min, +max]}
											getHandleProps={getHandleProps}
										/>
									))}
								</div>
							)}
						</Handles>
						<Tracks left={false} right={false}>
							{({ tracks, getTrackProps }) => (
								<div>
									{tracks.map(({ id, source, target }) => (
										<Track
											key={id}
											source={source}
											target={target}
											getTrackProps={getTrackProps}
										/>
									))}
								</div>
							)}
						</Tracks>
						<Ticks values={dateTicks}>
							{({ ticks }) => (
								<div>
									{ticks.map(tick => (
										<Tick
											key={tick.id}
											tick={tick}
											count={ticks.length}
											format={formatTick}
										/>
									))}
								</div>
							)}
						</Ticks>
					</Slider>
				</div>
		);
	}
}

DatetimeSlider.propTypes = {
	step: PropTypes.number.isRequired,
	min: PropTypes.instanceOf(Date).isRequired,
	max: PropTypes.instanceOf(Date).isRequired,
	minValue: PropTypes.instanceOf(Date),
	maxValue: PropTypes.instanceOf(Date),
	onChange: PropTypes.func.isRequired,
	dateFormat: PropTypes.string,

};

export default DatetimeSlider;
