import constants from 'modules/constants';

export const applicationStatusStyle = {
	'progressing': {color: 'darkgoldenrod'},
	'with_editor': {color: 'blueviolet'},
	'under_review': {color: 'blue'},
	'completed': {color: 'green'}
}

export const applicationStatus = Object.keys(applicationStatusStyle);

// export const applicationStatus = [
// 	'progressing',
// 	'with_editor',
// 	'under_review',
// 	'author_actions',
// 	'under_editor_evaluation',
// 	'revise',
// 	'accepted',
// 	'rejected',
// 	'completed',
// 	'on_hold',
// 	'suspended'
// ];

export const applicationTypes = [
	'boolean',
	'categorical',
	'form',
	'workflow',
	'transition',
	'assignation',
	'register',
	'email'
];

export const applicationContentTypes = {
	'O': 'Operational',
	'I': 'Intermediate',
	'P': 'Product'
};

export const applicationRoles = [
	'author',
	'reviewer',
	'editor'
];

export const fieldTypes = {
	string: {
		name: 'text',
	},
	text: {
		name: 'textarea',
	},
	attachment: {
		name: 'file',
	},
	number: {
		name: 'number',
	},
	date: {
		name: 'date',
	},
	select: {
		name: 'select',
	},
	radio: {
		name: 'radio',
	},
	checkbox: {
		name: 'checkbox',
	},
	map: {
		name: 'map',
	},
	plaintext: {
		name: 'plaintext',
	},
	parse_pdf: {
		name: 'parse_pdf',
	},
	address: {
		name: 'address',
	},
	...constants.fieldTypes,
};
