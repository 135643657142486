import config from '../../../package.json';

const getLocale = () => (dispatch, getState) => {
	let locale = '';
	if (!config.multilingual) {
		if (config.locales) {
			locale = config.locales[0];
		}
	} else {
		locale = getState().i18n.locale;
	}

	return locale;
};

export default getLocale;
