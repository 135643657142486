import React, { Component } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';

import { Toolbox } from '../components';

class ViewFeed extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showToolbox: false
		};
	}

	render() {
		const className = this.props.className ? `border-bottom ${this.props.className}` : 'border-bottom';
		const messages = this.props.messages || {};
		return (
			<Row className={className} onMouseOver={() => this.setState({showToolbox: true})} onMouseOut={() => this.setState({showToolbox: false})} >
				<Toolbox
					className={this.state.showToolbox ? '' : 'd-none'}
					onFocus={this.props.onFocus}
					onEdit={this.props.onEdit}
					onDrop={this.props.onDrop}
				/>
				<Col>
					<h5 className="mb-0">
						{this.props.title}{' '}
					</h5>
					<span className="text-muted pl-4">
						<Badge className="py-1 mr-2" color="warning">
							{messages.from || 'from'}
						</Badge>
							<span className="mr-3">{format(parseISO(this.props.validFrom), 'dd/MM/yyyy HH:mm')}</span>
						<Badge className="py-1 mr-2" color="success">
							{messages.to || 'to'}
						</Badge>
							<span>{format(parseISO(this.props.validTo), 'dd/MM/yyyy HH:mm')}</span>
					</span>
					<div className="m-2" dangerouslySetInnerHTML={{__html: this.props.body}} />
				</Col>
			</Row>
		);
	}
}

ViewFeed.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	validFrom: PropTypes.string.isRequired,
	validTo: PropTypes.string.isRequired,
	onFocus: PropTypes.func,
	onEdit: PropTypes.func,
	onDrop: PropTypes.func,
	messages: PropTypes.object
};

export default ViewFeed;
