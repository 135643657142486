import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from 'react-calendar';
import { Row, Col, Button } from 'reactstrap';
import { DragDropContext } from 'react-beautiful-dnd';
import { FormattedDate } from 'react-intl';

import { requestData } from 'core/ducks/list';
import { postData } from 'core/ducks/update';
import { Loading } from 'core/components';
import T from 'modules/i18n';

import { User, List } from '../../components';
import '../../scss/style.scss';

const today = new Date();

class Grouping extends Component {

	constructor(props) {
		super(props);
		this.state = {
			date: today,
			users: [],
			groups: []
		};
		this.fetchData = this.fetchData.bind(this);
		this.handleCalendarChange = this.handleCalendarChange.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( requestData('members', 'custommembers') );
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const { groups, list } = this.props;
		const users = list.members.data;
		const usersPending = list.members.pending;
		const usersStatus = list.members.status;
		if (prevProps.usersPending && !usersPending) {
			if (usersStatus === 200)
				this.setState({ users: this.prepareUsersList(users)});
		}
		if (prevProps.groups && ((prevProps.groups.pending && !groups.pending) || (prevProps.groups.refreshing && !groups.refreshing)) ) {
			if (groups.status === 200) {
				this.setState({ groups: groups.data}, () => {
					this.setState({ users: this.prepareUsersList(users) });
				});
			}
		}
	}

	fetchData() {
		this.props.dispatch( requestData('groups', `groups/date/${this.getDate(this.state.date, 'sql')}`) );
	}

	handleCalendarChange(date) {
		this.setState({date});
		this.props.dispatch( requestData('groups', `groups/date/${this.getDate(date, 'sql')}`) );
	}

	prepareUsersList(items) {
		let uuids = {};
		this.state.groups.forEach(entry => {
			entry.members.forEach(member => {
				uuids[member.id] = true;
			})
		});
		let objects = Object.keys(items)
			.filter(id => !uuids[id])
			.map(id => {
				let item = items[id];
				item.id = id;
				return item;
			});
		return objects;
	}

	getGroup(droppableId) {
		let group;
		if (droppableId === 'droppable_area_users') {
			group = 'users';
		} else {
			group = droppableId.split('_');
			group = group[group.length - 1];
		}
		return group;
	}

	reorder(group, startIndex, endIndex) {
		const result = Array.from(group);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	}

	move(sourceGroup, targetGroup, startIndex, endIndex) {
		const source = Array.from(sourceGroup);
		const target = Array.from(targetGroup);

		const [removed] = source.splice(startIndex, 1);
		target.splice(endIndex, 0, removed);
		return [source, target];
	}

	onDragEnd(result) {
		const { source, destination } = result;

		if (!destination)
			return;

		if (source.droppableId === destination.droppableId) {
			const group = this.getGroup(destination.droppableId);
			let reordered;
			if (group === 'users') {
				reordered = this.reorder(this.state.users, source.index, destination.index);
				this.setState({users: reordered});
			} else {
				reordered = this.reorder(this.state.groups[group].members, source.index, destination.index);
				this.setState({
					groups: Object.assign(
						[...this.state.groups],
						{[group]: {
							...this.state.groups[group],
							members: reordered
						}}
					)
				});
			}
		} else {
			const sourceGroup = this.getGroup(source.droppableId);
			const targetGroup = this.getGroup(destination.droppableId);
			let sourceReordered, targetReordered;
			if (sourceGroup === 'users') {
				[sourceReordered, targetReordered] = this.move(this.state.users, this.state.groups[targetGroup].members, source.index, destination.index);
				this.setState({
					users: sourceReordered,
					groups: Object.assign(
						[...this.state.groups],
						{[targetGroup]: {
							...this.state.groups[targetGroup],
							members: targetReordered
						}}
					)
				});
			} else if (targetGroup === 'users') {
				[sourceReordered, targetReordered] = this.move(this.state.groups[sourceGroup].members, this.state.users, source.index, destination.index);
				this.setState({
					users: targetReordered,
					groups: Object.assign(
						[...this.state.groups],
						{[sourceGroup]: {
							...this.state.groups[sourceGroup],
							members: sourceReordered
						}}
					)
				});
			} else {
				[sourceReordered, targetReordered] = this.move(this.state.groups[sourceGroup].members, this.state.groups[targetGroup].members, source.index, destination.index);
				this.setState({
					groups: Object.assign(
						[...this.state.groups],
						{
							[sourceGroup]: {
								...this.state.groups[sourceGroup],
								members: sourceReordered
							},
							[targetGroup]: {
								...this.state.groups[targetGroup],
								members: targetReordered
							}
						}
					)
				});
			}
		}
	}

	handleSubmit() {
		const date = this.getDate(this.state.date, 'sql');
		this.props.dispatch( postData(`groups/date/${date}`, this.state.groups) );
	}

	getDate(date, format='int') {
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const formattedDate = format === 'int' ? year * 1000 + month * 100 + day : `${year}-${month + 1}-${day}`;
		return formattedDate;
	}

	render() {
		const { pending, groups } = this.props;
		const { locale, messages } = this.props.i18n || {locale: 'en', messages: {}};

		if (pending || !groups || !groups.data)
			return <Loading />;

		const disabled = this.getDate(this.state.date) < this.getDate(today);

		return (
			<div className="grouping">
				<div className="fixed-date">
					<FormattedDate value={this.state.date} />
				</div>
				<Row>
					<Col className="d-none d-md-block"/>
					<Col>
						<Calendar
							onChange={this.handleCalendarChange}
							value={this.state.date}
							locale={locale}
							className="m-auto"
						/>
					</Col>
					<Col className="text-right mx-5">
						<Button color="success" onClick={this.handleSubmit} disabled={disabled}><T>submit</T></Button>{' '}
						<Button color="secondary" onClick={this.fetchData}><T>reset</T></Button>
					</Col>
				</Row>
				<Row style={{overflowX: 'hidden'}}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Col className="mx-5">
							<List droppableId="droppable_area_users" title={messages.users || 'users'} isDropDisabled={disabled}>
								{this.state.users.map(user =>
									<User key={`user_${user.id}`} id={user.id} username={user.username} firstname={user.firstname} lastname={user.lastname} />
								)}
							</List>
						</Col>
						<Col className="mx-5">
							{this.state.groups.map((group, index) =>
								<List key={`group_${index}`} droppableId={`group_${index}`} title={group.label} isDropDisabled={disabled}>
									{group.members.map(member =>
										<User key={`member_${member.username}`} id={member.username} username={member.username} firstname={member.firstname} lastname={member.lastname} />
									)}
								</List>
							)}
						</Col>
					</DragDropContext>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	list: state.list,
	groups: state.list.groups,
});

Grouping = connect(mapStateToProps)(Grouping);

export default Grouping;