import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Row, Col, Form, Input, Button,
	Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import T from 'modules/i18n';
import { updateData } from 'core/ducks/update';

const fields = ['category', 'type', 'name', 'description', 'unit'];

const Select = (props) => {
	const { name, options, messages } = props;
	return (
		<Input {...props} type="select">
			<option value="">{messages.choose || 'choose'} {messages[name] || name}</option>
			{ options.map((option, index) =>
				<option key={`select_${name}_${index}`} value={option}>{option}</option>
			)}
		</Input>
	);
};

class EditItem extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeTab: 'category',
			data: props.data,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleTab = this.toggleTab.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const { index, ...dataToUpdate } = this.state.data;
		this.props.dispatch( updateData(`logistics/uuid/${index}`, dataToUpdate) ).then(() => {
			this.props.refresh();
		});
	}

	handleInputChange(event) {
		const { name, value } = event.target;
		this.setState({data: {...this.state.data, [name]: value}});
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	handleUpdate(scope) {
		this.props.dispatch( updateData(`logistics/${scope}/${this.props.data[scope]}`, {[scope]: this.state.data[scope]}) ).then(() => {
			this.props.refresh();
		});
	}

	render() {

		const { messages } = this.props.i18n || {messages: {}};
		const { data } = this.state;

		return (
			<Form onSubmit={this.handleSubmit}>
				<Nav tabs>
					{ fields.map(field => (
						<NavItem key={`nav_item_${field}`}>
							<NavLink
								className={this.state.activeTab===field ? 'active text-info p-2' : 'border border-secondary p-2'}
								onClick={() => { this.toggleTab(field); }}
							>
								{messages[field] || field}
							</NavLink>
						</NavItem>
					))}
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					{ fields.map(field => (
						<TabPane key={`tab_pane_${field}`} tabId={field}>
							{ (field !== 'name' && field !== 'description') ?
								<>
									<Row>
										<Col>
											<Select
												value={data[field]}
												name={field}
												options={field==='category' ? this.props.categories : this.props[field + 's']}
												messages={messages}
												onChange={this.handleInputChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col lg="9">
											<Input
												value={data[field]}
												name={field}
												onChange={this.handleInputChange}
											/>
										</Col>
										<Col lg="3" className="text-right">
											<Button disabled={this.props.categories.indexOf(data.category) !== -1} onClick={() => this.handleUpdate(field)}>
												<T>{`rename ${field}`}</T>
											</Button>
										</Col>
									</Row>
								</>
							:
								<>
									<Row>
										<Col>
											<Input
												type={field==='name' ? 'text' : 'textarea'}
												name={field}
												value={data[field]}
												onChange={this.handleInputChange}
											/>
										</Col>
									</Row>
								</>
							}
						</TabPane>
					))}
				</TabContent>
				<Row>
					<Col className="text-right">
						<Button type="submit" color="primary"><T>submit</T></Button>
					</Col>
				</Row>
			</Form>
		);
	}

}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

EditItem.propTypes = {
	data: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
	types: PropTypes.array.isRequired,
	units: PropTypes.array.isRequired,
	refresh: PropTypes.func.isRequired,
};

EditItem = connect(mapStateToProps)(EditItem);

export default EditItem;
