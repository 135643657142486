import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Form, FormGroup, FormText, FormFeedback, Table,
	Input, Button
} from 'reactstrap';

import { getData, deleteData, postData } from 'core/ducks/update';
import { Loading } from 'core/components';
import T from 'modules/i18n';

class RegisterFields extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {},
			id: -1,
			invalid: [],
			underSubmit: false
		};
		this.invalid = [];

		this.handleChange = this.handleChange.bind(this);
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch( getData('admin/register_fields') );
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.pending && !this.props.pending) {
			let values = this.props.values.length === 0
				? {[-1]: {key: '', label: '', type: 'secondary'}}
				: this.props.values;
			this.setState({
				values,
			})
		}

		if (!prevState.underSubmit && this.state.underSubmit) {
			this.setState({underSubmit: false});
			if (this.state.invalid.length === 0)
				this.props.dispatch( postData('admin/register_fields', this.state.values) ).then(() => {
					this.props.dispatch( getData('admin/register_fields') );
				});
		}
	}

	handleChange(event, id) {
		this.setState({
			values: {...this.state.values, [id]: {...this.state.values[id], [event.target.name]: event.target.value}}
		});
	}

	handleTypeChange(event, id) {
		const { name } = event.target;
		let values = Object.keys(this.state.values)
			.reduce((obj, key) => ({
				...obj,
				[key]: key===id
					? {...this.state.values[key], type: name}
					: {...this.state.values[key], type: this.state.values[key].type === name ? 'secondary' : this.state.values[key].type}
			}), {});
		this.setState({values});
	}

	handleAdd() {
		this.setState({
			id: this.state.id - 1,
			values: {...this.state.values, [ this.state.id - 1]: {key: '', label: '', type: 'secondary'}}
		});
	}

	handleRemove(id) {
		if (id < 0) {
			this.removeRow(id);
		} else {
			this.props.dispatch( deleteData(`admin/register_fields/id/${id}`) ).then(() => {
				if (this.props.http_status === 200)
					this.removeRow(id);
			});
		}
	}

	removeRow(id) {
		let values = Object.keys(this.state.values)
			.filter(key => key!==id)
			.reduce((obj, key) => ({
				...obj,
				[key]: this.state.values[key]
			}), {});
		this.setState({values});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.validate();
	}

	validate() {
		let invalid = [];
		let keys = [];
		Object.keys(this.state.values).forEach((id) => {
			let key = this.state.values[id].key;
			if (keys.includes(key)) {
				invalid.push(id);
			} else {
				keys.push(key);
			}
		});
		this.setState({
			invalid,
			underSubmit: true
		});
	}

	render() {
		if (this.props.pending)
			return <Loading/>

		const { values } = this.state;
		return (
			<Form onSubmit={this.handleSubmit}>
				<Row>
					<Col>
						<FormText color="muted" className="text-justify" style={{maxWidth: '600px', margin: '0 auto'}}>
							<T>register_fields_help</T>
						</FormText>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<thead>
								<tr>
									<th />
									<th>key</th>
									<th>label</th>
									<th className="text-center"><T>primary</T></th>
									<th className="text-center"><T>label</T></th>
									<th className="text-center"><T>comment</T></th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(values).map((id) =>
									<tr key={`key_${id}`}>
										<td>
											<i
												className="fa fa-eraser"
												role="button"
												title={this.props.i18n.delete}
												onClick={() => this.handleRemove(id)}
											/>
										</td>
										<td>
											<FormGroup>
												<Input
													type="text"
													name="key"
													value={values[id].key}
													data-type="keys"
													onChange={(event) => this.handleChange(event, id)}
													autoComplete="off"
													maxLength={55}
													invalid={this.state.invalid.includes(id)}
												/>
												<FormFeedback className="invalid-tooltip">Duplicate key!</FormFeedback>
											</FormGroup>
										</td>
										<td>
											<FormGroup>
												<Input
													type="text"
													name="label"
													value={values[id].label}
													onChange={(event) => this.handleChange(event, id)}
													autoComplete="off"
													maxLength={55}
												/>
											</FormGroup>
										</td>
										<td className="text-center">
											<input
												type="checkbox"
												checked={values[id].type === 'primary'}
												value={values[id].type === 'primary'}
												name="primary"
												onChange={(event) => this.handleTypeChange(event, id)}
											/>
										</td>
										<td className="text-center">
											<input
												type="checkbox"
												checked={values[id].type === 'label'}
												value={values[id].type === 'label'}
												name="label"
												onChange={(event) => this.handleTypeChange(event, id)}
											/>
										</td>
										<td className="text-center">
											<input
												type="checkbox"
												checked={values[id].type === 'comment'}
												value={values[id].type === 'comment'}
												name="comment"
												onChange={(event) => this.handleTypeChange(event, id)}
											/>
										</td>
									</tr>
								)}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="6" className="text-right">
										<i
											className="fa fa-plus mr-5 border rounded-circle"
											role="button"
											title={this.props.i18n.add}
											onClick={this.handleAdd}
										/>
										<Button><T>submit</T></Button>
									</td>
								</tr>
							</tfoot>
						</Table>
					</Col>
				</Row>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	values: state.update.response,
	i18n: state.i18n.messages,
	pending: state.update.pending,
	http_status: state.update.status,
});

RegisterFields = connect(mapStateToProps)(RegisterFields);

export default RegisterFields;
