import React, { Component } from 'react';
import { connect } from 'react-redux';

class GroupReport extends Component {

	constructor(props) {
		super(props);
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, '0');
		const mm = String(today.getMonth() + 1).padStart(2, '0');
		const yyyy = today.getFullYear();

		const date = `${yyyy}-${mm}-${dd}`;
		this.state = {
			date,
		};
		this.frameRef = React.createRef();
	}

	render() {

		return (
			<div className="grouping">
				<embed title="Report" className="w-100 h-100" src={`https://komotini.getmap.gr/api/createReport/date/${this.state.date}`} type="application/pdf" ref={this.frameRef}/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

GroupReport = connect(mapStateToProps)(GroupReport);

export default GroupReport;
