import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format, parseISO } from 'date-fns';
import { el } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('el', el);

class DateInput extends Component {

	constructor(props) {
		super(props);
		let value;
		if (props.value && props.value !== '') {
			value = parseISO(props.value);
		} else {
			value = new Date();
			this.props.onChange({target: {name: props.name, value: format(value, 'yyyy-MM-dd')}});
		}
		this.state = {
			value,
		}

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (value) => {
		this.setState({value});
		this.props.onChange({target: {name: this.props.name, value: format(value, 'yyyy-MM-dd')}});
	}

	render() {

		const { value, ...otherProps } = this.props;

		return (
			<DatePicker
				{...otherProps}
				selected={this.state.value}
				onChange={this.handleChange}
				allowSameDay={true}
				showTimeSelect={false}
				dateFormat="d MMMM yyyy"
				className="form-control"
			/>
		);
	}
}

DateInput.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default DateInput;
