import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';

import T from 'modules/i18n';

class Alert extends Component {

	render() {

		const { onConfirm, toggle } = this.props;

		return (
			<Modal isOpen={true} className="modal-md">
				<ModalHeader toggle={toggle}><T>{this.props.title}</T></ModalHeader>
				<ModalBody>
					<T>{this.props.message}</T>
				</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => {toggle(); onConfirm();}}>
						<T>yes</T>
					</Button>
					<Button color="success" onClick={toggle}>
						<T>no</T>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

Alert.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired
}

export default Alert;
