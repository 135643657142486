import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Track extends Component {
	render() {
		const { source, target, getTrackProps, disabled } = this.props;

		return (
			<div
				className={disabled ? 'track disabled' : 'track'}
				style={{
					left: `${source.percent}%`,
					width: `${target.percent - source.percent}%`
				}}
				{...getTrackProps()}
			/>
		);
	}
}

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Track.defaultProps = {
	disabled: false
};

export default Track;
