import * as roles from 'core/model/roles';

/**
 * Static routes
 */
const AdminSearch = '/search';

export const StaticRoutes = {
	AdminSearch,
};

export const routes = [
	{
		url: AdminSearch,
		name: 'search',
		description: 'Full text search page',
		title: 'links.search',
		defaultTitle: 'Full text search page',
		component: 'Search',
		role: roles.REVIEWER,
	},
];

const combined = {StaticRoutes, routes};

export default combined;
