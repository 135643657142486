import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';

import T from 'modules/i18n';

const CustomInput = React.forwardRef((props, ref) => {
	const { type, ...otherProps } = props;
	switch (type) {
		case 'textarea':
			return <textarea {...otherProps} ref={ref} />;

		default:
			return <input type={type} {...otherProps} ref={ref} />;
	}
});

const Select = (props) => {
	const { name, options, messages, ...otherProps } = props;
	return (
		<Input {...otherProps} name={name} type="select">
			<option value="">{messages.choose || 'choose'} {messages[name] || name}</option>
			{ options.map((option, index) =>
				<option key={`select_${name}_${index}`} value={option}>{option}</option>
			)}
		</Input>
	);
};

class Selection extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: '',
			isModalOpen: false
		};
		this.inputRef = React.createRef();
		this.modalInputRef = React.createRef();

		this.toggleModal = this.toggleModal.bind(this);
		this.openModal = this.openModal.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	componentDidMount() {
		this.setState({
			value: this.props.value
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value)
			this.setState({value: this.props.value});
	}

	toggleModal() {
		this.inputRef.current.blur();
		this.setState({
			isModalOpen: !this.state.isModalOpen
		});
	}

	openModal() {
		this.inputRef.current.blur();
		this.setState({
			isModalOpen: true
		}, () => {
			this.modalInputRef.current.focus();
		});
	}

	handleInputChange(event) {
		const { value } = event.target;
		this.setState({value});
	}

	handleReset() {
		this.setState({
			value: this.props.value
		});
	}

	render() {
		const { value, className, scope, list, ...other } = this.props;
		const messages = this.props.messages || {messages: {}};

		return (
			<>
				<input
					className={className ? `form-control ${className}` : 'form-control'}
					value={this.state.value}
					{...other}
					onFocus={this.openModal}
					ref={this.inputRef}
				/>
				<Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal} className="modal-lg">
					<ModalHeader toggle={this.toggleModal}><i className="fa fa-shopping-cart"/> <T>{scope}</T></ModalHeader>
					<ModalBody>
						{ (scope !== 'name' && scope !== 'quantity') &&
							<Row>
								<Col>
									<Select
										value={this.state.value}
										options={scope==='category' ? this.props.categories : this.props[scope + 's']}
										messages={messages}
										onChange={this.handleInputChange}
									/>
								</Col>
							</Row>
						}
						<Row>
							<Col>
								<CustomInput
									value={this.state.value}
									className="form-control"
									{...other}
									autoComplete="off"
									type={scope === 'description' ? 'textarea' : (scope === 'quantity' ? 'number' : 'text')}
									onChange={this.handleInputChange}
									ref={this.modalInputRef}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="text-right">
								<Button type="reset" color="warning" onClick={this.handleReset}><T>reset</T></Button>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
			</>
		);
	}
}

Selection.propTypes = {
	value: PropTypes.string.isRequired,
	scope: PropTypes.string.isRequired,
	list: PropTypes.array,
	categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	types: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	units: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	messages: PropTypes.object,
};

export default Selection;
