import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tick extends Component {

	render() {
		const { tick, count, format } = this.props;

		return (
			<div>
				<div
					className="tick"
					style={{
						left: `${tick.percent}%`
					}}
				/>
				<div
					className="tick-label"
					style={{
						marginLeft: `${-(100 / count) / 2}%`,
						width: `${100 / count}%`,
						left: `${tick.percent}%`,
					}}
				>
					{format(tick.value)}
				</div>
			</div>
		);
	}
}

Tick.propTypes = {
	tick: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	count: PropTypes.number.isRequired,
	format: PropTypes.func.isRequired
};

Tick.defaultProps = {
	format: d => d
};

export default Tick;
