import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import PropTypes from 'prop-types';

class SearchControl extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isInputOpen: false,
		};

		this.inputRef = React.createRef();
		this.submitButtonRef = React.createRef();

		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleButtonClick() {
		this.setState({
			isInputOpen: !this.state.isInputOpen
		}, () => {
			if (this.state.isInputOpen)
				this.inputRef.current.focus();
		});
	}

	handleInputChange(event) {
		const { name, value } = event.target;
		this.props.onChange({target: {name, value}});
		if (value === '')
			this.props.onSubmit()
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.onSubmit();
	}

	render() {

		const { isInputOpen } = this.state;

		return (
			<div className={this.props.className || undefined}>
				{ isInputOpen ?
					<form onSubmit={this.handleSubmit}>
						<InputGroup className="animated fadeIn" style={{width: '200px'}}>
							<Input
								type="text"
								className="rounded-left border-right-0 focus-no"
								innerRef={this.inputRef}
								name={this.props.name}
								value={this.props.value}
								onChange={this.handleInputChange}
								placeholder={this.props.placeholder || '...'}
								autoComplete="off"
							/>
							<InputGroupAddon addonType="append">
								{ this.props.value !== '' &&
									<InputGroupText className="bg-white text-secondary border-right-0 px-1">
										<i className="fa fa-times" onClick={() => {
											this.props.resetSearch();
											this.submitButtonRef.current.click();
										}}/>
									</InputGroupText>
								}
								<button
									className="bg-light rounded-right border-left-0 px-2"
									type={this.props.value!=='' ? 'submit' : 'button'}
									onClick={this.props.value==='' ? this.handleButtonClick : undefined}
								>
									<i className="fa fa-search"/>
								</button>
								<button ref={this.submitButtonRef} type="submit" className="d-none"/>
							</InputGroupAddon>
						</InputGroup>
					</form>
					:
					<i className="fa fa-search search-control" role="button" onClick={this.handleButtonClick}/>
				}
			</div>
		);
	}
}

SearchControl.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	resetSearch: PropTypes.func.isRequired
}

export default SearchControl;
