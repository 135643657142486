import React, { Component } from "react";
import ReactDOM from 'react-dom';
import Node from "./lib/Node";
import Spline from "./lib/Spline";
import SVGComponent from "./lib/SVGComponent";

import { computeOutOffsetByIndex, computeInOffsetByIndex } from "./lib/util";

const GraphContext = React.createContext();

export const GraphContextConsumer = GraphContext.Consumer;

export default class index extends Component {

	constructor(props) {
		super(props);

		this.state = {
			data: this.props.data,
			source: [],
			dragging: false,
			moving: false,
			nodeSelected: '',
			splineSelected: null,
		};

		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.handleFrameClick = this.handleFrameClick.bind(this);
		this.selectNode = this.selectNode.bind(this);
		this.selectSpline = this.selectSpline.bind(this);
	}

	componentDidMount() {
		if (!this.props.frameRef)
			return;
		const iframe = ReactDOM.findDOMNode(this.props.frameRef);
		const container = iframe.contentWindow.document;
		container.addEventListener("mousemove", this.onMouseMove);
		container.addEventListener("mouseup", this.onMouseUp);
		container.addEventListener('mousedown', this.handleFrameClick);
	}

	componentWillUnmount() {
		if (!this.props.frameRef)
			return;
		const iframe = ReactDOM.findDOMNode(this.props.frameRef);
		const container = iframe.contentWindow.document;
		container.removeEventListener("mousemove", this.onMouseMove);
		container.removeEventListener("mouseup", this.onMouseUp);
		container.removeEventListener('mousedown', this.handleFrameClick);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			let state = { data: this.props.data };
			if (
				this.props.data.nodes.length - prevProps.data.nodes.length === 1 &&
				Object.is(this.props.data.connections, prevProps.data.connections)
			)
				state = {...state, nodeSelected: this.props.data.nodes[this.props.data.nodes.length-1].nid};
			this.setState(state);
		}
	}

	selectNode(nid) {
		this.setState({nodeSelected: nid});
	}

	selectSpline(target) {
		this.setState({splineSelected: target});
	}

	handleFrameClick(e) {
		if (this.state.splineSelected && e.target !== this.state.splineSelected)
			this.setState({splineSelected: null})
		if (e.target.tagName === 'svg')
			this.setState({nodeSelected: ''});
		if (this.props.onMouseEvent)
			this.props.onMouseEvent(e);
	}

	onMouseUp(e) {
		// this.setState({ dragging: false });
		if (this.props.onMouseEvent)
			this.props.onMouseEvent(e);
	}

	onMouseMove(e) {
		e.stopPropagation();
		e.preventDefault();

		const { svgComponent: { refs: { svg } } } = this.refs;

		//Get svg element position to substract offset top and left
		const svgRect = svg.getBoundingClientRect();

		let x = svgRect.left > 0 ? e.pageX - svgRect.left : e.pageX;
		let y = svgRect.top > 0 ? e.pageY - svgRect.top : e.pageY;
		this.setState({
			mousePos: { x, y }
		});
		if (this.props.onMouseEvent)
			this.props.onMouseEvent(e);
	}

	handleNodeStart(nid) {
		this.setState({moving: true});
		if (this.props.onNodeStartMove)
			this.props.onNodeStartMove(nid);
	}

	handleNodeStop(nid, pos) {
		this.setState({moving: false});
		if (this.props.onNodeMove)
			this.props.onNodeMove(nid, pos);
	}

	handleNodeMove(index, pos) {
		let d = {...this.state.data};

		d.nodes[index].x = pos.x;
		d.nodes[index].y = pos.y;

		this.setState({ data: d });
	}

	handleStartConnector(nid, outputIndex) {
		this.setState({ dragging: true, source: [nid, outputIndex] });
	}

	handleCompleteConnector(nid, inputIndex) {
		if (this.state.dragging) {
			let nodes = this.state.data.nodes;
			let fromNode = this.getNodebyId(nodes, this.state.source[0]);
			let fromPinName = fromNode.fields.out[this.state.source[1]].name;
			let toNode = this.getNodebyId(nodes, nid);
			let toPinName;
			if (toNode) {
				toPinName = toNode.fields.in[inputIndex].name;

				this.props.onNewConnector(fromNode.nid, fromPinName, toNode.nid, toPinName);
			}
		}
		this.setState({ dragging: false });
	}

	handleRemoveConnector(connector) {
		if (this.props.onRemoveConnector) {
			this.props.onRemoveConnector(connector);
		}
	}

	handleNodeSelect(nid) {
		if (this.props.onNodeSelect) {
			this.props.onNodeSelect(nid);
		}
	}

	handleNodeDeselect(nid) {
		if (this.props.onNodeDeselect) {
			this.props.onNodeDeselect(nid);
		}
	}

	handleEdit(nid) {
		if (this.props.onEdit) {
			this.props.onEdit(nid);
		}
	}

	computePinIndexfromLabel(pins, pinLabel) {
		let reval = 0;

		for (let pin of pins) {
			if (pin.name === pinLabel) {
				return reval;
			} else {
				reval++;
			}
		}
	}

	getNodebyId(nodes, nid) {
		let reval = 0;

		for (let node of nodes) {
			if (node.nid === nid) {
				return nodes[reval];
			} else {
				reval++;
			}
		}
	}

	render() {
		if (!this.props.frameRef)
			return null;
		let { data, mousePos, dragging } = this.state;
		let { grid } = this.props;

		let connectors = data.connections;
		let nodes = data.nodes;
		let newConnector = null;

		if (dragging) {
			let sourceNode = this.getNodebyId(nodes, this.state.source[0]);
			let connectorStart = computeOutOffsetByIndex(
				sourceNode.x,
				sourceNode.y,
				this.state.source[1]
			);
			let connectorEnd = { x: this.state.mousePos.x, y: this.state.mousePos.y };

			newConnector = <Spline start={connectorStart} end={connectorEnd} />;
		}

		let splineIndex = 0;

		return (
			<GraphContext.Provider value={{
				nodeSelected: this.state.nodeSelected,
				selectNode: this.selectNode,
				splineSelected: this.state.splineSelected,
				selectSpline: this.selectSpline,
			}}>
				<div className={dragging ? "dragging" : ""}>
					{nodes.map((node, i) => {
						return (
							<Node
								key={node.nid}
								index={i}
								nid={node.nid}
								title={node.label}
								type={node.type}
								content_type={node.content_type}
								content={node.content}
								inputs={node.fields.in}
								outputs={node.fields.out}
								grid={grid}
								pos={{ x: node.x, y: node.y }}
								onNodeStart={nid => this.handleNodeStart(nid)}
								onNodeStop={(nid, pos) => this.handleNodeStop(nid, pos)}
								onNodeMove={(index, pos) => this.handleNodeMove(index, pos)}
								onStartConnector={(nid, outputIndex) => this.handleStartConnector(nid, outputIndex)}
								onCompleteConnector={(nid, inputIndex) =>
									this.handleCompleteConnector(nid, inputIndex)
								}
								onNodeSelect={nid => {
									this.handleNodeSelect(nid);
								}}
								onNodeDeselect={nid => {
									this.handleNodeDeselect(nid);
								}}
							/>
						);
					})}

					{/* render our connectors */}

					<SVGComponent
						ref="svgComponent"
						style={{
							height: (this.props.frameRef.node.contentWindow.document.body.scrollHeight - 8) + 'px',
							width: (this.props.frameRef.node.contentWindow.document.body.scrollWidth - 8) + 'px'
						}}
					>
						{connectors.map(connector => {
							if (connector.to_node === '')
								return null;
							let fromNode = this.getNodebyId(nodes, connector.from_node);
							let toNode = this.getNodebyId(nodes, connector.to_node);
							if (!toNode)
								return null;

							let splinestart = computeOutOffsetByIndex(
								fromNode.x,
								fromNode.y,
								this.computePinIndexfromLabel(fromNode.fields.out, connector.from)
							);
							splinestart.y += 1;
							let splineend = computeInOffsetByIndex(
								toNode.x,
								toNode.y,
								this.computePinIndexfromLabel(toNode.fields.in, connector.to)
							);
							splineend.y += 2.5;

							return (
								<Spline
									start={splinestart}
									end={splineend}
									key={splineIndex++}
									mousePos={mousePos}
									onRemove={() => {
										this.handleRemoveConnector(connector);
									}}
								/>
							);
						})}

						{/* this is our new connector that only appears on dragging */}
						{newConnector}
					</SVGComponent>
				</div>
			</GraphContext.Provider>
		);
	}
}
