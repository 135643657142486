import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../scss/toolbox.scss';


class Toolbox extends Component {

	render() {
		const { onFocus, onEdit, onDrop } = this.props;

		return (
			<div className={`toolbox ${this.props.className} animated fadeIn fadeOut`}>
				{ onFocus && <i className="fa fa-search buttons" role="button" onClick={() => {onFocus()}} /> }
				{ onEdit && <i className="fa fa-edit buttons" role="button" onClick={() => {onEdit()}} /> }
				{ onDrop && <i className="fa fa-trash-o buttons" role="button" onClick={() => {onDrop()}} /> }
			</div>
		);
	}
}

Toolbox.propTypes = {
	onFocus: PropTypes.func,
	onEdit: PropTypes.func,
	onDrop: PropTypes.func,
};

export default Toolbox;
