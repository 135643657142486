import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer, FeatureGroup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import 'leaflet-draw/dist/leaflet.draw.css';
import '../scss/style.scss';

class PolygonInput extends Component {

	constructor(props) {
		super(props);

		this.editControlRef = React.createRef();

		this.state = {
			polygons: {},
		};
	}

	_onEdited = (e) => {
		let changes = {};
		e.layers.eachLayer(layer => {
			changes[layer._leaflet_id] = layer._latlngs;
		});
		this.setState({
			polygons: {...this.state.polygons, ...changes}
		}, this._onChange);
	}

	_onCreated = (e) => {
		let { layer } = e;
		this.setState({
			polygons: {...this.state.polygons, [layer._leaflet_id]: layer._latlngs}
		}, this._onChange);
	}

	_onDeleted = (e) => {

		let ids = [];
		e.layers.eachLayer(layer => {
			ids[layer._leaflet_id] = true;
		});
		let polygons = Object.keys(this.state.polygons)
			.filter(id => !ids[id])
			.reduce((obj, id) => ({
				...obj,
				[id]: this.state.polygons[id]
			}), {});

		this.setState({
			polygons
		}, this._onChange);
	}

	_onChange = () => {

		this.props.onChange({
			target: {
				name: this.props.name,
				value: Object.values(this.state.polygons)
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.disabled && !this.props.disabled) {
			this.setState({
				polygons: {}
			});
			this.removeAllLayers();
		}
	}

	removeAllLayers() {
		const layerContainer = this.editControlRef.current.leafletElement.options.edit.featureGroup;
		const layers = layerContainer._layers;
		Object.keys(layers).forEach(id => {
			const layer = layers[id];
			layerContainer.removeLayer(layer);
		});
	}

	render() {

		return (
			<Row>
				<Col>
					<LeafletMap
						style={{width: 100 + '%', height: this.props.height + 'px'}}
						center={this.props.center}
						zoom={this.props.zoom || 10}
					>
						<FeatureGroup>
							{ !this.props.disabled &&
								<EditControl
									ref={this.editControlRef}
									position="topright"
									draw={this.props.draw || {}}
									onEdited={this._onEdited}
									onCreated={this._onCreated}
									onDeleted={this._onDeleted}
								/>
							}
							{ this.props.polygon ?
								this.props.polygon.map((positions, index) => (
									<Polygon key={`polygon_${index}`} positions={positions} />
								))
								:
								null
							}
						</FeatureGroup>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						/>
					</LeafletMap>
				</Col>
			</Row>
		);
	}
}

PolygonInput.propTypes = {
	center: PropTypes.array.isRequired,
	height: PropTypes.number.isRequired,
	zoom: PropTypes.number,
	draw: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	polygon: PropTypes.array,
	disabled: PropTypes.bool,
};

export default PolygonInput;
