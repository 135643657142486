import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FilePond, File as FileElem, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Lightbox from 'react-image-lightbox';
import { Row, Col, Button } from 'reactstrap';
import 'react-image-lightbox/style.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import '../scss/style.scss';

import en from '../i18n/en';
import el from '../i18n/el';
import { Api } from 'core/api';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class File extends Component {

	constructor(props) {
		super(props);
		this.api = new Api('upload');
		this.state = {
			value: props.value,
			files: {},
			i18n: {en, el},
			isLBOpen: false,
		}

		this.handleLocationResponse = this.handleLocationResponse.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	handleLocationResponse(response) {
		this.setState({
			value: response.replace(/"/g,"")
		});
		this.props.onChange({target: {name: this.props.name, value: this.state.value}});
		return response;
	}

	handleRemove() {
		this.setState({
			value: ''
		});
		this.props.onChange({target: {name: this.props.name, value: ''}});
	}

	componentDidMount() {
		const file = this.state.value;
		if (file !== '') {
			let parts = file.split('/');
			let user = parts[3];
			let application = parts[4];
			let filename = parts[5]
			let a = new Api(`upload/user/${user}/application/${application}/filename/${filename}`);
			a.Get().then((response) => {
				return response.json();
			}).then((json) => {
				this.setState({
					files: {
						...this.state.files,
						[file]: json,
					}
				});
			});
		}
	}

	render() {
		const { value, locale, onChange, readOnly, required, ...other } = this.props;
		const { i18n } = this.state;
		let source;
		if (readOnly && Object.keys(this.state.files).length === 0)
			return (
				<p className="text-center text-muted">{i18n[locale].noFile}</p>
			);

		return (
			<div className="file-upload">
				<fieldset disabled={readOnly}>
					<FilePond
						{...other}
						allowMultiple={false}
						required={required ? required : false}
						onremovefile={this.handleRemove}
						acceptedFileTypes={this.props.filetype || undefined}
						allowFileSizeValidation={this.props.maxFileSize ? true : false}
						maxFileSize={this.props.maxFileSize || undefined}
						server={{
							process: {
								url: this.api.url,
								method: 'POST',
								headers: {
									'X-Csrf-Token': this.api.token,
								},
								credentials: 'same-origin',
								onload: this.handleLocationResponse
							},
							revert: {
								url: this.api.url,
								method: 'DELETE',
								headers: {
									'X-Csrf-Token': this.api.token,
								},
								credentials: 'same-origin'
							},
							restore: {
								url: `${this.api.url}/uuid`,
								method: 'GET',
								headers: {
									'X-Csrf-Token': this.api.token,
								},
								credentials: 'same-origin',
							},
							load: {
								url: `${this.api.url}/uuid/`,
								method: 'GET',
								headers: {
									'X-Csrf-Token': this.api.token,
								},
								credentials: 'same-origin',
							},
							fetch: {
								url: `${this.api.url}/uuid/`,
								method: 'GET',
								headers: {
									'X-Csrf-Token': this.api.token,
								},
								credentials: 'same-origin',
							}
						}}
						{...this.state.i18n[locale]}
					>
						{ Object.keys(this.state.files).map((key) => {
							let file = this.state.files[key];
							source = key;
							return (<FileElem onClick={() => this.setState({isLBOpen: true})} key={key} className="d-none" src={key} origin="local" name={file.name} size={file.size} type={file.type}/>);
						}) }
					</FilePond>
				</fieldset>
				<Row>
					{ this.props.filetype.substring(0,5) === 'image' &&
						<Col className="my-0 py-0">
							<i className="btn btn-secondary" onClick={() => this.setState({isLBOpen: true})}>{i18n[locale]['show']}</i>
						</Col>
					}
					<Col className="text-right my-0 py-0">
						<a className="d-inline-block text-info px-4 small" key={source} href={source} download target="_blank" rel="noopener noreferrer">{i18n[locale]['download file']}</a>
					</Col>
				</Row>
				{ (this.state.isLBOpen) &&
					<Lightbox
						mainSrc={source}
						onCloseRequest={() => this.setState({isLBOpen: false})}
						onMovePrevRequest={() => {}}
						onMoveNextRequest={() => {}}
					/>
				}
			</div>

		);
	}
}

File.propTypes = {
	id: PropTypes.string,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	locale: PropTypes.string,
	filetype: PropTypes.string,
	maxFileSize: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

export default File;
