import React, { Component } from 'react';
import { format, parseISO } from 'date-fns';
import T from 'modules/i18n';
import { PropTypes } from 'prop-types';

class StatusHistory extends Component {

	render() {

		const { data } = this.props;

		return (
			<div className="d-table">
				<div className="d-table-row">
					<div className="d-table-cell text-nowrap font-weight-bold mx-2 px-2 py-1 border-bottom border-right"><T>transition date</T></div>
					<div className="d-table-cell text-nowrap font-weight-bold mx-2 px-2 py-1 border-bottom"><T>status</T></div>
				</div>
				{ data.map((elem, index) =>
					<div key={`popover_row_${index}`} className="d-table-row" >
						<div className="d-table-cell text-nowrap mx-2 p-2 border-right">{format(parseISO(elem.transition), 'dd/MM/YYY HH:mm')}</div>
						<div className="d-table-cell text-nowrap mx-2 p-2"><T>{elem.status}</T></div>
					</div>
				)}
			</div>
		);
	}
}

StatusHistory.propTypes = {
	data: PropTypes.array.isRequired,
};

export default StatusHistory;
