import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'failures',
		children: [
			{
				name: 'table',
				url: StaticRoutes.AdminWorkflowsList,
				icon: 'fa fa-tasks',
				role: roles.REVIEWER
			}
		]
	},
	{
		name: 'register_application',
		url: StaticRoutes.WorkflowDashboard,
		role: [roles.GUEST, roles.AUTHORIZED]
	},
	{
		name: 'tracking',
		url: StaticRoutes.MyWorkflows,
		role: [roles.GUEST, roles.AUTHORIZED],
	},
	// {
	// 	name: 'inactive_workflows',
	// 	url: StaticRoutes.Inactive,
	// 	icon: 'icon-trash',
	// 	role: roles.REVIEWER
	// }
];

export default menu;
