import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
	Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink,
	TabContent, TabPane, Modal, ModalHeader, ModalBody, Fade
} from 'reactstrap';
import { subMonths, addMonths, startOfToday, format } from "date-fns";

import { requestData } from 'core/ducks/list';
import { deleteData } from 'core/ducks/update';
import { buildPath } from 'core/model/lib/urlTools';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { Pagination } from 'table';
import { DatetimeSlider } from 'datetime-slider';
import T from 'modules/i18n';
import { Map, ViewFeed, SearchControl, LayersSelect } from '../../components';
import EditFeed from './editFeed';
import { DynamicRoutes } from '../../model/routes';
import '../../scss/style.scss';

const today = startOfToday();
const minRange = subMonths(today, 11);
const maxRange = addMonths(today, 1);
const minValue = today;
const maxValue = maxRange;
const dateFormat = 'MM-dd-yyyy HH:mm';

class ListFeed extends Component {

	constructor(props) {
		super(props);

		const layerStyle = {
			past: {fillColor: 'green', color: 'green', weight: 0.8, fillOpacity: 0.3},
			current: {fillColor: 'red', color: 'red', weight: 0.8, fillOpacity: 0.3},
			future: {fillColor: 'orange', color: 'orange', weight: 0.8, fillOpacity: 0.3},
			range: {fillColor: 'grey', color: 'grey', weight: 0.8, fillOpacity: 0.3},
		};
		const { messages } = props.i18n || {messages: {}};
		this.initialPage = {
			past: 1,
			current: 1,
			future: 1,
			range: 1,
		};
		this.state = {
			layers: {
				past: {name: messages.past || 'past', checked: false, style: layerStyle.past, geoJSON: undefined},
				current: {name: messages.current || 'current', checked: true, style: layerStyle.current, geoJSON: undefined},
				future: {name: messages.future || 'future', checked: false, style: layerStyle.future, geoJSON: undefined},
				range: {name: messages['custom range'] || 'custom range', checked: false, style: layerStyle.range, geoJSON: undefined}
			},
			attributes: {
				past: {},
				current: {},
				future: {},
				range: {},
			},
			info: {
				past: {},
				current: {},
				future: {},
				range: {},
			},
			page: {
				...this.initialPage,
			},
			range: [format(minValue, dateFormat), format(maxValue, dateFormat)],
			bbox: [],
			activeTab: 'current',
			isEditModalOpen: false,
			isDeleteModalOpen: false,
			feedUnderEdit: null,
			selectedFeed: 0,
			selectedFeature: {},
			query: '',
			refreshing: false,
			isTimeSelectOpen: true,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, props.dispatch);

		this.handleMapChange = this.handleMapChange.bind(this);
		this.handleLayerChange = this.handleLayerChange.bind(this);
		this.toggleTab = this.toggleTab.bind(this);
		this.changePage = this.changePage.bind(this);
		this.handleFeedFocus = this.handleFeedFocus.bind(this);
		this.handleFeedEdit = this.handleFeedEdit.bind(this);
		this.handleFeedDrop = this.handleFeedDrop.bind(this);
		this.toggleEditModal = this.toggleEditModal.bind(this);
		this.handleFeatureSelect = this.handleFeatureSelect.bind(this);
		this.handleSliderChange = this.handleSliderChange.bind(this);
		this.handleQueryChange = this.handleQueryChange.bind(this);
		this.handleQuerySubmit = this.handleQuerySubmit.bind(this);
	}

	toggleTab(activeTab) {
		if (this.state.activeTab !== activeTab)
			this.setState({
				activeTab
			});
	}


	handleMapChange(bbox) {
		this.setState({
			bbox,
		}, this.requestLayers);
	}

	createUrl(layer, findpage=null) {
		const bbox = this.state.bbox.join(',');
		const page = !findpage ? `page/${this.state.page[layer]}` : `findpage/${findpage}`;
		let url = `feed/bbox/${bbox}/layers/${layer}/${page}/sort/valid_start/limit/5`;
		if (layer === 'range')
			url += `/start/${this.state.range[0]}/end/${this.state.range[1]}`;
		if (this.state.query !== '')
			url += `/query/${this.state.query}`;
		return url;
	}

	requestLayers(specificLayer=null) {
		const activeLayers = !specificLayer ? Object.keys(this.state.layers).filter(layer => this.state.layers[layer].checked) : [specificLayer];
		activeLayers.forEach(layer => {
			this.props.dispatch( requestData('layers', this.createUrl(layer)) ).then(() => {
				let attributes, info;
				if (!this.props.layers.data.attributes || this.props.layers.data.attributes.length === 0) {
					attributes = {};
					info = {};
				} else if (this.props.layers.data.attributes.values) {
					let { values, ...other } = this.props.layers.data.attributes;
					attributes = values ? values.reduce((obj, elem) => ({
						...obj,
						[elem.id]: elem
					}), {})
					: {};
					info = other;
				} else {
					attributes = [this.props.layers.data.attributes].reduce((obj, elem) => ({
						...obj,
						[elem.id]: elem
					}), {});
					info = this.state.info;
				}
				this.setState({
					layers: {
						...this.state.layers,
						[layer]: {
							...this.state.layers[layer],
							geoJSON: this.props.layers.data.geometry,
						}
					},
					attributes: {
						...this.state.attributes,
						[layer]: {
							...attributes,
						}
					},
					info: {
						...this.state.info,
						[layer]: {
							...info,
						}
					},
					refreshing: false,
				});
			});
		});
		const inactiveLayers = Object.keys(this.state.layers).filter(layer => !this.state.layers[layer].checked);
		inactiveLayers.forEach(layer => {
			this.setState({
				layers: {
					...this.state.layers,
					[layer]: {
						...this.state.layers[layer],
						geoJSON: undefined
					}
				},
				attributes: {
					...this.state.attributes,
					[layer]: {}
				}
			});
		});
	}

	handleLayerChange(layer) {
		const key = Object.keys(layer)[0];
		if ( layer[key].checked ) {
			this.toggleTab(key);
		} else if (this.state.activeTab === key) {
			const activeLayers = Object.keys(this.state.layers).filter(layer => (this.state.layers[layer].checked && layer !== key));
			if (activeLayers[0])
				this.toggleTab(activeLayers[0]);
		}
		this.setState({
			layers: {
				...this.state.layers,
				...layer,
			}
		}, this.requestLayers);
	}

	changePage(layer, page) {
		this.setState({
			page: {
				...this.state.page,
				[layer]: page,
			},
			refreshing: true,
		}, () => {this.requestLayers(layer)});
	}

	handleFeedFocus(layer, id) {
		this.setState({
			selectedFeature: {layer, id},
			selectedFeed: id,
		});
	}

	handleFeedEdit(id) {
		this.toggleEditModal(id);
	}

	handleFeedDrop(id) {
		this.actions.toggleModal(true,
			<Alert
				toggle={this.actions.toggleModal}
				title="drop confirm"
				message="do you wish to continue"
				onConfirm={() => this.actions.deleteData(`feed/id/${id}`).then(() => {this.requestLayers()})}
			/>
		);
	}

	toggleEditModal(id=null) {
		this.setState({
			isEditModalOpen: !this.state.isEditModalOpen,
			feedUnderEdit: id,
		});
	}

	handleFeatureSelect(layer, id) {
		this.props.dispatch( requestData('layers', this.createUrl(layer, id)) ).then(() => {
			let attributes, info;
			if (this.props.layers.data.attributes.values) {
				let { values, ...other } = this.props.layers.data.attributes;
				attributes = values.reduce((obj, elem) => ({
					...obj,
					[elem.id]: elem
				}), {});;
				info = other;
				this.setState({
					attributes: {
						...this.state.attributes,
						[layer]: {
							...attributes,
						}
					},
					info: {
						...this.state.info,
						[layer]: {
							...info,
						}
					},
					selectedFeed: id,
					page: {
						...this.state.page,
						[layer]: info.page
					},
				});
			} else {
				this.setState({
					selectedFeed: id,
				});
			}
			this.toggleTab(layer);
		});
	}

	handleSliderChange(range) {
		this.setState({
			range,
		}, () => {
			this.requestLayers('range');
		});
	}

	handleQueryChange(e) {
		const { value } = e.target;
		this.setState({
			query: value,
		});
	}

	handleQuerySubmit() {
		this.setState({
			page: {...this.initialPage}
		}, this.requestLayers);
	}

	render() {

		const { attributes, layers, info, selectedFeed } = this.state;

		const activeLayers = Object.keys(layers).filter(layer => layers[layer].checked);
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<div className="feed-module">
				<Row>
					<Col className="m-0 p-0" xl="7">
						<Card className="p-0 m-0">
							<CardBody className="m-0 p-0">
								<div style={{position: 'relative'}}>
									{ layers.range.checked && (
										<DatetimeSlider
											step={1800000}
											min={minRange}
											max={maxRange}
											minValue={minValue}
											maxValue={maxValue}
											onChange={this.handleSliderChange}
											dateFormat={dateFormat}
										/>
									)}
									<Map
										center={this.props.mapSettings.center}
										zoom={14}
										onChange={this.handleMapChange}
										layers={this.state.layers}
										onFeatureSelect={this.handleFeatureSelect}
										selectedFeature={this.state.selectedFeature}
										className="main-feed-map"
										minZoom={5}
										maxZoom={19}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col xl="5">
						{ this.props.layers && !this.props.layers.pending &&
							<Card>
								<CardBody>
									<SearchControl
										className="floating-search"
										value={this.state.query}
										onChange={this.handleQueryChange}
										onSubmit={this.handleQuerySubmit}
										name="query"
										placeholder={messages.search+'...' || 'Search...'}
										resetSearch={() => this.setState({query: ''})}
									/>
									<div className="px-2 mt-1 pt-1 pb-0 mb-2 border-top border-bottom">
										<h5 className="d-inline-block" ><T>select time</T></h5>{` `}
										<i
											className={this.state.isTimeSelectOpen ? 'fa fa-caret-up' : 'fa fa-caret-down'}
											role="button"
											onClick={() => this.setState({isTimeSelectOpen: !this.state.isTimeSelectOpen})}
										/>
										{ this.state.isTimeSelectOpen &&
											<LayersSelect layers={this.state.layers} className="animated fadeIn fadeOut" onLayerChange={this.handleLayerChange}/>
										}
									</div>
									<Nav tabs >
										{activeLayers.map(layer => (
											<NavItem key={`nav_item_${layer}`}>
												<NavLink onClick={() => {this.toggleTab(layer)}} className={this.state.activeTab===layer ? 'active' : undefined} >
													{layers[layer].name}
												</NavLink>
											</NavItem>
										))}
									</Nav>
									<TabContent activeTab={this.state.activeTab} className={this.state.refreshing ? 'semi-transparent' : undefined}>
										{activeLayers.map(layer => (
											<TabPane key={`tabpane_${layer}`} tabId={layer} className="py-0">
												{Object.keys(attributes[layer]).map(id =>
													<div key={`feed_${id}`} className="position-relative">
														<SecureContent role={roles.REVIEWER}>
															<ViewFeed
																title={attributes[layer][id].title}
																body={attributes[layer][id].body}
																validFrom={attributes[layer][id].valid_start}
																validTo={attributes[layer][id].valid_end}
																onFocus={() => {this.handleFeedFocus(layer, id)}}
																onEdit={() => {this.handleFeedEdit(id)}}
																onDrop={() => {this.handleFeedDrop(id)}}
																className={selectedFeed==id ? 'bg-light' : ''}
																messages={messages}
															/>
														</SecureContent>
														<SecureContent role={[roles.GUEST, roles.AUTHORIZED]}>
															<ViewFeed
																title={attributes[layer][id].title}
																body={attributes[layer][id].body}
																validFrom={attributes[layer][id].valid_start}
																validTo={attributes[layer][id].valid_end}a
																onFocus={() => {this.handleFeedFocus(layer, id)}}
																className={selectedFeed==id ? 'bg-light' : ''}
																messages={messages}
															/>
														</SecureContent>
													</div>
												)}
												<Row>
													<Col>
														<Pagination
															className="mx-auto"
															style={{width: 'fit-content'}}
															page={info[layer].page || 1}
															total={info[layer].total_pages || 1}
															onClick={(page) => {this.changePage(layer, page)}}
														/>
													</Col>
												</Row>
											</TabPane>
										))}
										<SecureContent role={roles.REVIEWER}>
											<Modal isOpen={this.state.isEditModalOpen} className="modal-xl">
												<ModalHeader toggle={this.toggleEditModal}><T>edit</T></ModalHeader>
												<ModalBody>
													<div className="text-right">
														<NavLink tag={Link} to={buildPath(DynamicRoutes.EditFeed, [this.state.feedUnderEdit])} className="text-muted">
															<T>open in main window</T>
														</NavLink>
													</div>
													<EditFeed
														match={{params: {id: this.state.feedUnderEdit}}}
														onSubmit={() => {
															this.toggleEditModal();
															this.requestLayers();
														}}
													/>
												</ModalBody>
											</Modal>
										</SecureContent>
									</TabContent>
								</CardBody>
							</Card>
						}
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	layers: state.list.layers,
	i18n: state.i18n,
	mapSettings: state.ui.settings.values.map,
});

ListFeed = connect(mapStateToProps)(ListFeed);

export default ListFeed;
