import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody } from 'reactstrap';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, start, destination) => {
	const result = Array.from(list);
	const [removed] = result.splice(start, 1);
	result.splice(destination, 0, removed);

	return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: 8 + 'px',
	margin: '4px 0',

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'inherit',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListClassName = isDraggingOver => ({
	className: isDraggingOver ? 'draggable-container:dragging mt-4 row' : 'draggable-container mt-4 row',
});

class Sequence extends Component {

	constructor(props) {
		super(props);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		if (!result.destination)
			return;

		const items = reorder(
			this.props.items,
			result.source.index,
			result.destination.index
		);

		this.props.onDragEnd(items);
	}


	render() {
		const { items } = this.props;

		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							{...getListClassName(snapshot.isDraggingOver)}
						>
							{ items.map((item, index) => (
								<Draggable key={item.mname} draggableId={item.mname} index={index}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
											className="col-12 col-sm-6"
										>
											<Link to="#" className="no-decoration text-dark">
												<Card>
													<CardHeader>
														<h5>{item.name}</h5>
													</CardHeader>
													<CardBody>
														{item.description}
													</CardBody>
												</Card>
											</Link>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}

Sequence.propTypes = {
	items: PropTypes.array.isRequired,
	onDragEnd: PropTypes.func.isRequired,
};

export default Sequence;
