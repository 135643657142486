class api {

	constructor(url) {
		this.url = '/api/' + url;
		this.token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
	}

	_requestSession() {
		let promise = fetch('/api/login', {
			method: "GET",
			credentials: 'same-origin',
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json"
			}
		}).then((response) => {
			response.json();
		});

		return promise;
	}

	Get() {
		let promise = fetch(this.url, {
			method: "GET",
			credentials: 'same-origin',
			headers: {
				"Accept": "application/json",
				"X-Csrf-Token": this.token,
			}
		});
		return promise;
	}

	Delete(id) {
		let promise = fetch(this.url, {
			method: "DELETE",
			credentials: 'same-origin',
			body: JSON.stringify({'id': id}),
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-Csrf-Token": this.token,
			}
		});
		return promise;
	}

	Post(data, type='json') {
		let promise = new Promise((resolve, reject) => {
			if (this.token === '') {
				this._requestSession().then((json) => {
					this.token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
					if (type === 'upload') {
						this._upload(data, 'POST').then((p) => resolve(p));
					} else {
						this._send(data, 'POST').then((p) => resolve(p));
					}
				});
			} else {
				if (type === 'upload') {
					this._upload(data, 'POST').then((p) => resolve(p));
				} else {
					this._send(data, 'POST').then((p) => resolve(p));
				}
			}
		});
		return promise;
	}

	_upload(data, method) {
		let promise = fetch(this.url, {
			method,
			credentials: 'same-origin',
			body: data,
			headers: {
				"Accept": "application/json",
				"X-Csrf-Token": this.token,
			}
		});
		return promise;
	}

	_send(data, method) {
		let promise = fetch(this.url, {
			method,
			credentials: 'same-origin',
			body: JSON.stringify(data),
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
				"X-Csrf-Token": this.token,
			}
		});
		return promise;
	}

	Put(data, type='json') {
		let promise = new Promise((resolve, reject) => {
			if (type === 'upload') {
				this._upload(data, 'PUT').then((p) => resolve(p));
			} else {
				this._send(data, 'PUT').then((p) => resolve(p));
			}
		});
		return promise;
	}

}

export default api;
