import { Api } from 'core/api';


// Actions
const SET_QUERY = 'search/SET_QUERY';
const REFRESHING = 'search/REFRESHING';
const GOT_RESULTS = 'search/FETCHED_RESULTS';
const RESET_SEARCH = 'search/RESET';


// Initial state
const initialState = {
	query: '',
	results: [],
	refreshing: false,
	page: 1,
	total_pages: 0,
	count: -1,
	results_per_page: 0,
	proc: '',
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {
		case SET_QUERY:
			return {
				...state,
				query: action.query,
			};

		case REFRESHING:
			return {
				...state,
				refreshing: action.refreshing,
				proc: action.proc,
			}

		case GOT_RESULTS:
			return {
				...state,
				refreshing: action.refreshing,
				results: action.results,
				count: action.count,
				results_per_page: action.results_per_page,
				total_pages: action.total_pages,
				page: action.page
			}

		case RESET_SEARCH:
			if (state.proc !== '')
				clearTimeout(state.proc);
			return {
				...state,
				...initialState
			}

		default:
			return state;
	}
}


// Action creators
export const setQuery = (query) => ({
	type: SET_QUERY,
	query
});

const refreshing = (proc) => ({
	type: REFRESHING,
	refreshing: true,
	proc,
});

const gotResults = (response, page) => ({
	type: GOT_RESULTS,
	refreshing: false,
	results: response.results,
	count: response.count,
	results_per_page: response.results_per_page,
	total_pages: response.total_pages,
	page,
});

export const resetSearch = () => ({
	type: RESET_SEARCH,
});


// Thunk action creators
export const search = (query, page) => (dispatch, getState) => {
	const state = getState().search;
	if (state.refreshing)
		clearTimeout(state.proc);
	let enc_query = encodeURIComponent(query);
	let uri = encodeURI(`fullsearch/query/${enc_query}/page/${page}`);
	let proc = setTimeout(() => {
		let a = new Api(uri);
		a.Get().then((response) => {
			return response.json();
		}).then((json) => {
			dispatch(gotResults(json, page));
		});
	}, 500);
	dispatch(refreshing(proc));
};
