import React, { Component } from 'react';
import { box } from './styles/pins';

export default class NodeOutputListItem extends Component {

	onMouseDown(e) {
		e.stopPropagation();
  		e.preventDefault();

		this.props.onMouseDown(this.props.index);
	}

	noop(e) {
		e.stopPropagation();
  		e.preventDefault();
	}

	render() {
		return (
			<li onMouseDown={(e) => this.onMouseDown(e)}>
				{/*eslint-disable-next-line*/}
				<a href="#" onClick={(e) => this.noop(e)}>
					{ this.props.item.name === 'out' ? '' : this.props.item.name }&nbsp;
					<span style={box}/>
				</a>
			</li>
		);
	}
}
