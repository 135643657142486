import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Form,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	FormFeedback
} from 'reactstrap';

import { Loading, Error } from 'core/components';
import { default as T, getLocale } from 'modules/i18n';
import { Editor } from 'editor'
import { getContent, setContent, submit } from 'core/ducks/forms';
import { requestData } from 'core/ducks/list';

class PdfTemplates extends Component {

	constructor(props) {
		super(props);

		this.state = { under_submit: false, notFound: false, selectedForm: '' };
		this.submitRef = React.createRef();

		props.dispatch( setContent('pdf', { html: '' }) );

		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleEditorChange(html) {
		this.props.dispatch(setContent('pdf', { ...this.props.content.pdf, html } ));
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, content, match } = this.props;
		if (match.params.workflow) {
			if (content.pdf.workflow) {
				dispatch( submit(`admin/pdftemplate/workflow/${match.params.workflow}`, 'pdf', 'Put') );
			} else {
				dispatch( setContent('pdf', {...content.pdf, workflow: match.params.workflow}) );
				dispatch( submit(`admin/pdftemplate`, 'pdf', 'Post') );
			}
		}
	}

	componentDidMount() {
		const { match } = this.props;
		if (match.params.workflow)
			this.getContent(match.params.workflow);
		if (match.params.workflow && !this.props.list_pending)
			if (!Object.keys(this.props.workflow_list).includes(match.params.workflow))
				this.setState({notFound: true});
	}

	componentDidUpdate(prevProps, prevState) {
		const { dispatch, match } = this.props;
		if ( prevProps.match.params.workflow && !match.params.workflow) {
			dispatch( setContent('pdf', {workflow: '', html: ''}) );
		} else if ( (!prevProps.match.params.workflow && match.params.workflow) || prevProps.match.params.workflow !== match.params.workflow ) {
			this.getContent(match.params.workflow);
		}
		if (match.params.workflow && prevProps.list_pending && !this.props.list_pending) {
			if (!Object.keys(this.props.workflow_list).includes(match.params.workflow))
				this.setState({notFound: true});
		}
	}

	getContent(workflow) {
		const { content, dispatch } = this.props;
		dispatch( requestData('fieldlist', `admin/fieldlist/workflow/${workflow}`));
		dispatch( getContent(`admin/pdftemplate/workflow/${workflow}`, 'pdf') ).then(() => {
			if (content.pdf && Object.keys(content.pdf).length === 0)
				dispatch( setContent('pdf', {html: ''}) );
		});
	}

	render() {
		const { content, pending, list_pending, validation_msgs, match, workflow_list } = this.props;

		const locale = this.props.dispatch(getLocale());

		if (!content.pdf || pending || list_pending)
			return <Loading />

		if (this.state.notFound)
			return <Error status={404} errorMsg={'The page you are looking for was not found!'} />;

		const other = match.params.workflow ? {} : {'disabled': true};

		return (
			<Row>
				<Col>
					<Card>
						<CardHeader>
								<h3><T>pdf templates</T>: {(match.params.workflow && workflow_list[match.params.workflow]) ? workflow_list[match.params.workflow].name : null}</h3>
						</CardHeader>
						<CardBody>
							<Form onSubmit={this.handleSubmit}>
								<Row>
									<Col>
										<FormGroup>
											<Editor
												locale={locale}
												value={content.pdf.html}
												onChange={this.handleEditorChange}
												name="html"
												setup={(ed) => {
													ed.on('KeyDown', (e) => {
														if ((e.metaKey || e.ctrlKey) && ( String.fromCharCode(e.which).toLowerCase() === 's')) {
															e.preventDefault();
															this.submitRef.current.click();
														}
													});
													ed.addButton('forms', {
														type: 'menubutton',
														text: this.props.i18n.insert_field,
														icon: false,
														menu: Object.keys(this.props.form_list).map(key => {
															let value = this.props.form_list[key];
															return {
																text: value.label,
																menu: Object.keys(value.fields).map((name) => {
																	let label = value.fields[name];
																	label = label.length > 30 ? label.substring(0,27) + '...' : label;
																	return {
																		text: name + ': ' + label,
																		onclick: () => {
																			ed.insertContent(`[[${key}:${name}]]`)
																		}
																	};
																})
															};
														})
													});
												}}
												{...other}
											/>
											<FormFeedback><T>{validation_msgs.html}</T></FormFeedback>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col className="text-right">
										<InputGroupAddon addonType="prepend" className="float-right">
											<InputGroupText><i className="fa fa-save mr-2"></i></InputGroupText>
												<button type="submit" className="btn btn-success" ref={this.submitRef}>
													 <T>save</T>
												</button>
										</InputGroupAddon>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	content: state.forms.content,
	pending: state.forms.pending,
	httpStatus: state.forms.status,
	i18n: state.i18n.messages,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	rules: state.forms.validation.rules,
	workflow_list: state.list.workflow.data,
	list_pending: state.list.workflow.pending,
	form_list: state.list.fieldlist.data,
});

PdfTemplates = connect(mapStateToProps)(PdfTemplates);

export default PdfTemplates;