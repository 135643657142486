import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Groups = '/admin/groups';
const GroupsDefinition = '/admin/groups/definition';
const GroupsAssign = '/admin/groups/assign';
const GroupsReport = '/admin/groups/report';

export const StaticRoutes = {
	Groups,
	GroupsDefinition,
	GroupsAssign,
	GroupsReport,
};

/**
 * Dynamic routes
 */
// const EditFormsDynamic = EditForms + '/form/:form';
// const EditWorkflowsDynamic = EditWorkflows + '/workflow/:workflow';
// const PdfTemplateDynamic = PdfTemplates + '/workflow/:workflow';

// export const DynamicRoutes = {
// 	EditWorkflows: EditWorkflowsDynamic,
// 	EditForms: EditFormsDynamic,
// 	PdfTemplates: PdfTemplateDynamic,
// };

export const routes = [
	{
		url: Groups,
		name: 'assignations',
		to: GroupsAssign,
		role: roles.EDITOR,
		exact: true
	},
	// {
	// 	url: GroupsDefinition,
	// 	name: 'crews',
	// 	component: 'Grouping',
	// 	role: roles.EDITOR
	// },
	{
		url: GroupsAssign,
		name: 'applications',
		component: 'BulkAssign',
		role: roles.EDITOR
	},
	{
		url: GroupsReport,
		name: 'report',
		component: 'GroupReport',
		role: roles.EDITOR
	}
];

const combined = {StaticRoutes, routes};

export default combined;
