import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimeSeries } from 'pondjs';
import { Charts, ChartContainer, ChartRow, YAxis, LineChart, Resizable, Baseline, ScatterChart, EventMarker } from 'react-timeseries-charts';

import { Loading } from 'core/components';

const style = {
	value: {
		stroke: "#a02c2c",
		opacity: 0.2
	}
};

const baselineStyle = {
	line: {
		stroke: "steelblue",
		strokeWidth: 1,
		opacity: 0.4,
		strokeDasharray: "none"
	},
	label: {
		fill: "steelblue"
	}
};

const baselineStyleLite = {
	line: {
		stroke: "steelblue",
		strokeWidth: 1,
		opacity: 0.5
	},
	label: {
		fill: "steelblue"
	}
};

const baselineStyleExtraLite = {
	line: {
		stroke: "steelblue",
		strokeWidth: 1,
		opacity: 0.2,
		strokeDasharray: "1,1"
	},
	label: {
		fill: "steelblue"
	}
};

const NullMarker = props => {
	return <g />;
};

class TimeseriesChart extends Component {

	constructor(props) {
		super(props);

		const { points } = props.data;
		const series = new TimeSeries({
			name: "Stock",
			columns: ['time', 'value'],
			points
		});

		this.state = {
			tracker: null,
			trackerValue: null,
			trackerEvent: null,
			markerMode: "flag",
			x: null,
			y: null,
			timerange: series.range(),
			series,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			const { columns, points } = this.props.data;
			const series = new TimeSeries({
				name: "Stock",
				columns,
				points
			});

			this.setState({
				series,
				timerange: series.range()
			})
		}
	}

	handleTrackerChanged = t => {
		if (t) {
			const e = this.state.series.atTime(t);
			const eventTime = new Date(
				e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
			);
			const eventValue = e.get('value');
			const v = `${eventValue}`;
			this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
		} else {
			this.setState({ tracker: null, trackerValue: null, trackerEvent: null, x: null, y: null });
		}
	};

	renderMarker = () => {
		if (!this.state.tracker) {
			return <NullMarker />;
		}
		if (this.state.markerMode === "flag") {
			return (
				<EventMarker
					type="flag"
					axis="yaxis"
					event={this.state.trackerEvent}
					column="value"
					info={[{ label: "Stock", value: this.state.trackerValue }]}
					infoTimeFormat="%d/%m/%Y"
					infoWidth={120}
					markerRadius={2}
					markerStyle={{ fill: "black" }}
				/>
			);
		} else {
			return (
				<EventMarker
					type="point"
					axis="yaxis"
					event={this.state.trackerEvent}
					column="value"
					markerLabel={this.state.trackerValue}
					markerLabelAlign="left"
					markerLabelStyle={{ fill: "#2db3d1", stroke: "white" }}
					markerRadius={3}
					markerStyle={{ fill: "#2db3d1" }}
				/>
			);
		}
	};

	handleTimeRangeChange = timerange => {
		this.setState({ timerange });
	};

	handleMouseMove = (x, y) => {
		this.setState({ x, y });
	};

	render() {

		const { series } = this.state;

		if (!series)
			return (<Loading />);

		return (
			<Resizable>
				<ChartContainer
					timeRange={this.state.timerange}
					width={800}
					onTrackerChanged={this.handleTrackerChanged}
					maxTime={series.range().end()}
					minTime={series.range().begin()}
					timeAxisAngledLabels={true}
					timeAxisHeight={65}
					enablePanZoom={true}
					onTimeRangeChanged={this.handleTimeRangeChange}
					onMouseMove={(x, y) => this.handleMouseMove(x, y)}
					minDuration={1000 * 60 * 60 * 24 * 30}
					{...this.props}
				>
					<ChartRow height="400">
						<YAxis id="yaxis" label={this.props.unit} min={series.min()} max={series.max()} width="60" type="linear" format=".0f"/>
						<Charts>
							<LineChart axis="yaxis" series={series} style={style}/>
							<ScatterChart
								axis="yaxis"
								series={series}
								style={style}
							/>
							<Baseline
								axis="yaxis"
								style={baselineStyleLite}
								value={series.max()}
								label="Max"
								position="right"
							/>
							<Baseline
								axis="yaxis"
								style={baselineStyleLite}
								value={series.min()}
								label="Min"
								position="right"
							/>
							<Baseline
								axis="yaxis"
								style={baselineStyleExtraLite}
								value={series.avg() - series.stdev()}
							/>
							<Baseline
								axis="yaxis"
								style={baselineStyleExtraLite}
								value={series.avg() + series.stdev()}
							/>
							<Baseline
								axis="yaxis"
								style={baselineStyle}
								value={series.avg()}
								label="Avg"
								position="right"
							/>
							{this.renderMarker()}
						</Charts>
					</ChartRow>
				</ChartContainer>
			</Resizable>
		);
	}
}

TimeSeries.propTypes = {
	data: PropTypes.object.isRequired,
	unit: PropTypes.string.isRequired,
};

export default TimeseriesChart;
