import React, { Component } from "react";
import { GraphContextConsumer } from '../react-node-graph';
import TrashIcon from "./TrashIcon";

class Spline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: false,
			position: { x: 0, y: 0 }
		};

		this.TrashRef = React.createRef();
	}

	handleClick(e) {
		this.setState({
			selected: !this.state.selected,
			position: this.props.mousePos
		});

		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	handleRemove(e) {
		this.setState({ selected: false });

		if (this.props.onRemove) {
			this.props.onRemove(e);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.selected && this.state.selected) {
			this.props.context.selectSpline(this.TrashRef.current);
		}
		if (
			this.state.selected &&
			(prevProps.context.splineSelected !== this.props.context.splineSelected) &&
			this.props.context.splineSelected !== this.TrashRef.current
		)
			this.setState({selected: false});
	}

	render() {
		let { selected, position } = this.state;

		let { start, end } = this.props;

		let dist = this.distance([start.x, start.y], [end.x, end.y]);

		let pathString = this.bezierCurve(
			start.x, // start x
			start.y, // start y
			start.x + dist * 0.25, // cp1 x
			start.y, // cp1 y
			end.x - dist * 0.75, // cp2 x
			end.y, // cp2 y
			end.x, // end x
			end.y
		); // end y

		let className = "connector" + (selected ? " selected" : "");

		return (
			<g>
				<defs>
					<marker id="arrowhead" viewBox="0 0 10 10" refX="7" refY="2.5" markerUnits="strokeWidth" markerWidth="1" markerHeight="1" orient="auto">
						<path d="M 0,0 7.5945743,2.0600352 0,4.1200704 Z" stroke="none" fill="#337ab7"/>
					</marker>
				</defs>
				{/*<circle cx={start.x} cy={start.y} r="3" fill="#337ab7" />*/}
				{/*<circle cx={end.x} cy={end.y} r="3" fill="#9191A8" />*/}
				<path
					strokeDasharray="5,5"
					className="connector-click-area"
					d={pathString}
					markerEnd="url(#arrowhead)"
					onClick={e => {
						this.handleClick(e);
					}}
				/>
				<path
					strokeDasharray="5,5"
					className={className}
					markerEnd="url(#arrowhead)"
					d={pathString}
					onClick={e => {
						this.handleClick(e);
					}}
				/>
				{selected && (
					<TrashIcon
						trashRef={this.TrashRef}
						position={position}
						onClick={e => {
							this.handleRemove(e);
						}}
					/>
				)}
			</g>
		);
	}

	bezierCurve(a, b, cp1x, cp1y, cp2x, cp2y, x, y) {
		return `M${a},${b} C${cp1x},${cp1y} ${cp2x},${cp2y}  ${x},${y}`;
	}

	distance(a, b) {
		return Math.sqrt((b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]));
	}
}

export default props => (
	<GraphContextConsumer>
		{context => <Spline {...props} context={context} />}
	</GraphContextConsumer>
);
