import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';

import '../scss/style.scss';
import { requestData } from 'core/ducks/list';

class InputTag extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			suggestions: [],
			value: []
		};

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
	}

	componentDidMount() {
		if (!this.props.options.tagList) {
			this.props.dispatch( requestData('tags') );
		} else {
			const suggestions = this.props.options.tagList.map(name => ({
				id: name,
				name
			}));
			this.setState({suggestions});
		}
		let value = [];
		if (this.props.value && this.props.value !== '') {
			value = JSON.parse(this.props.value);
			this.setState({value});
		}
		const tags = value.map(name => ({
			id: name,
			name
		}));
		this.setState({tags});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.list.tags && ((prevProps.list.tags.pending && !this.props.list.tags.pending) || (prevProps.list.tags.refreshing && !this.props.list.tags.refreshing))) {
			const suggestions = this.props.list.tags.data.map(name => ({
				id: name,
				name
			}));
			this.setState({suggestions});
		}

		if (JSON.stringify(prevState.tags) !== JSON.stringify(this.state.tags)) {
			let value = this.state.tags.map(tag => tag.name);
			this.setState({value});
		}

		if (prevState.value !== this.state.value && typeof this.props.onChange == 'function')
			this.props.onChange({target: {name: this.props.name, value: JSON.stringify(this.state.value)}});
	}

	handleDelete (i) {
		const tags = this.state.tags.slice(0);
		tags.splice(i, 1);
		this.setState({ tags });
	}

	handleAddition (tag) {
		const { value, tags } = this.state;
		const index = value.indexOf(tag.name);
		let newTags = index !== -1 ? [...tags.slice(0, index), ...tags.slice(index + 1)] : [...tags];
		newTags = [].concat(newTags, tag);
		this.setState({ tags: newTags });
	}

	render() {
		return (
			<Row>
				<Col>
					<ReactTags
						tags={this.state.tags}
						suggestions={this.state.suggestions}
						handleDelete={this.handleDelete}
						handleAddition={this.handleAddition}
						allowBackspace={false}
						allowNew={true}
						maxSuggestionsLength={8}
						minQueryLength={0}
						readOnly={this.props.readOnly}
					/>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

InputTag = connect(mapStateToProps)(InputTag);

export default InputTag;
