import * as roles from 'core/model/roles';

/**
 * Static routes
 */

// Admin
const Logistics = '/logistics'
let LogisticsDashboard = Logistics + '/dashboard';
const LogisticsForm = Logistics + '/form';

export const StaticRoutes = {
	Logistics,
	LogisticsDashboard,
	LogisticsForm,
};

/**
 * Dynamic routes
 */
LogisticsDashboard = LogisticsDashboard + '/(category)?/:category?/(type)?/:type?';

export const DynamicRoutes = {
	LogisticsDashboard,
};

export const routes = [
	{
		url: Logistics,
		name: 'logistics',
		to: LogisticsDashboard,
		role: roles.EDITOR,
		exact: true
	},
	{
		url: LogisticsForm,
		name: 'form',
		component: 'LogisticsForm',
		role: roles.EDITOR
	},
	{
		url: LogisticsDashboard,
		name: 'dashboard',
		component: 'LogisticsDashboard',
		role: roles.EDITOR,
		exact: true
	},
];

const combined = {StaticRoutes, routes};

export default combined;
