import React, { Component } from 'react';
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern';
import '../langs/el';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/media';
import { Editor as TinymceEditor } from '@tinymce/tinymce-react';

import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

import { Api } from 'core/api';

class Editor extends Component {

	constructor(props) {
		super(props);
		this.state = {fieldName: '', file: ''};
		this.fileBrowserRef = React.createRef();
		this.fieldNameRef = React.createRef();

		this.handleFileUpload = this.handleFileUpload.bind(this);
	}

	handleFileUpload(event) {
		this.setState({file: event.target.value});
		let data = new FormData();
		data.append('file', event.target.files[0], event.target.files[0].name);
		let field = document.getElementById(this.state.fieldName);
		let api = new Api('admin/public_upload/path/editor');
		field.value = 'Uploading...';
		api.Post(data, 'upload')
			.then(response => response.json())
			.then(json => {
				field.value = json;
			});
	}

	render() {
		let { locale, value, onChange, valid, setup, ...other } = this.props;
		locale = locale ? locale : 'en';

		return (
			<React.Fragment>
				<TinymceEditor
					{...other}
					key={'editor_' + locale}
					init={{
						height: 250,
						theme: 'modern',
						entity_encoding : 'raw',
						language: locale,
						relative_urls: false,
						remove_script_host : false,
						toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview | fontsizeselect forecolor backcolor | forms',
						file_browser_callback: (field_name, url, type, win) => {
							if(type==='image') {
								this.setState({fieldName: field_name});
								this.fileBrowserRef.current.click();
							}
							return this.fileBrowserRef.current.value;
						},
						setup: setup || null

					}}
					plugins="link image code lists fullscreen table paste textcolor colorpicker"
					value={value}
					onEditorChange={onChange}
				/>
				{ typeof valid != 'undefined' &&
					<Input type="hidden" value={value} valid={valid} />
				}
				<div className="invisible">
					<input name="file" type="file" accept="image/*" ref={this.fileBrowserRef} value={this.state.file} onChange={this.handleFileUpload} />
				</div>
			</React.Fragment>
		);
	}
}

Editor.propTypes = {
	locale: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	valid: PropTypes.bool,
	setup: PropTypes.func,
}

export default Editor;
