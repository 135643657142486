import * as roles from 'core/model/roles';

/**
 * Static routes
 */
const Feed = '/feed';
const AdminFeed = '/admin/feed';
const AddFeed = AdminFeed + '/add';
const EditFeeds = AdminFeed + '/list';

export const StaticRoutes = {
	Feed,
	AdminFeed,
	AddFeed,
	EditFeeds
};

/**
 * Dynamic routes
 */
const EditFeed = EditFeeds + '/feed/:id'

export const DynamicRoutes = {
	EditFeed,
};

export const routes = [
	{
		url: Feed,
		name: 'feed',
		component: 'ListFeed',
	},
	{
		url: AdminFeed,
		name: 'feed',
		to: EditFeeds,
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: AddFeed,
		name: 'add',
		component: 'EditFeed',
		role: roles.REVIEWER,
		exact: true
	},
	{
		url: EditFeeds,
		name: 'list',
		component: 'ListFeed',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: EditFeed,
		name: 'edit',
		component: 'EditFeed',
		role: roles.REVIEWER,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
