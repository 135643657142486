import React, { Component } from "react";
import { connect } from 'react-redux';
import Reader from "react-qr-reader";
import {
	Row, Col, Card, CardBody, CardText, CardHeader, Form, FormGroup,
	InputGroup, InputGroupAddon, Label
} from 'reactstrap';

import { DynamicRoutes } from 'flows-app/model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import T from 'modules/i18n';
import { pushNotification } from 'core/ducks/notifications';

class QRReader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			delay: 300,
			result: '',
			error: false,
		};
		this.handleScan = this.handleScan.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleScan(data) {
		if (data) {
			let regex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/g;
			if (!data.match(regex)) {
				this.setState({result: ''});
				this.props.dispatch(pushNotification({body: 'not valid', type: 'warning'}));
				return;
			}
			let path = buildPath(DynamicRoutes.Apply, [data, 'current'])
			this.setState({
				result: data
			});
			this.props.history.push(path);
		}
	}

	handleError(err) {
		this.setState({error: true});
	}

	handleInputChange(event) {
		this.setState({result: event.target.value.trim()});
	}

	handleSubmit(event) {
		event.preventDefault();
		this.handleScan(this.state.result);
	}

	render() {
		return (
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<h5><T>QR Reader</T></h5>
						</CardHeader>
						<CardBody>
							<CardText className="text-center"><T>qr_code_instructions</T></CardText>
							<div style={{maxWidth: '300px', maxHeight: '300px', margin: '0 auto'}}>
								{ this.state.error ?
									<p className="text-red"><T>no camera found</T></p>
									:
									<Reader
										delay={this.state.delay}
										onError={this.handleError}
										onScan={this.handleScan}
										style={{ width: "100%" }}
									/>
								}
							</div>
							<Form onSubmit={this.handleSubmit}>
								<FormGroup>
									<Row className="mt-5">
										<Col className="text-center" sm="12" md="6" xl="3">
											<Label htmlFor="uuid_input"><T>qr_code_alternative_instructions</T>:</Label>
										</Col>
										<Col sm="12" md="6" xl="9">
											<InputGroup>
												<input
													id="uuid_input"
													size="36"
													minLength="36"
													maxLength="36"
													type="text"
													name="uuid"
													value={this.state.result}
													placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
													onChange={this.handleInputChange}
												/>
												<InputGroupAddon addonType="append">
													<button title={this.props.i18n.transition}>
														<i className="fa fa-long-arrow-right"/>
													</button>
												</InputGroupAddon>
											</InputGroup>
										</Col>
									</Row>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n.messages,
});

QRReader = connect(mapStateToProps)(QRReader);

export default QRReader;
