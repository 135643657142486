import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Input, FormGroup, Label, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Badge } from 'reactstrap';
import { FormattedDate } from 'react-intl';

import { applicationStatusStyle } from 'flows-app/model/constants';
import { DynamicRoutes } from 'flows-app/model/routes';
import StatusHistory from 'flows-app/components/statusHistory';
import { setAsideState, clearAsideState } from 'core/ducks/ui/menu';
import { requestData } from 'core/ducks/list';
import { FileTab } from 'flows-app/views/sidebars';
import { AdvancedView as View } from 'advanced-view';
import { toggleModal } from 'core/ducks/ui/modal';
import { buildPath } from 'core/model/lib/urlTools';
import { ViewUser } from 'users/views/modals/';
import T from 'modules/i18n';

import '../../scss/style.scss';

class WorkflowsMap extends Component {

	constructor(props) {
		super(props);

		this.initialState = {
			query: '',
			category: '',
			status: Object.keys(applicationStatusStyle).reduce((obj, key) => ({
				...obj,
				[key]: true
			}), {}),
			history: []
		}

		this.state = {
			pending: false,
			proc: '',
			activeTab: '1',
			...this.initialState
		};
		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		props.dispatch(
			setAsideState(this.initialState)
		);

		this.toggle = this.toggle.bind(this);
		this.handleStatusChange = this.handleStatusChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.reset = this.reset.bind(this);
		this.viewDetails = this.viewDetails.bind(this);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab)
			this.setState({activeTab: tab});
	}

	handleStatusChange(event) {
		const { name, value, checked } = event.target;
		this.setState({
			status: {
				...this.state.status,
				[value]: checked
			}
		}, () => {
			this.props.dispatch(
				setAsideState({status: {...this.state.status}})
			);
		});
	}

	handleInputChange(event) {
		const state = {[event.target.name]: event.target.value};
		const { pending } = this.state;
		this.setState(state);
		if (pending)
			clearTimeout(this.state.proc);
		let proc = setTimeout(() => {
			this.props.dispatch(
				setAsideState(state)
			);
			this.setState({pending: false, proc: ''});
		}, 500);
		this.setState({
			pending: true,
			proc
		});
	}

	reset() {
		this.setState(this.initialState);
		this.props.dispatch(setAsideState(this.initialState));
	}

	viewDetails(uuid) {
		const url = `advancedView/application/${uuid}`;
		this.props.dispatch( requestData('user', url) ).then(() => {
			if (this.props.list.user.status === 200)
				this.actions.toggleModal(true,
					<View
						toggle={() => {this.actions.toggleModal()}}
						data={this.props.list.user.data.values}
						translateFields={false}
						location={this.props.list.user.data.location}
						details={this.props.list.user.data.details}
					/>
				);
		});
	}

	componentDidMount() {
		this.props.dispatch( requestData('workflow', 'workflow/hidden/false/type/public/') );
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pending && !this.props.pending)
			this.props.dispatch(requestData('history', `/application/history/${this.props.details.uuid}`)).then(() => {
				this.setState({history: [{transition: this.props.details.created, status: 'submission'}, ...this.props.list.history.data]});
				this.toggle('0');
			});
	}

	componentWillUnmount() {
		this.props.dispatch(clearAsideState());
	}

	render() {
		const { details, waitWorkflows, workflowList } = this.props;

		const { messages } = this.props.i18n || {messages: {}};

		if (waitWorkflows || !workflowList)
			return null;

		return (
			<div className="workflows-map">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='0' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('0'); }}
						>
							<T>details</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='1' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('1'); }}
						>
							<T>filters</T>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={this.state.activeTab==='2' ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggle('2'); }}
						>
							<T>files</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId="0">
						{ (!this.props.pending && details.uuid) ?
							<>
								<Table>
									<thead>
										<tr>
											<th colSpan={2} className="text-center">{details.workflow}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th className="px-0 small"><T>address</T></th>
											<td>{details.address}</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>author</T></th>
											<td>
												{details.author + ' '}
												<i
													className="fa fa-eye"
													role="button"
													onClick={() => {
														this.props.dispatch( toggleModal(true,
															<ViewUser uuid={details.author_uuid} />
														) );
													}}
												/>
											</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>created</T></th>
											<td>
												<FormattedDate
													value={details.created}
													year='numeric'
													month='short'
													day='2-digit'
												/>
											</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>last_action</T></th>
											<td>
												<FormattedDate
													value={details.last_action}
													year='numeric'
													month='short'
													day='2-digit'
												/>
											</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>step</T></th>
											<td>{details.step}</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>reviewer</T></th>
											<td>
												{details.reviewer + ' '}
												{ details.reviewer_uuid &&
													<i
														className="fa fa-eye"
														role="button"
														onClick={() => {
															this.props.dispatch( toggleModal(true,
																<ViewUser uuid={details.reviewer_uuid} />
															) );
														}}
													/>
												}
											</td>
										</tr>
										<tr>
											<th className="px-0 small"><T>status</T></th>
											<td><T>{details.status}</T></td>
										</tr>
									</tbody>
								</Table>
								<Row className="my-3 py-2 border-top border-bottom">
									<Col className="text-center m-0 p-0" style={{fontSize: '120%'}}>
										<i
											className="fa fa-eye border rounded d-inline-block"
											role="button"
											title={messages.view || 'view'}
											onClick={() => this.viewDetails(details.uuid)}
										/>
										<i
											className="fa fa-edit border rounded d-inline-block"
											role="button"
											title={messages.edit || 'edit'}
											onClick={() => {
												this.props.history.push(buildPath(DynamicRoutes.Apply, [details.uuid, 'current']));
											}}
										/>
									</Col>
								</Row>
								<Row className="border-top mt-2">
									<Col className="mt-0 pt-0">
										<StatusHistory data={this.state.history} />
									</Col>
								</Row>
							</>
							:
							<p><T>select a point to see details</T></p>
						}
					</TabPane>
					<TabPane tabId="1">
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="query" className="font-weight-bold"><T>search</T></Label>
									<Input
										id="query"
										placeholder={messages.search || 'search'}
										value={this.state.query}
										name="query"
										onChange={this.handleInputChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="workflows_list" className="font-weight-bold"><T>category</T></Label><br/>
									{Object.keys(workflowList).length > 1 ?
										<Input
											id="workflows_list"
											type="select"
											value={this.state.category}
											name="category"
											onChange={this.handleInputChange}
										>
											<option value="">{`${messages.choose || 'choose'} ${messages.category || 'category'}`}</option>
											{ Object.keys(workflowList).map((key) =>
												<option key={`workflow_${key}`} value={key}>{workflowList[key].name}</option>
											)}
										</Input>
										:
										<Badge className="p-2">{workflowList[Object.keys(workflowList)[0]].name}</Badge>
									}
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<Label htmlFor="status" className="font-weight-bold"><T>status</T></Label>
									{ Object.keys(this.state.status).map((status) =>
										<label key={`checkbox_group_${status}`} className="input-container mb-3">
											{' '}<T>{status}</T>
											<input
												type="checkbox"
												checked={this.state.status[status]}
												onChange={this.handleStatusChange}
												value={status}
												name="status"
											/>
											<span className="checkmark" style={this.state.status[status] ? {backgroundColor: applicationStatusStyle[status].color} : {}}/>
										</label>
									) }
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button type="button" onClick={this.reset}><T>reset</T></Button>
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId="2">
						{ (!this.props.pending && details.uuid)
							? <FileTab uuid={details.uuid} />
							: <p><T>select a point to see details</T></p>
						}
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	asideState: state.ui.menu.asideState,
	details: state.update.response,
	pending: state.update.pending,
	workflowList: state.list.workflow.data,
	waitWorkflows: state.list.workflow.pending,
	list: state.list,
});

WorkflowsMap = connect(mapStateToProps)(WorkflowsMap);

export default WorkflowsMap;
