import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'failures',
		children: [
			{
				name: 'map',
				url: StaticRoutes.WorkflowsMap,
				icon: 'icon-map',
				role: roles.REVIEWER
			}
		]
	},
];

export default menu;
