import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'feed',
		url: StaticRoutes.Feed,
		role: [roles.GUEST, roles.AUTHORIZED],
	},
	{
		name: 'feed',
		url: StaticRoutes.AdminFeed,
		icon: 'fa fa-rss',
		role: roles.REVIEWER,
		children: [
			{
				name: 'overview',
				url: StaticRoutes.EditFeeds,
				icon: 'fa fa-street-view',
				role: roles.REVIEWER
			},
			{
				name: 'add',
				url: StaticRoutes.AddFeed,
				icon: 'icon-plus',
				role: roles.REVIEWER
			},
		]
	},
];

export default menu;
