import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form } from 'reactstrap';
import csv from 'jquery-csv';

import { requestData } from 'core/ducks/list';
import { Loading } from 'core/components';
import Alert from 'core/views/modals/alert';
import { toggleModal } from 'core/ducks/ui/modal';
import { Table, Tbody, Thead, Tfoot, Tr } from 'table';
import T from 'modules/i18n';
import { postData } from 'core/ducks/update';

import Selection from '../../components/selection';

class LogisticsForm extends Component {

	constructor(props) {
		super(props);
		this.initialRow = {
			category: '',
			type: '',
			name: '',
			description: '',
			unit: '',
			quantity: '',
		};
		this.state = {
			values: [{...this.initialRow}],
			underSubmit: false,
			isInputModalOpen: false
		};

		this.layout = {
			category: {sortable: false},
			type: {sortable: false},
			name: {sortable: false},
			description: {sortable: false},
			unit: {sortable: false},
			quantity: {sortable: false},
		};
		this.fields = Object.keys(this.layout);
		this.csvUploadRef = React.createRef();

		this.actions = bindActionCreators({toggleModal}, props.dispatch);

		this.handleCSVImport = this.handleCSVImport.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleAddRowClick = this.handleAddRowClick.bind(this);
		this.handleRowRemove = this.handleRowRemove.bind(this);
		this.sanitizeValues = this.sanitizeValues.bind(this);
		this.readFile = this.readFile.bind(this);
		this.handleCSVContent = this.handleCSVContent.bind(this);
		this.toggleInputModal = this.toggleInputModal.bind(this);
	}

	handleCSVImport() {
		this.csvUploadRef.current.click();
	}

	handleInputChange(event, row) {
		const {name, value} = event.target;
		this.setState({
			values: [
				...this.state.values.slice(0, row),
				{...this.state.values[row], [name]: value},
				...this.state.values.slice(row + 1)
			]
		});
	}

	sanitizeValues(underSubmit=false) {
		const { values } = this.state;
		let newValues = values.filter(row => {
			let keep = false;
			Object.values(row).forEach(value => {
				if (value.trim() !== '')
					keep = true;
			});
			return keep;
		});

		if (underSubmit)
			underSubmit = newValues.length > 0;

		this.setState({
			values: [...newValues],
			underSubmit
		});
	}

	handleFormSubmit(event) {
		event.preventDefault();
		this.sanitizeValues(true);
	}

	handleAddRowClick() {
		this.setState({
			values: [...this.state.values, {...this.initialRow}]
		});
	}

	handleRowRemove(row) {
		this.setState({
			values: [
				...this.state.values.slice(0, row),
				...this.state.values.slice(row + 1)
			]
		});
	}

	readFile(event) {
		const file = event.target.files[0];
		const fileReader = new FileReader();
		fileReader.onloadend = (evt) => {
			this.handleCSVContent(evt.target.result);
		};
		fileReader.readAsText(file);
	}

	handleCSVContent(content) {
		let values = csv.toObjects(content);
		values = values.map(row => {
			let newRow = {};
			this.fields.forEach(field => {
				let value = row[field].trim();
				if (field === 'category' || field === 'type') {
					value = value.replace(/[ ]*\/[ ]*/g, '-');
				}
				value = value.replace(/[ ]+,/g, ',');
				value = value.replace(/[ ]{2,}/g, ' ');
				newRow[field] = value;
			});
			return newRow;
		});
		this.setState({values: [...this.state.values, ...values]}, this.sanitizeValues);
	}

	toggleInputModal() {
		this.setState({isInputModalOpen: !this.state.isInputModalOpen});
	}

	componentDidMount() {
		this.props.dispatch(requestData('categories', 'logistics/list/category'));
		this.props.dispatch(requestData('types', 'logistics/list/type'));
		this.props.dispatch(requestData('units', 'logistics/list/unit'));
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.underSubmit && this.state.underSubmit) {
			this.props.dispatch( postData('logistics', this.state.values) );
		}
	}

	render() {

		const { values } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		if (!this.props.list.categories || !this.props.list.types || !this.props.list.units)
			return (<Loading />);

		const { categories, types, units } = this.props.list;

		return (
			<Form onSubmit={this.handleFormSubmit}>
				<fieldset disabled={this.state.underSubmit}>
					<Row>
						<Col xs="12" lg="12" className="text-right">
							<input type="file" className="d-none" ref={this.csvUploadRef} accept=".csv" onChange={this.readFile} />
							<Button color="success" onClick={this.handleCSVImport}><T>import</T> CSV</Button>
						</Col>
					</Row>
					<Row>
						<Col xs="12" lg="12">
							<Table>
								<Thead>
									<Tr data={['', ...this.fields]} layout={this.layout} />
								</Thead>
								<Tbody>
									{ values.map((row, row_index) => (
										<tr key={`row_${row_index}`}>
											<td>
												<i
													className="fa fa-trash-o"
													onClick={
														(index) => this.actions.toggleModal(true,
															<Alert
																toggle={this.actions.toggleModal}
																title="drop confirm"
																message="do you wish to continue"
																onConfirm={() => this.handleRowRemove(row_index)}
															/>
														)
													}
													title={messages.drop || 'drop'}
													role="button"
												/>
											</td>
											{this.fields.map(field => (
												<td key={`${field}_${row_index}`}>
													<Selection
														type={field==='quantity' ? 'number' : 'text'}
														name={field}
														value={row[field]}
														onChange={(e) => this.handleInputChange(e, row_index)}
														scope={field}
														categories={categories.data}
														types={types.data}
														units={units.data}
														messages={messages}
													/>
												</td>
											))}
										</tr>
									))}
								</Tbody>
								<Tfoot>
									<tr>
										<td colSpan={this.fields.length + 1} className="text-right">
											<i role="button" className="fa fa-plus" onClick={this.handleAddRowClick} title={messages.add || 'add'} />
										</td>
									</tr>
								</Tfoot>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col xs="12" lg="12" className="text-right">
							<Button type="reset" color="secondary" className="mr-3" onClick={() => this.setState({values: [{...this.initialRow}]})}><T>reset</T></Button>
							<Button type="submit" color="primary"><T>submit</T></Button>
						</Col>
					</Row>
				</fieldset>
			</Form>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	list: state.list,
});

LogisticsForm = connect(mapStateToProps)(LogisticsForm);

export default LogisticsForm;
