import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

class User extends Component {
	render() {
		return (
			<Card className="p-2 w-fit-content min-w-100">
				<mark className="text-danger small w-fit-content">
					@{this.props.username}
				</mark>
				{this.props.firstname} {this.props.lastname}
			</Card>
		);
	}
}

User.propTypes = {
	username: PropTypes.string.isRequired,
	firstname: PropTypes.string.isRequired,
	lastname: PropTypes.string.isRequired
};

export default User;
