import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, FormFeedback, Label, Button } from 'reactstrap';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Input } from '../../components';
import { toggleModal } from '../../ducks/ui/modal';
import { getValidation, validate } from '../../ducks/forms';
import { updateData, postData } from '../../ducks/update';
import { pushNotification } from '../../ducks/notifications';
import { Loading } from '../../components';
import { rolesList } from 'users/model/constants';
import * as roles from '../../model/roles';
import T from 'modules/i18n';
import { Address } from 'input-fields';

import sample from 'core/img/sample.png';

class ProfileForm extends Component {

	constructor(props) {
		super(props);
		const data = props.profile ? props.profile_data : (props.data ? props.data : {});
		const initialValues = {
			firstname: data.firstname || '',
			lastname: data.lastname || '',
			email: data.email || '',
			role: data.role || roles.AUTHORIZED,
			phone: data.phone || '',
			address: {address: data.address || '', location: (data.lat && data.lon) ? `${data.lat};${data.lon}` : {}},
			meter_number: data.meter_number || '',
			client_number: data.client_number || '',
			accepts_email: data.accepts_email || false,
		};
		const readOnly = {
			created: data.created ? data.created : '',
			date_last_login: data.last_login ? data.last_login : '',
		}
		if (props.profile || props.data) {
			readOnly.username = data.username;
		} else {
			initialValues.username = '';
		}
		this.initialValues = initialValues;
		this.state = {
			values: initialValues,
			readOnly,
			under_submit: false,
			disabled: false,
			showSample: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const { name, type, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: type === 'checkbox' ? event.target.checked : value
			},
			under_submit: false
		}, () => {
			if (typeof this.props.unsubmitted === 'function')
				this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { dispatch, rules } = this.props;
		this.setState({under_submit: true}, () => dispatch(validate(this.state.values, rules)));
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(JSON.stringify(this.initialValues) !== JSON.stringify(this.state.values));
	}

	submit(data) {
		const { dispatch, profile } = this.props;
		if (profile || this.props.data) {
			let url = profile ? 'profile' : `users/uuid/${this.props.data.uuid}`;
			dispatch(updateData(url, data, false)).then(() => {
				this.initialValues = {...this.initialValues, ...data};
				if (this.props.modalOpen)
					dispatch(toggleModal());
			});
		} else {
			dispatch(postData(('registration'), data));
			this.setState({disabled: true});
		}
	}

	componentDidMount() {
		this.props.dispatch( getValidation('users') );
		if (typeof this.props.unsubmitted === 'function')
			this.props.unsubmitted(false);
	}

	componentDidUpdate(prevProps, prevState) {
		if ( !prevState.under_submit && this.state.under_submit && this.props.valid) {
			const { values } = this.state;
			let data = {};
			if (this.props.profile || this.props.data) {
				Object.keys(values).forEach((key) => {
					if (values[key] !== this.initialValues[key])
						data[key] = values[key];
				});
			} else {
				data = values;
			}
			if (Object.keys(data).length > 0)
				this.submit(data);
			this.setState({under_submit: false});
		}
		if (prevProps.post_pending && !this.props.post_pending) {
			const { post_status, dispatch } = this.props;
			if (post_status === 200) {
				dispatch(pushNotification({body: "user added", type: "success"}));
				this.setState({submitted: true});
			} else if (post_status === '409') {
				dispatch(pushNotification({body: "username exists", type: "warning"}));
			} else {
				dispatch(pushNotification({body: 'action denied', type: 'warning'}));
			}
		}
	}

	render() {

		if (this.props.validation_pending || this.props.validation_scope !== 'users')
			return (<Loading />);

		const { i18n, modalOpen, dispatch, rules } = this.props;
		const { values, readOnly } = this.state;

		let disabled = this.state.disabled ? {disabled: 'disabled'} : {};

		return (
			<Form onSubmit={this.handleSubmit}>
				<fieldset {...disabled}>
					<Row>
						<Col xs="12" md="4">
							<FormGroup>
								<Label htmlFor="username"><T>username</T>*</Label>
								<Input
									id="username"
									type="text"
									name="username"
									value={(this.props.profile || this.props.data) ? readOnly.username : values.username}
									readOnly={(this.props.profile || this.props.data) ? true : undefined}
									required={(this.props.profile || this.props.data) ? undefined : true}
									autoComplete="off"
									minLength={rules.username.min_size}
									maxLength={rules.username.max_size}
									onChange={this.handleChange}
									pattern={rules.username.validation}
								/>
								<FormFeedback><T>{rules.username.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="4">
							{(this.props.profile || this.props.data) ?
								<FormGroup>
									<Label htmlFor="created"><T>date_joined</T></Label>
									<Input
										id="created"
										type="datetime"
										value={
											this.props.intl.formatDate(readOnly.created, {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}) +
											', ' +
											this.props.intl.formatTime(readOnly.created)
										}
										readOnly
									/>
								</FormGroup>
								:
								<span/>
							}
						</Col>
						<Col xs="12" md="4">
							{(this.props.profile || this.props.data) ?
								<FormGroup>
									<Label htmlFor="date_last_login"><T>date_last_login</T></Label>
									<Input
										id="date_last_login"
										type="datetime"
										value={
											readOnly.date_last_login && readOnly.date_last_login !== '' ?
											this.props.intl.formatDate(readOnly.date_last_login, {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}) +
											', ' +
											this.props.intl.formatTime(readOnly.date_last_login)
											:
											''
										}
										readOnly
									/>
								</FormGroup>
								:
								<span/>
							}
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="firstname"><T>firstname</T>*</Label>
								<Input
									id="firstname"
									type="text"
									name="firstname"
									autoComplete="given-name"
									required
									value={values.firstname}
									minLength={rules.firstname.min_size}
									maxLength={rules.firstname.max_size}
									onChange={this.handleChange}
									pattern={rules.firstname.validation}
								/>
								<FormFeedback><T>{rules.firstname.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" lg="3">
							<FormGroup>
								<Label htmlFor="lastname"><T>lastname</T>*</Label>
								<Input
									id="lastname"
									type="text"
									name="lastname"
									autoComplete="family-name"
									value={values.lastname}
									required
									minLength={rules.lastname.min_size}
									maxLength={rules.lastname.max_size}
									onChange={this.handleChange}
									pattern={rules.lastname.validation}
								/>
								<FormFeedback><T>{rules.lastname.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						{ !this.props.profile &&
							<Col xs="12" md="6" lg="3">
								<FormGroup>
									<Label htmlFor="role"><T>role</T></Label>
									<Input
										id="role"
										name="role"
										type="select"
										value={values.role}
										onChange={this.handleChange}
									>
										{ Object.keys(rolesList).map((key) => <option key={`option_${key}`} value={rolesList[key]}>{i18n[rolesList[key]]}</option>) }
									</Input>
								</FormGroup>
							</Col>
						}
					</Row>
					<Row>
						<Col xs="12" lg="6">
							<FormGroup>
								<Label htmlFor="email"><i className="icon-envelope-letter" aria-hidden="true"></i> <T>email</T>*</Label>
								<Input
									id="email"
									type="email"
									name="email"
									autoComplete="email"
									value={values.email}
									required
									onChange={this.handleChange}
									pattern={rules.email.validation}
								/>
								<FormFeedback><T>{rules.email.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" lg="6">
							<FormGroup>
								<Label htmlFor="phone"><i className="icon-phone" aria-hidden="true"></i> <T>phone</T>*</Label>
								<Input
									id="phone"
									type="text"
									name="phone"
									autoComplete="tel-national"
									value={values.phone}
									onChange={this.handleChange}
									pattern={rules.phone.validation}
								/>
								<FormFeedback><T>{rules.phone.message}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col xs="12" md="6" xl="4">
							<FormGroup>
								<Label htmlFor="meter_number"><span className="i-circle">A</span> Αριθμός Μετρητή</Label>
								<Input
									id="meter_number"
									type="text"
									name="meter_number"
									autoComplete="off"
									value={values.meter_number}
									onChange={this.handleChange}
									pattern={rules.meter_number.validation}
								/>
								<FormFeedback><T>{rules.meter_number.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="6" xl="4">
							<FormGroup>
								<Label htmlFor="client_number"><span className="i-circle">B</span> Κωδικός Καταναλωτή</Label>
								<Input
									id="client_number"
									type="text"
									name="client_number"
									autoComplete="off"
									value={values.client_number}
									onChange={this.handleChange}
									pattern={rules.client_number.validation}
								/>
								<FormFeedback><T>{rules.client_number.message}</T></FormFeedback>
							</FormGroup>
						</Col>
						<Col xs="12" md="12" xl="4">
							<div
								className="bg-light rounded px-5 py-2 font-weight-bold text-primary"
								style={{fontSize: '130%', minHeight: '3rem'}}
							>
								<div className="float-left">Υπόδειγμα</div>
								<div className="float-right">
									{ this.state.showSample ?
										<i
											className="fa fa-angle-up"
											title="Απόκρυψη υποδείγματος"
											onClick={() => {this.setState({showSample: false})}}
										/>
										:
										<i
											className="fa fa-angle-down"
											title="Προβολή υποδείγματος"
											onClick={() => {this.setState({showSample: true})}}
										/>
									}
								</div>
							</div>
							<img src={sample} alt="sample" className={this.state.showSample ? 'd-block w-100' : 'd-none'}/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Label htmlFor="address"> <T>address</T></Label>
								<Address
									id="address"
									type="text"
									name="address"
									autoComplete="street-address"
									value={values.address}
									onChange={this.handleChange}
									locale='el'
									showCoordinates={false}
									center={this.props.mapSettings.center}
								/>
								<FormFeedback><T>{rules.address.message}</T></FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col sm="8" lg="9" xl="10">
							<FormGroup check>
								<Label check>
									<Input
										type="checkbox"
										name="accepts_email"
										value={values.accepts_email}
										checked={values.accepts_email}
										onChange={this.handleChange}
									/>{' '}
									<T>accept emails</T>
								</Label>
							</FormGroup>
						</Col>
						<Col sm="4" lg="3" xl="2" className="text-right">
							<Row>
								<Col md="6" className="p-0 m-0 text-center">
									<Button
										color="primary"
										type="submit"
									>
										<T>save</T>
									</Button>
								</Col>
								<Col md="6" className="p-0 m-0 text-center">
									<Button
										color="secondary"
										type="reset"
										onClick={() => {
											if (modalOpen)
												dispatch(toggleModal());
										}}
									>
										<T>cancel</T>
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</fieldset>
			</Form>
		);
	};
}

ProfileForm.propTypes = {
	profile: PropTypes.bool,
	data: PropTypes.object,
	unsubmitted: PropTypes.func
};

const mapStateToProps = (state) => ({
	profile_data: state.profile.user,
	rules: state.forms.validation.rules,
	validation_pending: state.forms.validation.pending,
	validation_scope: state.forms.validation.scope,
	valid: state.forms.valid,
	post_pending: state.update.sending,
	post_status: state.update.status,
	modalOpen: state.ui.modal.modalOpen,
	i18n: state.i18n.messages,
	mapSettings: state.ui.settings.values.map,
});

ProfileForm = connect(mapStateToProps)(injectIntl(ProfileForm));

export default ProfileForm;
