import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

class Handle extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showPopover: false,
		};
	}

	togglePopover = (e) => {
		this.setState({
			showPopover: !this.state.showPopover,
		});
	}

	render() {
		const { domain: [min, max], handle: {id, value, percent }, disabled, getHandleProps } = this.props;

		return (
			<>
				<div
					className="handle"
					style={{
						left: `${percent}%`,
					}}
					{...getHandleProps(id)}
					onMouseEnter={this.togglePopover}
					onMouseLeave={this.togglePopover}
				/>
				<div
					className={disabled ? 'handle-slider disabled' : 'handle-slider'}
					role="slider"
					aria-valuemin={min}
					aria-valuemax={max}
					aria-valuenow={value}
					style={{
						left: `${percent}%`,
					}}
				/>
				{ this.state.showPopover && (
					<div style={{left: `${percent}%`}} className="handle-slider-popover">
						{format(value, "dd/MM/yy HH:mm")}
						<span className="arrow"/>
					</div>
				)}
			</>
		);
	}
}

Handle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Handle.defaultProps = {
	disabled: false
};

export default Handle;
