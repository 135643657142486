import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	NavItem,
	NavLink,
	Badge,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { countNotifications } from '../../ducks/messages';
import { StaticRoutes } from '../../model/routes';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';

class Notifications extends Component {

	constructor(props) {
		super(props);
		this.intervalID = '';
		this.fetchMessages = this.fetchMessages.bind(this);
	}

	componentDidMount() {
		this.fetchMessages();
		this.intervalID = setInterval(this.fetchMessages, 1800000);
	}

	fetchMessages() {
		this.props.dispatch(countNotifications());
	}

	componentWillUnmount() {
		clearInterval(this.intervalID);
	}

	render() {
		return (
			<SecureContent role={roles.AUTHORIZED}>
				<NavItem className="d-md-down-none">
					<NavLink tag={Link} to={StaticRoutes.Messages}>
						{ this.props.count!==0 ?
							<React.Fragment>
								<i className="icon-envelope-letter"/>
								<Badge pill color="warning">
									{this.props.count}
								</Badge>
							</React.Fragment>
							:
							<i className="icon-envelope"/>
						}
					</NavLink>
				</NavItem>
			</SecureContent>
		);
	}
}

const mapStateToProps = (state) => ({
	count: state.messages.count,
	pending: state.messages.pending,
});

Notifications = connect(mapStateToProps)(Notifications);

export default Notifications;
