import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row, Col, Form, FormGroup, FormFeedback, FormText, Label, Button,
	Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { el } from 'date-fns/locale';
import { addDays } from 'date-fns';

import { PolygonInput } from 'input-fields';
import T from 'modules/i18n';
import { Input, Loading } from 'core/components';
import { Editor } from 'editor';
import { postData, getData, updateData } from 'core/ducks/update';
import { getValidation, validate } from 'core/ducks/forms';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('el', el);

class EditFeed extends Component {

	constructor(props) {
		super(props);

		this.initialValues = {
			title: '',
			geometry: [],
			body: '',
			valid_start: new Date(),
			valid_end: addDays(new Date(), 1),
			send_email: true,
		};

		this.initialState = {
			values: {
				...this.initialValues
			},
			method: 'post',
			submitted: false,
		};

		this.state = {
			...this.initialState,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		let { name, value, checked, type } = event.target;
		if (name === 'geometry') {
			value = value.map(polygon => polygon[0].map(point => [point.lat, point.lng]));
		}
		this.setState({
			values: {
				...this.state.values,
				[name]: type==='checkbox' ? checked : value
			}
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		if (this.state.method === 'post') {
			this.props.dispatch( postData('feed', this.state.values) );
			this.setState({
				submitted: true,
			});
		} else {
			const { id } = this.props.match.params;
			this.props.dispatch( updateData(`feed/id/${id}`, this.state.values) ).then(() => {
				if (this.props.updateStatus && this.props.onSubmit && typeof this.props.onSubmit == 'function')
					this.props.onSubmit();
			});
		}
	}

	componentDidMount() {
		if (this.props.match.params && this.props.match.params.id) {
			const { id } = this.props.match.params;
			this.props.dispatch( getData(`feed/id/${id}`) );
			this.setState({method: 'put'});
		}
		this.props.dispatch( getValidation('feed') );
	}

	componentDidUpdate(prevProps) {
		if (prevProps.pending && !this.props.pending) {
			const values = Object.keys(this.props.response)
				.reduce((obj, key) => {
					let value;
					switch (key) {
						case 'valid_start':
						case 'valid_end':
							value = new Date(this.props.response[key]);
							break;

						case 'geometry':
							value = JSON.parse(this.props.response[key]).coordinates[0];
							break;

						default:
							value = this.props.response[key];
					}
					return {
						...obj,
						[key]: value
					}
				}, {});
			values.send_email = false;
			this.setState({values});
		};
	}

	render() {
		const { values } = this.state;

		if (this.props.validation_pending || this.props.validation_scope !== 'feed')
			return (<Loading />);

		const { rules, validation_msgs } = this.props;

		return (
			<Card>
				<CardHeader>
					<h4><i className="fa fa-pencil-square-o"/> <T>feed</T></h4>
				</CardHeader>
				<Form onSubmit={this.handleSubmit}>
					<fieldset disabled={this.state.submitted}>
						<CardBody>
							<Row className="border-bottom">
								<Col>
									<FormGroup>
										<Label className="label-back" htmlFor="title_input">
											<em><T>title</T></em>
										</Label>
										<Input
											id="title_input"
											name="title"
											value={values.title}
											onChange={this.handleChange}
											minLength={rules.title.min_size}
											maxLength={rules.title.max_size}
											pattern={rules.title.validation}
										/>
										<FormFeedback><T>{validation_msgs.title}</T></FormFeedback>
									</FormGroup>
								</Col>
							</Row>
							<Row className="border-bottom">
								<Col>
									<FormGroup>
										<Label className="label-back"><em><T>location</T></em></Label>
										<FormText>
											<T>draw the polygons for which this feed is concerned</T>
										</FormText>
										<PolygonInput
											draw={{
												polygon: {showArea: true, metric: ['km', 'm'], precision: {km: 2, m: 1}},
												rectangle: true,
												marker: false,
												polyline: false,
												circlemarker: false,
												circle: false
											}}
											center={[41.120164, 25.404698]}
											zoom={14}
											height={500}
											onChange={this.handleChange}
											name="geometry"
											polygon={this.state.values.geometry}
											disabled={this.state.submitted}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row className="border-bottom">
								<Col>
									<FormGroup>
										<Label className="label-back" htmlFor="body_input">
											<em><T>text</T></em>
										</Label>
										<Editor
											id="body_input"
											value={values.body}
											locale="el"
											onChange={(value) => {this.handleChange({target: {name: 'body', value}})}}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup>
										<Label className="label-back">
											<em><T>validity range</T></em>
										</Label>
										<Label className="mx-2">
											<T>from</T>{' '}
											<DatePicker
												selected={values.valid_start}
												onChange={(value) => {this.handleChange({target: {name: 'valid_start', value}})}}
												allowSameDay={true}
												showTimeSelect
												locale="el"
												timeFormat="HH:mm"
												timeIntervals={15}
												dateFormat="d MMMM yyyy, HH:mm"
												timeCaption="Ώρα"
												className="form-control"
											/>
										</Label>
										<Label className="mx-2">
											{' '}<T>to</T>{' '}
											<DatePicker
												selected={values.valid_end}
												onChange={(value) => {this.handleChange({target: {name: 'valid_end', value}})}}
												allowSameDay={true}
												showTimeSelect
												locale="el"
												timeFormat="HH:mm"
												timeIntervals={15}
												dateFormat="d MMMM yyyy, HH:mm"
												timeCaption="Ώρα"
												className="form-control"
											/>
										</Label>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<Row>
								<Col className="text-right">
									<FormGroup check>
										<Label check>
											<Input name="send_email" type="checkbox" value={values.send_email} checked={values.send_email} onChange={this.handleChange} />{' '}
											<T>send emails to the users who are affected by this feed</T>
										</Label>
									</FormGroup>
								</Col>
								<Col className="text-right">
									{ this.state.submitted &&
										<i
											className="btn btn-success mr-2"
											onClick={() => {
												this.setState({...this.initialState}, () => {
													this.forceUpdate();
												});
											}}
										>
											<T>new add</T>
										</i>
									}
									<Button type="submit" color="primary"><T>{this.state.method === 'post' ? 'publish' : 'update'}</T></Button>
								</Col>
							</Row>
						</CardFooter>
					</fieldset>
				</Form>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	response: state.update.response,
	pending: state.update.pending,
	rules: state.forms.validation.rules,
	valid: state.forms.valid,
	validation_msgs: state.forms.validation_msgs,
	validation_pending: state.forms.validation.pending,
	validation_scope: state.forms.validation.scope,
	updateStatus: state.update.status,
});

EditFeed = connect(mapStateToProps)(EditFeed);

export default EditFeed;
